import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FirebaseAppContext } from 'libs.firebase_react';
import { AssetTypes, LocalizationStatus } from 'libs.localization.common';
import { useLocalization } from 'libs.localization.react';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Workflow } from 'libs.nucleus.workflow';
import { useBooleanState } from 'libs.react.hooks';
import { LocaleStatusChangeModal } from './locale_status_change_modal.component';
import { TranslationWorkbenchContext } from '../../contexts';
import { useLocalizedBundle } from '../../queries/use_localized_bundle';
export const LocaleStatusProgress = ({ asset, locale, assetId, isDisabled = false, trackingContext = {}, }) => {
    const t = useLocalizeMessage();
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);
    const { logEvent } = useContext(FirebaseAppContext);
    const { study, updateStudy } = useContext(TranslationWorkbenchContext);
    const localizationAssets = study.getLocalizationAssets();
    const { localizationEntry, localization } = useLocalization(asset);
    const status = localizationEntry?.supported_locales?.[locale]?.status;
    const enableLocalizedBundle = asset && ('activity' in asset || asset.type === AssetTypes.ACTIVITY);
    const { isPending, mutate: pushTranslationBundle } = useLocalizedBundle();
    const statusProps = useMemo(() => [
        {
            iconName: 'stopCircle',
            color: 'red',
            label: t('Not Started'),
            value: LocalizationStatus.NOT_STARTED,
        },
        {
            iconName: 'navWrenchScrewdriver',
            color: 'yellow',
            label: t('In Progress'),
            value: LocalizationStatus.IN_PROGRESS,
        },
        {
            iconName: 'preview',
            color: 'yellow',
            label: t('In Review'),
            value: LocalizationStatus.IN_REVIEW,
        },
        {
            iconName: 'success',
            color: 'green',
            label: t('Ready for use'),
            value: LocalizationStatus.READY,
        },
    ], []);
    const onHandleStatus = useCallback(async (selectedStatus) => {
        if (assetId) {
            logEvent('translations_localization_status_changed', {
                ...trackingContext,
                previousStatus: status,
                status: selectedStatus,
                locale,
                id: assetId,
            });
        }
        if (selectedStatus === LocalizationStatus.READY && assetId) {
            if (enableLocalizedBundle) {
                await pushTranslationBundle({ resourceId: assetId, locales: [locale] }, {
                    onSuccess: () => {
                        closeModal();
                    },
                });
            }
            else {
                // update status in supported_locales
                await localization?.setStatus(locale, selectedStatus);
                // publish localization asset to study config
                const localizationAsset = {
                    id: localization?.getId() || '',
                    libraryId: uuidv4(),
                    locale,
                    type: asset.type,
                    version: asset.version,
                };
                const assets = localizationAssets.filter((asset) => asset.id !== localization?.getId());
                study.setLocalizationAssets([...assets, localizationAsset]);
                updateStudy?.(study);
                closeModal();
            }
        }
        else {
            await localization?.setStatus(locale, selectedStatus);
            closeModal();
        }
    }, [status]);
    const currentStatusIndex = statusProps.findIndex((statusProp) => statusProp.value === status);
    return (_jsxs("div", { className: 'flex gap-4 items-center relative', "data-testid": 'translation-status-workflow', children: [_jsx("div", { className: 'flex', children: _jsx(Workflow, { statuses: statusProps, activeStatusIndex: currentStatusIndex }) }), _jsx(Button, { variant: 'secondary', label: 'Change status', onClick: openModal, disabled: isDisabled }), isModalOpen && status && (_jsx(LocaleStatusChangeModal, { isChanging: isPending, onModalClose: closeModal, onHandleStatus: onHandleStatus, status: status }))] }));
};
