import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FORMAT_ELEMENT_COMMAND, $selectAll } from 'lexical';
import { useEffect } from 'react';
import { Dropdown } from 'libs.nucleus.dropdown';
import { Icon } from 'libs.nucleus.icon';
import { FormatterWrapper } from './formatter_wrapper';
var AlignContentOption;
(function (AlignContentOption) {
    AlignContentOption["LEFT"] = "left";
    AlignContentOption["CENTER"] = "center";
    AlignContentOption["RIGHT"] = "right";
})(AlignContentOption || (AlignContentOption = {}));
const alignContentActions = [
    { type: AlignContentOption.LEFT, icon: 'alignLeft', label: 'Align left' },
    { type: AlignContentOption.CENTER, icon: 'alignCenter', label: 'Align center' },
    { type: AlignContentOption.RIGHT, icon: 'alignRight', label: 'Align right' },
];
export const AlignContent = ({ onClick }) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        // For aligning the content, select all and apply the alignment
        return editor.registerCommand(FORMAT_ELEMENT_COMMAND, () => {
            $selectAll();
            return false;
        }, 1);
    }, [editor]);
    return (_jsx(FormatterWrapper, { children: _jsx(Dropdown, { variant: 'icon', iconName: 'formatAlignList', iconSize: 20, label: 'Format alignment', items: alignContentActions.map((action) => ({
                id: action.type,
                content: _jsx(Icon, { name: action.icon, color: 'black' }),
                onClick: () => {
                    onClick && onClick(action.type);
                    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, action.type);
                },
                ariaLabel: action.label,
            })), "aria-label": `Format alignment` }, 'align') }));
};
