import { jsx as _jsx } from "react/jsx-runtime";
import { $applyNodeReplacement, DecoratorNode } from 'lexical';
import { Suspense } from 'react';
const imageCache = new Set();
const useSuspenseImage = (src) => {
    if (!imageCache.has(src)) {
        throw new Promise((resolve) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                imageCache.add(src);
                resolve(null);
            };
            img.onerror = () => {
                imageCache.add(src);
            };
        });
    }
};
const LazyImage = ({ src, alt, title }) => {
    useSuspenseImage(src);
    return (_jsx(Suspense, { fallback: _jsx("div", { children: "Broken Image" }), children: _jsx("img", { src: src, alt: alt, title: title }) }));
};
export class ImageNode extends DecoratorNode {
    constructor(src, alt = '', title = '', imageKey = '', key) {
        super(key);
        this.src = src;
        this.alt = alt;
        this.title = title;
        this.imageKey = imageKey;
        this.key = key;
    }
    exportDOM() {
        const element = document.createElement('img');
        element.setAttribute('src', `{${this.imageKey}}`);
        this.alt && element.setAttribute('alt', this.alt);
        this.title && element.setAttribute('title', this.title);
        return { element };
    }
    updateDOM() {
        return false;
    }
    createDOM() {
        return document.createElement('span');
    }
    exportJSON() {
        return {
            src: this.src,
            alt: this.alt,
            title: this.title,
            imageKey: this.imageKey,
            type: 'image',
            version: '1',
        };
    }
    remove(preserveEmptyParent) {
        super.remove(preserveEmptyParent);
    }
    decorate() {
        return _jsx(LazyImage, { src: this.src, alt: this.alt, title: this.title });
    }
    getImageKey() {
        return this.imageKey;
    }
    static getType() {
        return 'image';
    }
    static importDOM() {
        return {
            img: (node) => {
                if (node instanceof HTMLImageElement) {
                    return {
                        conversion: () => ({
                            node: $createImageNode({ src: node.src, imageKey: node.getAttribute('data-image-key') || undefined }),
                        }),
                        priority: 0,
                    };
                }
                return null;
            },
        };
    }
    static importJSON(jsonNode) {
        const { alt, src, title, imageKey } = jsonNode;
        const node = $createImageNode({ src, alt, title, imageKey });
        return node;
    }
    static clone(node) {
        return new ImageNode(node.src, node.alt, node.title, node.imageKey);
    }
}
export const $createImageNode = ({ src, alt, title, key, imageKey }) => {
    return $applyNodeReplacement(new ImageNode(src, alt, title, imageKey, key));
};
export const $isImageNode = (node) => {
    return node instanceof ImageNode;
};
