import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { AuthWrapper } from '../../components';
import { AuthContext, AuthRoute } from '../../contexts';
import { useEmailField } from '../../hooks';
/**
 * The forgot password page is used when a user wants to reset the password
 * It will trigger an email to the user with a link to reset the password
 */
export const ForgotPasswordPage = () => {
    const translate = useLocalizeMessage();
    const { forgotPassword, error } = useContext(AuthContext);
    const navigate = useNavigate();
    const { email, emailError, emailHasError, emailTouched, onEmailBlur, onEmailChange } = useEmailField();
    const isSubmitDisabled = !email || emailHasError;
    const onSubmit = async () => {
        if (!isSubmitDisabled) {
            await forgotPassword(email);
            navigate(AuthRoute.LOGIN);
        }
    };
    const onGoBack = () => {
        navigate(AuthRoute.LOGIN);
    };
    return (_jsxs(AuthWrapper, { onSubmit: onSubmit, error: error, children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx("h2", { className: 'text-lg font-medium mb-0 font-sans', children: translate('Enter email') }), _jsx("span", { className: 'text-gray-500', children: translate('If there is an account with that address, you will receive an email to reset the password.') }), _jsx(Input, { errorMessage: emailError, hasError: emailTouched && emailHasError, id: 'email', label: translate('Email'), onBlur: onEmailBlur, onChange: onEmailChange, required: true, hideRequiredIndicator: true, value: email, width: 'full' })] }), _jsxs("div", { className: 'flex flex-col gap-4 pt-8', children: [_jsx(Button, { label: translate('Submit'), disabled: isSubmitDisabled }), _jsx(Button, { label: translate('Go back'), variant: 'transparent', onClick: onGoBack })] })] }));
};
