import { useQuery } from '@tanstack/react-query';
import { ResourceType } from 'libs.library_manager';
import { useApiClient } from 'libs.react.contexts';
export function useLibraryDetails(id, type = ResourceType.ACTIVITY, withUrl = false) {
    const { get } = useApiClient("library" /* ApiClientService.LIBRARY */);
    return useQuery({
        queryKey: ['library', 'details', id],
        queryFn: () => {
            const params = withUrl ? { withUrl: true } : {};
            return get(`v1/${type}/${id}`, { params });
        },
        enabled: !!id,
        retry: false,
    });
}
