import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useMemo, useCallback, useContext } from 'react';
import { Locale, getLocaleMetaInfo } from 'libs.i18n-core';
import { LocaleContext, useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Table } from 'libs.nucleus.table';
import { SelectLocaleTypeStep } from './select_locale_type_step';
/**
 * Returns an array of objects that represent the locales available in the app.
 * @param currentLocale - The current locale of the app. Defaults to 'en_US'.
 * @returns An array of objects that represent the locales available in the app, ready to be displayed in a table.
 */
export const getLocalesTableArray = (currentLocale = Locale.en_US, hideLocales) => {
    const locales = (hideLocales === null || hideLocales === void 0 ? void 0 : hideLocales.length)
        ? Object.values(Locale).filter((locale) => !hideLocales.includes(locale))
        : Object.values(Locale);
    return locales.map((locale) => {
        const { region, language } = getLocaleMetaInfo({ appLocale: currentLocale, targetLocale: locale });
        return { code: locale, region, language };
    });
};
/**
 * A modal window that allows the user to add locales to the app.
 */
export const SelectLocalesModal = ({ onClose, onAddLocales, initialSelectedLocales = [], showLocaleTypeSelectStep, hideLocales, }) => {
    const translate = useLocalizeMessage();
    const [isPristine, setIsPristine] = useState(true);
    const [step, setStep] = useState(0);
    const [localeType, setLocaleType] = useState('site');
    const currentLocale = useContext(LocaleContext);
    const locales = useMemo(() => getLocalesTableArray(currentLocale || Locale.en_US, hideLocales), [currentLocale]);
    const [selectedLocales, setSelectedLocales] = useState(initialSelectedLocales);
    const onRowSelectionChange = useCallback((selectedRows) => {
        setSelectedLocales(selectedRows);
        setIsPristine(false);
    }, []);
    const onDoneClick = useCallback(() => {
        const savedLocales = locales
            .filter((locale) => selectedLocales.includes(locale.code))
            .map((locale) => (Object.assign(Object.assign({}, locale), { type: localeType })));
        onAddLocales(savedLocales);
    }, [selectedLocales, localeType]);
    const cancelButton = useMemo(() => ({
        label: translate('Cancel'),
        onClick: onClose,
    }), []);
    const doneButton = useMemo(() => ({
        disabled: isPristine,
        label: translate('Save'),
        onClick: onDoneClick,
    }), [isPristine, onDoneClick]);
    const localesColumns = useMemo(() => {
        return [
            { header: translate('Code'), accessorKey: 'code' },
            { header: translate('Language/Script'), accessorKey: 'language' },
            { header: translate('Country/Region'), accessorKey: 'region' },
        ];
    }, []);
    const onContinueClick = () => {
        setStep(1);
        setIsPristine(false);
    };
    const onGoBack = () => {
        setStep(0);
        setIsPristine(false);
    };
    const continueButton = {
        label: translate('Continue'),
        onClick: onContinueClick,
        disabled: selectedLocales.length === 0 || isPristine,
    };
    const backButton = { label: translate('Back'), onClick: onGoBack };
    return (_jsx(ModalWindow, { title: translate('Select locales'), isOpen: true, closeWindow: onClose, footerSecondaryActionButtons: showLocaleTypeSelectStep && step === 1 ? [cancelButton, backButton] : [cancelButton], footerPrimaryActionButton: showLocaleTypeSelectStep && step === 0 ? continueButton : doneButton, width: 'lg', children: _jsx("section", { className: 'min-h-[30rem]', children: !showLocaleTypeSelectStep || step === 0 ? (_jsx(Table, { data: locales, columns: localesColumns, labelNoResults: translate('No locales'), getRowId: 'code', hasPagination: true, initialPageSize: 10, paginationNavigationScreenReaderLabel: translate('Select locales table navigation'), isSelectable: true, initialSelectedIds: selectedLocales, onRowSelectionChange: onRowSelectionChange, useInternalSearch: true, labelSearchField: translate('Search locales') })) : (_jsx(SelectLocaleTypeStep, { localeType: localeType, onLocaleTypeChange: setLocaleType })) }) }));
};
