import { useContext } from 'react';
import { LocaleContext } from 'libs.nucleus.i18n';
export const useDateTime = () => {
    const currentLocale = useContext(LocaleContext);
    const parseDateShort = (date) => {
        if (!date) {
            return '';
        }
        const formatter = new Intl.DateTimeFormat(currentLocale, {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
        if (typeof date === 'string') {
            return formatter.format(new Date(date));
        }
        return formatter.format(date);
    };
    const parseDateTimeShort = (date) => {
        if (!date) {
            return '';
        }
        const formatter = new Intl.DateTimeFormat(currentLocale, {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        });
        if (typeof date === 'string') {
            return formatter.format(new Date(date));
        }
        return formatter.format(date);
    };
    return { parseDateShort, parseDateTimeShort };
};
