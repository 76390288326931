import { jsx as _jsx } from "react/jsx-runtime";
import { EM_DASH } from 'libs.constants';
import { useLocalization } from 'libs.localization.react';
import { Locale } from 'libs.nucleus.i18n';
import { Pill } from 'libs.nucleus.pill';
export const AssetBaseVersion = ({ asset }) => {
    const { localizationEntry } = useLocalization(asset);
    const baseVersion = localizationEntry?.supported_locales?.[localizationEntry?.base_locale ?? Locale.en_US]?.version;
    if (!baseVersion) {
        return EM_DASH;
    }
    return _jsx(Pill, { label: `v${baseVersion}`, backgroundColor: 'gray' });
};
