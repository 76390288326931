import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQuery } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { v4 } from 'uuid';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Select } from 'libs.nucleus.select';
import { TextArea } from 'libs.nucleus.text_area';
import { useApiClient } from 'libs.react.contexts';
import { useToastNotification } from 'libs.react.hooks';
import { LibraryEndpoint, LibraryResourceStatus } from 'libs.react.types';
import { StudyConfigurationContext } from '../../contexts';
const DEFAULT_EXTENSION_FORM = {
    name: '',
    customization: { label: '-select-', value: '' },
    description: '',
};
export const CreateExtensionModal = ({ onClose }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const libraryClient = useApiClient("library" /* ApiClientService.LIBRARY */);
    const { assignStudy, currentStudy, studyId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const [isPristine, setIsPristine] = useState(true);
    const [extensionForm, setExtensionForm] = useState(DEFAULT_EXTENSION_FORM);
    const createEnabled = !isPristine && !!extensionForm.name && !!extensionForm.customization.value;
    const retrieveCustomizations = async () => {
        const searchParams = new URLSearchParams({ status: LibraryResourceStatus.PUBLISHED });
        const endpoint = `${LibraryEndpoint.GET_CUSTOMIZATIONS}?${searchParams.toString()}`;
        const { data: response } = await libraryClient.get(endpoint);
        const options = response.data.map((customization) => ({ label: customization.name, value: customization.id }));
        return [{ label: translate('-select-'), value: '' }, ...options];
    };
    const { data: customizationOptions = [] } = useQuery({
        queryKey: ['customizations'],
        queryFn: retrieveCustomizations,
    });
    const createExtension = async () => {
        if (createEnabled) {
            const customPackages = Object.values(currentStudy.getConfiguration().customPackages || {});
            const customPackage = {
                description: extensionForm.description,
                id: v4(),
                mappingKey: extensionForm.customization.value,
                name: extensionForm.name,
            };
            currentStudy.setCustomPackages([...customPackages, customPackage]);
            assignStudy(currentStudy);
            logEvent('extension_added', {
                study_id: studyId,
                extension_id: customPackage.id,
                extension_name: customPackage.name,
            });
            addNotification({
                title: translate('Extension created'),
                subtitle: translate(`A new extension with the latest build configuration has been created.`),
                type: 'success',
            });
            onClose();
        }
    };
    const handleCustomizationChange = (newCustomization) => {
        setExtensionForm((oldExtensionForm) => ({ ...oldExtensionForm, customization: newCustomization }));
        setIsPristine(false);
    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setExtensionForm((oldExtensionForm) => ({ ...oldExtensionForm, [id]: value }));
        setIsPristine(false);
    };
    const savePrimaryButton = {
        label: translate('Create'),
        onClick: createExtension,
        disabled: !createEnabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            createExtension();
        }
    };
    const onSubmit = (event) => {
        event.preventDefault();
        if (createEnabled) {
            createExtension();
        }
    };
    const formSections = [
        {
            title: translate('Details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(Input, { id: 'name', label: translate('Name'), maxLength: 50, onChange: handleInputChange, required: true, value: extensionForm.name, width: 'lg' }), _jsx(TextArea, { id: 'description', maxLength: 200, label: translate('Description'), onChange: handleInputChange, value: extensionForm.description, width: 'lg' })] })),
        },
        {
            title: translate('Content'),
            content: (_jsx("div", { className: 'flex flex-col gap-6', children: _jsx(Select, { label: translate('Customization'), onChange: handleCustomizationChange, options: customizationOptions, required: true, value: extensionForm.customization, width: 'lg' }) })),
        },
    ];
    return (_jsx(ModalWindow, { title: translate('Add extension'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onSubmit: onSubmit, onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) }) }));
};
