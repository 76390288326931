import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { EM_DASH } from 'libs.constants';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { FormattedDateTime } from 'libs.react.components';
import { LocalesCount } from 'libs.translation_workbench';
import { useActivityUtils } from '../../../hooks';
export const useActivitiesTableColumns = () => {
    const translate = useLocalizeMessage();
    const { getActivityTypeName } = useActivityUtils();
    return useMemo(() => [
        {
            header: translate('Short name'),
            accessorKey: 'short_name',
            accessorFn: (activity) => activity.data.short_name || EM_DASH,
        },
        {
            header: translate('Display name'),
            accessorKey: 'full_name',
            accessorFn: (activity) => activity.data.full_name || EM_DASH,
        },
        {
            header: translate('Activity type'),
            accessorKey: 'type',
            accessorFn: (resource) => getActivityTypeName(resource),
        },
        { header: translate('Author version'), accessorKey: 'data.version' },
        {
            header: translate('Build version'),
            accessorFn: (resource) => resource.data.activity_metadata?.activity_version || resource.version,
        },
        {
            header: translate('Locales'),
            cell: ({ row }) => _jsx(LocalesCount, { asset: { activity: row.original.data } }),
        },
        {
            header: translate('Last updated'),
            cell: ({ row }) => _jsx(FormattedDateTime, { date: row.original.updatedAt }),
        },
    ], [translate]);
};
