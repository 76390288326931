import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Icon } from 'libs.nucleus.icon';
import { useBooleanState } from 'libs.react.hooks';
import { FormatterWrapper } from './formatter_wrapper';
import { ImageUploadModal } from '../components';
export const ImageUpload = ({ onUpload, uploader = () => null }) => {
    const t = useLocalizeMessage();
    const [isImageUploadModalOpen, openImageUploadModal, closeImageUploadModal] = useBooleanState(false);
    const [canceled, setCanceled] = useState(false);
    const onOpen = useCallback(() => {
        setCanceled(false);
        openImageUploadModal();
    }, []);
    const onClose = useCallback((canceled) => {
        setCanceled(!!canceled);
        closeImageUploadModal();
    }, []);
    return (_jsxs(FormatterWrapper, { children: [_jsx(Button, { label: t('Upload image'), variant: 'transparent', type: 'button', size: 'full', content: _jsx(Icon, { name: 'image', size: 20, color: 'black' }), onClick: onOpen, disabled: false }), _jsx(ImageUploadModal, { isOpen: isImageUploadModalOpen, onClose: onClose, onUpload: onUpload, uploader: uploader, canceled: canceled })] }));
};
