import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Locale } from 'libs.i18n-core';
import { useLocalization, useLocalizationExport } from 'libs.localization.react';
import { Spinner } from 'libs.nucleus.spinner';
import { useBooleanState } from 'libs.react.hooks';
import { useAssetContext } from '../../hooks/use_asset_context.hook';
import { TableExport } from '../export_table';
import { SingleExportTranslationModal } from '../single_export_modal';
export const SectionExport = ({ asset, trackingContext = {} }) => {
    const [isModalOpen, openModal, closeModal] = useBooleanState(false);
    const { localizationEntry } = useLocalization(asset);
    const { exportLocalization, isFetching: isExporting } = useLocalizationExport();
    let lastExportedAt = localizationEntry?.supported_locales?.[Locale.en_US]?.last_exported_at;
    if (lastExportedAt) {
        lastExportedAt = lastExportedAt.toDate();
    }
    const { logEvent } = useContext(FirebaseAppContext);
    const { getReviewUrl } = useAssetContext();
    const handleExport = useCallback((resources) => {
        closeModal();
        if (asset) {
            exportLocalization({
                assets: [asset],
                resources,
                locales: [Locale.en_US],
            });
            logEvent('translations_base_language_exported', {
                id: asset.id,
                type: asset.type,
                ...trackingContext,
            });
        }
    }, [asset, trackingContext]);
    if (!asset) {
        return null;
    }
    return (_jsxs("div", { className: 'relative', children: [isExporting && _jsx(Spinner, { wrapper: 'full' }), _jsx(TableExport, { asset: asset, onExportButtonClick: openModal, data: { locale: 'en-US', last_exported: lastExportedAt }, isExporting: isExporting, reviewPath: `${getReviewUrl()}/${Locale.en_US}?assetType=${asset?.type}` }), isModalOpen && _jsx(SingleExportTranslationModal, { onModalClose: closeModal, onHandleExport: handleExport })] }));
};
