var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header } from 'libs.nucleus.header';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Icon } from 'libs.nucleus.icon';
import { SideNav } from 'libs.nucleus.side_nav';
import { Spinner } from 'libs.nucleus.spinner';
import { AuthContext, AuthRoute } from 'libs.react.auth';
import { useApiClient } from 'libs.react.contexts';
import { useSelfServiceNavigation } from '../../hooks';
export const PrivateRoute = ({ sectionPath, navItems }) => {
    const iamClient = useApiClient("iam" /* ApiClientService.IAM */);
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isDocumentationLoading, setIsDocumentationLoading] = useState(false);
    const { isLoading, isLoggedIn, logout } = useContext(AuthContext);
    const { appRoutes } = useSelfServiceNavigation();
    useEffect(() => {
        if (!isLoading && !isLoggedIn) {
            navigate(AuthRoute.LOGIN);
        }
    }, [isLoggedIn, isLoading]);
    const headerNavLinks = Object.values(appRoutes)
        .filter(({ hideFromHeader }) => !hideFromHeader)
        .map(({ name, path }) => {
        const onClick = () => navigate(path);
        return { name, path, onClick };
    });
    const openDeveloperFlags = () => {
        navigate('/developer/flags');
    };
    const goToProfilePage = () => {
        navigate('/profile/me');
    };
    const openDocumentation = ({ event }) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        if (!isDocumentationLoading) {
            setIsDocumentationLoading(true);
            const { data: response } = yield iamClient.get('/v1/gitbook/doc-url');
            setIsDocumentationLoading(false);
            if (response.url) {
                window.open(response.url, '_blank');
            }
        }
    });
    const dropdownOptions = useMemo(() => {
        const options = [{ id: 'profile', content: translate('Your profile'), onClick: goToProfilePage }];
        const externalLinks = [
            {
                id: 'documentation',
                content: translate('Documentation'),
                onClick: openDocumentation,
            },
        ];
        if (process.env.ENABLE_DEVELOPER_FLAGS === 'true') {
            options.push({ id: 'developer-flags', content: translate('Developer flags'), onClick: openDeveloperFlags });
        }
        options.push(...externalLinks.map((link) => ({
            id: link.id,
            content: (_jsxs("div", { className: 'flex items-center gap-2', children: [_jsx("span", { children: link.content }), isDocumentationLoading && _jsx(Spinner, { size: 'sm' }), !isDocumentationLoading && _jsx(Icon, { name: 'externalLink', size: 'sm' })] })),
            onClick: link.onClick,
        })));
        return options;
    }, [isDocumentationLoading]);
    const renderHeaderLink = useCallback(({ name, path }) => {
        const isActive = pathname.includes(path);
        return (_jsx(Link, { to: path, className: `inline-block border-b-2 py-[0.9375rem] text-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-1 ${isActive
                ? 'border-primary text-text-primary'
                : 'border-transparent text-neutral-500 hover:border-neutral-300 hover:text-neutral-700'}`, children: name }, path));
    }, [pathname]);
    return (_jsxs("section", { className: 'flex h-screen flex-col overflow-hidden', children: [_jsx("div", { "data-testid": 'app-header', children: _jsx(Header, { activeLinkPath: sectionPath, dropdownOptions: dropdownOptions, navLinks: headerNavLinks, onSignOut: logout, renderLink: renderHeaderLink }) }), _jsxs("div", { className: 'flex grow overflow-hidden', children: [navItems && (_jsx("div", { className: 'flex flex-col bg-neutral-75', "data-testid": 'side-nav-menu', children: _jsx(SideNav, { activeLinkPath: pathname.replace(`${sectionPath}/`, ''), items: navItems, LinkComponent: Link }) })), _jsx("div", { className: 'flex h-full w-full grow flex-col overflow-hidden bg-white', children: _jsx(Suspense, { fallback: _jsx("section", { className: 'flex items-center justify-center h-full', children: _jsx(Spinner, {}) }), children: _jsx(Outlet, {}) }) })] })] }));
};
