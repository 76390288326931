import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { FileUploader } from 'libs.nucleus.file_uploader';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
export const ImportPdfModal = ({ onModalClose, onHandleImport }) => {
    const t = useLocalizeMessage();
    const [fileStates, setFileStates] = useState({});
    const [files, setFiles] = useState({});
    const onDoneClick = () => {
        onHandleImport(files);
    };
    const onFileDelete = (_, deleteIdx, onSuccess) => {
        const newFileStates = { ...fileStates };
        delete newFileStates[deleteIdx];
        setFileStates(newFileStates);
        const newFiles = { ...files };
        delete newFiles[deleteIdx];
        setFiles(newFiles);
        onSuccess();
    };
    const handleFileUpload = (files) => {
        setFileStates(Object.keys(files).reduce((acc, key) => {
            acc[key] = { type: 'success' };
            return acc;
        }, {}));
        setFiles(files);
    };
    const doneButton = {
        label: t('Import'),
        onClick: onDoneClick,
        disabled: Object.keys(fileStates).length === 0,
    };
    const closeButton = {
        label: t('Cancel'),
        onClick: onModalClose,
    };
    return (_jsx(ModalWindow, { title: t('Import PDF'), isOpen: true, closeWindow: onModalClose, footerSecondaryActionButtons: [closeButton], footerPrimaryActionButton: doneButton, position: 'top', children: _jsx(FileUploader, { label: t('Select Resource PDF file'), accept: '.pdf', onFileChange: handleFileUpload, onFileDelete: onFileDelete, fileStates: fileStates, buttonLabel: t('Upload') }) }));
};
