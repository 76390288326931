import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from 'libs.react.components';
const UsersDashboardPage = lazy(() => import('./components/users_dashboard'));
const UserView = lazy(() => import('./components/user_view'));
export const UserManagementApp = () => {
    return (_jsxs(Routes, { children: [_jsx(Route, { index: true, path: 'dashboard', element: _jsx(UsersDashboardPage, {}) }), _jsx(Route, { index: true, path: ':id', element: _jsx(UserView, {}) }), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, {}) })] }));
};
export { USER_MANAGEMENT_FLAGS } from './constants/developer_flags.constants';
