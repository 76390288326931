var _a, _b;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Helmet } from 'react-helmet';
// This code safely retrieves environment variables COMMIT_SHA and BUILD_DATE.
// It's structured this way because 'process' and 'process.env' are not globally
// available in all environments, but the specific env variables are.
// The try-catch ensures it works even if accessing process.env throws an error.
// This approach eliminates the need for local .env file configuration.
let COMMIT_SHA;
let BUILD_DATE;
try {
    COMMIT_SHA = (_a = process.env.COMMIT_SHA) !== null && _a !== void 0 ? _a : 'local';
    BUILD_DATE = (_b = process.env.BUILD_DATE) !== null && _b !== void 0 ? _b : 'current';
}
catch (e) {
    COMMIT_SHA = 'local';
    BUILD_DATE = 'current';
}
export const BuildInfo = () => {
    return (_jsxs(Helmet, { children: [_jsx("meta", { name: 'build_version', content: COMMIT_SHA }), _jsx("meta", { name: 'build_date', content: BUILD_DATE })] }));
};
