import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AssetTypes } from 'libs.localization.common';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { InlineNotification } from 'libs.nucleus.notification';
import { Spinner } from 'libs.nucleus.spinner';
import { PageHeader } from 'libs.react.components';
import { SectionExport } from '../../components/section_export';
import { SectionImport } from '../../components/section_import';
import { TranslationWorkbenchContext } from '../../contexts';
import { useLibraryDetails } from '../../hooks';
export const ActivityAssetView = ({ assetId, studyId }) => {
    const translate = useLocalizeMessage();
    const { locales } = useContext(TranslationWorkbenchContext);
    const { isLoading, data: library } = useLibraryDetails(assetId);
    // autobuild activities return in data.data.data.activity vs ecoa return in data.data.data format
    const resource = library?.data.data.data.activity || library?.data.data.data;
    const resourceDisplayname = resource?.full_name;
    const breadcrumbPages = useMemo(() => [
        { name: translate('Translations'), path: `/studies/${studyId}/build/translations` },
        {
            name: resourceDisplayname || translate('Activities'),
            path: `/studies/${studyId}/build/translations/${assetId}`,
        },
    ], [resourceDisplayname]);
    const asset = {
        type: AssetTypes.ACTIVITY,
        id: resource?.identifier,
        version: resource?.version || '1',
        buildVersion: resource?.activity_metadata?.activity_version,
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: resourceDisplayname || translate('Activities'), breadcrumbPages: breadcrumbPages }), _jsx("hr", { className: 'mb-6' }), _jsxs("div", { className: 'flex flex-col gap-6', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), assetId && (_jsx(InlineNotification, { title: translate('Library asset'), subtitle: translate('Translations done here are applied to the Library, so any change may affect other studies.'), action: _jsx(Link, { LinkComponent: RouterLink, to: `/ecoa/activities/${assetId}#translation`, target: '_blank', rel: 'noopener noreferrer', children: translate('View in Library') }) })), resource && _jsx(SectionExport, { asset: asset, trackingContext: { context: 'study' } }), _jsx("hr", {}), resource && _jsx(SectionImport, { asset: asset, locales: locales })] })] }));
};
