import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { EM_DASH } from 'libs.constants';
import { Dropdown } from 'libs.nucleus.dropdown';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Pill } from 'libs.nucleus.pill';
/**
 * Hook to get the columns for the study participant statuses table
 * @param editParticipantStatus - Callback to edit a participant status
 * @param deleteParticipantStatus - Callback to delete a participant status
 * @param disableEditing - Flag to disable the editing of the statuses
 */
export const useStudyParticipantStatusesColumns = ({ editParticipantStatus, deleteParticipantStatus, disableEditing, }) => {
    const translate = useLocalizeMessage();
    return useMemo(() => {
        return [
            {
                header: translate('Status name'),
                accessorKey: 'value',
                size: 100,
            },
            {
                header: translate('Description'),
                accessorKey: 'description',
                size: 500,
                cell: ({ row }) => row.original.description || EM_DASH,
            },
            {
                header: translate('State'),
                accessorKey: 'state',
                size: 50,
                cell: ({ row }) => {
                    const label = row.original.isInactive
                        ? translate('Inactive')
                        : row.original.isDefault
                            ? translate('Default')
                            : translate('Active');
                    const iconColor = row.original.isInactive ? 'gray' : row.original.isDefault ? 'primary' : 'green';
                    return _jsx(Pill, { label: label, iconBackgroundColor: iconColor, variant: 'status' });
                },
            },
            {
                id: 'options',
                size: 20,
                cell: ({ row }) => {
                    if (row.original.isReadOnly) {
                        return null;
                    }
                    const dropdownItems = [
                        {
                            content: translate('Edit'),
                            id: 'edit',
                            onClick: () => editParticipantStatus(row.original),
                            disabled: disableEditing,
                        },
                        {
                            content: translate('Delete'),
                            destructive: true,
                            disabled: row.original.isDefault || disableEditing,
                            id: 'delete',
                            onClick: () => deleteParticipantStatus(row.original),
                        },
                    ];
                    return (_jsx("section", { className: 'flex flex-col items-end', children: _jsx(Dropdown, { position: 'bottom-end', items: dropdownItems, variant: 'kebab' }) }));
                },
            },
        ];
    }, [editParticipantStatus, deleteParticipantStatus]);
};
