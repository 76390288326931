import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Locale, getLocaleMetaInfo } from 'libs.i18n-core';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { Pill } from 'libs.nucleus.pill';
import { Table } from 'libs.nucleus.table';
import { Text } from 'libs.nucleus.typography';
import { FormattedDateTime } from 'libs.react.components';
import { sortLocaleSensitive } from '../../utils';
import { DashboardImportAction } from '../dashboard_import_action';
import { LocaleStatusIcon } from '../locale_status_icon';
export const TableImport = ({ checklink = false, data, ids, enableImport = false, hasAction = false, trackingContext = {}, }) => {
    const translate = useLocalizeMessage();
    const sortedData = useMemo(() => sortLocaleSensitive(data, 'locale'), [data]);
    const columns = useMemo(() => {
        const colData = [
            {
                header: translate('Locale'),
                accessorKey: 'locale',
                cell: ({ row }) => {
                    if (checklink && !row.original.last_updated) {
                        return (_jsx(Text, { size: 'sm', className: 'text-secondary', children: row.original.locale }));
                    }
                    return (_jsx(Link, { LinkComponent: RouterLink, to: row.original.reviewPath, children: row.original.locale }));
                },
            },
            {
                header: translate('Language/script'),
                accessorKey: 'locale',
                cell: (value) => {
                    const { language } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: value.getValue() });
                    return language;
                },
            },
            {
                header: translate('Country/Region'),
                accessorKey: 'locale',
                cell: (value) => {
                    const { region } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: value.getValue() });
                    return region;
                },
            },
            {
                header: translate('Locale version'),
                accessorKey: 'version',
                cell: ({ row }) => {
                    return row.original.version ? _jsx(Pill, { label: `v${row.original.version}`, backgroundColor: 'gray' }) : '—';
                },
            },
            {
                header: translate('Last updated'),
                cell: ({ row }) => row.original.last_updated ? (_jsx("div", { className: 'min-w-32', children: _jsx(FormattedDateTime, { date: row.original.last_updated }) })) : ('—'),
            },
            {
                header: translate('Status'),
                accessorKey: 'status',
                cell: ({ row }) => row.original.status ? _jsx(LocaleStatusIcon, { status: row.original.status }) : '—',
            },
        ];
        if (hasAction) {
            colData.push({
                header: ' ',
                accessorKey: 'action',
                cell: ({ row }) => row.original.action,
            });
        }
        return colData;
    }, [data]);
    return (_jsxs("div", { className: 'flex flex-col gap-4 items-end', "data-testid": 'table-translations-import', children: [enableImport && (_jsx("div", { className: 'flex items-center gap-4', children: _jsx(DashboardImportAction, { isDisabled: false, variant: 'primary', label: translate('Import translation'), requiredIds: ids, trackingContext: trackingContext }) })), _jsx("div", { className: 'w-full', children: _jsx(Table, { data: sortedData, columns: columns }) })] }));
};
