import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FlagContext } from 'libs.flags.react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { STUDY_MANAGEMENT_FLAGS } from '../../constants';
import { DeploymentContext } from '../../contexts';
export const useNavigation = () => {
    const { studyId } = useParams();
    const translate = useLocalizeMessage();
    const flagManager = useContext(FlagContext);
    const { jobs } = useContext(DeploymentContext);
    const navItems = useMemo(() => {
        const items = [
            {
                icon: 'navCube',
                links: [
                    { title: translate('Schedule'), path: `/studies/${studyId}/build/schedule` },
                    { title: translate('Resources'), path: `/studies/${studyId}/build/resources` },
                    { title: translate('Translations'), path: `/studies/${studyId}/build/translations` },
                    { title: translate('Notifications'), path: `/studies/${studyId}/build/notifications` },
                    { title: translate('Extensions'), path: `/studies/${studyId}/build/extensions` },
                ],
                path: `/studies/${studyId}/build`,
                title: 'Build',
            },
            { title: translate('Versions'), path: `/studies/${studyId}/versions`, icon: 'rectangleStack' },
            { title: translate('Tests'), path: `/studies/${studyId}/tests`, icon: 'window' },
        ];
        if (flagManager.getValue(STUDY_MANAGEMENT_FLAGS.review)) {
            items[0].links.push({ title: translate('Review'), path: `/studies/${studyId}/build/review` });
        }
        return items;
    }, [flagManager, studyId, jobs]);
    return { navItems };
};
