import { useLocalizeMessage } from 'libs.nucleus.i18n';
export const useNameBuilder = () => {
    const translate = useLocalizeMessage();
    const buildName = (name) => {
        if (!name) {
            return '';
        }
        if (name.first && name.last) {
            return translate('{first} {last}', { first: name.first, last: name.last });
        }
        if (name.first) {
            return name.first;
        }
        return name.last;
    };
    return { buildName };
};
