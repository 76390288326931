import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useEffect, useState } from 'react';
import { useBooleanState } from 'libs.react.hooks';
import { ConversationsUtils } from '../../utils';
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ChatContext = createContext(undefined);
export const ChatContextProvider = ({ children }) => {
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState();
    const [isInitialized, setIsInitialized] = useState(false);
    const [isMessageStreaming, setIsMessageStreaming] = useState(false);
    const [isChatOpen, , , toggleChat] = useBooleanState(false);
    const changeConversation = (conversationKey) => {
        let conversation = conversations.find((conversation) => conversation.key === conversationKey);
        if (!conversation) {
            conversation = {
                initialized: false,
                key: conversationKey,
                messages: [],
                prompt: '',
            };
            const updatedConversations = [...conversations, conversation];
            setConversations(updatedConversations);
            ConversationsUtils.saveConversations(updatedConversations);
        }
        setSelectedConversation(conversation);
        ConversationsUtils.saveConversation(conversation);
    };
    const handleUpdateConversation = (conversation, data) => {
        const updatedConversation = {
            ...conversation,
            [data.key]: data.value,
        };
        const newConversations = ConversationsUtils.updateConversations(updatedConversation, conversations);
        updateSelectedConversation(updatedConversation);
        updateConversations(newConversations);
    };
    const updateConversations = (conversations) => {
        setConversations(conversations);
        ConversationsUtils.saveConversations(conversations);
    };
    const updateSelectedConversation = (conversation) => {
        setSelectedConversation(conversation);
        ConversationsUtils.saveConversation(conversation);
    };
    useEffect(() => {
        const conversationHistory = localStorage.getItem('conversationHistory');
        if (conversationHistory) {
            const parsedConversationHistory = JSON.parse(conversationHistory);
            const cleanedConversationHistory = ConversationsUtils.cleanConversationHistory(parsedConversationHistory);
            setConversations(cleanedConversationHistory);
        }
        setIsInitialized(true);
    }, []);
    return (_jsx(ChatContext.Provider, { value: {
            changeConversation,
            conversations,
            handleUpdateConversation,
            isChatOpen,
            isInitialized,
            isMessageStreaming,
            selectedConversation,
            setIsMessageStreaming,
            updateConversations,
            updateSelectedConversation,
            toggleChat,
        }, children: children }));
};
