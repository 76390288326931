var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useState } from 'react';
const ImagesContext = createContext({
    images: {},
});
const ImagesApiContext = createContext({
    addImage: () => { },
    addImages: () => { },
    removeImage: () => { },
});
export const ImagesContextProvider = ({ children, initialValue, }) => {
    const [images, setImages] = useState(typeof initialValue === 'object' && (initialValue === null || initialValue === void 0 ? void 0 : initialValue.images) ? initialValue.images : {});
    const value = useMemo(() => ({ images }), [images]);
    const api = useMemo(() => ({
        addImage: (key, image) => {
            setImages((prevState) => (Object.assign(Object.assign({}, prevState), { [key]: image.src })));
        },
        addImages: (newImages) => {
            setImages((prevState) => (Object.assign(Object.assign({}, prevState), newImages)));
        },
        removeImage: (imageKey) => {
            setImages((prevState) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const _a = prevState, _b = imageKey, _ = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
                return rest;
            });
        },
    }), []);
    return (_jsx(ImagesContext.Provider, { value: value, children: _jsx(ImagesApiContext.Provider, { value: api, children: children }) }));
};
export const useImagesContext = () => useContext(ImagesContext);
export const useImagesApi = () => useContext(ImagesApiContext);
