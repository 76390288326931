import { jsx as _jsx } from "react/jsx-runtime";
import axios from 'axios';
import { createContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { useToastNotification } from 'libs.react.hooks';
import { ErrorCode } from 'libs.react.types';
import { API_ERROR_MESSAGES } from './api_client.constants';
const axiosClient = axios.create({
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    withCredentials: true,
});
export const ApiClientContext = createContext({
    apiClient: undefined,
});
const handleCustomApiError = (customErrors, defaultMessage) => {
    if (!customErrors || !customErrors.length) {
        return defaultMessage;
    }
    if (customErrors.length === 1) {
        return customErrors[0].message;
    }
    // TODO how to handle multiple errors
    return defaultMessage;
};
/**
 * The ApiClientProvider has the intention of being used as a wrapper for the whole application
 * It will provide a unique instance of the axios client to be used across the application
 * It is the intention to use this context through the useApiClient hook
 * that will allow consumers to not care about defining the necessary endpoints depending on the environment
 */
export const ApiClientProvider = ({ children }) => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const navigate = useNavigate();
    useEffect(() => {
        axiosClient.interceptors.response.use((response) => response, (error) => {
            var _a;
            const errorResponseData = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data;
            const formattedError = JSON.parse(JSON.stringify(error));
            const errorNotification = (errorResponseData === null || errorResponseData === void 0 ? void 0 : errorResponseData.errCode) && API_ERROR_MESSAGES[errorResponseData.errCode];
            let errorHandled = false;
            if (errorNotification) {
                addNotification({
                    title: translate(errorNotification.title),
                    subtitle: translate(errorNotification.subtitle),
                    type: 'error',
                });
                errorHandled = true;
                if (errorResponseData.errCode === ErrorCode.ACCESS_DENIED_INVALID_TOKEN) {
                    navigate('/login?expired-session=true');
                }
            }
            else {
                if (formattedError.status === 403) {
                    addNotification({
                        title: translate('Error'),
                        subtitle: formattedError.reason ||
                            translate('You do not have access to this feature. Please contact your administrator.'),
                        type: 'error',
                    });
                    errorHandled = true;
                }
                if (formattedError.status === 500) {
                    addNotification({
                        title: translate('Error'),
                        subtitle: translate('We were unable to process your request. Try again later.'),
                        type: 'error',
                    });
                    errorHandled = true;
                }
                if (formattedError.status === 401) {
                    addNotification({
                        title: translate('Unauthorized'),
                        subtitle: translate('You do not have permission to access this resource. Please contact your administrator.'),
                        type: 'error',
                    });
                    errorHandled = true;
                    navigate('/login?expired-session=true');
                }
                if (formattedError.status === 404 && !error.config.no404Handling) {
                    addNotification({
                        title: translate('Error'),
                        subtitle: translate('The requested resource was not found.'),
                        type: 'error',
                    });
                    errorHandled = true;
                }
                if (formattedError.status === 400) {
                    const customErrors = errorResponseData.errors;
                    const subtitle = handleCustomApiError(customErrors, errorResponseData.message || 'Invalid data passed in the request. Please check and try again.');
                    addNotification({ title: translate('Bad Request'), subtitle: translate(subtitle), type: 'error' });
                    errorHandled = true;
                }
                if (formattedError.status === 413) {
                    addNotification({
                        title: translate('Bad Request'),
                        subtitle: translate('The file(s) uploaded exceed the maximum size limit'),
                        type: 'error',
                    });
                    errorHandled = true;
                }
            }
            // We inject the errorHandled flag into the error object to indicate if the ApiClient was able to notify the error
            const rejectedError = Object.assign(Object.assign({}, error), { response: Object.assign(Object.assign({}, error.response), { data: Object.assign(Object.assign({}, error.data), { errorHandled }) }) });
            return Promise.reject(rejectedError);
        });
    }, []);
    return _jsx(ApiClientContext.Provider, { value: { apiClient: axiosClient }, children: children });
};
