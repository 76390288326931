import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { Button } from 'libs.nucleus.button';
import { deviceZoomLevelAtom } from './device_selector_store';
import { ZoomControlWrapper } from './styles';
const UPPER_LIMIT = 1;
const LOWER_LIMIT = 0.5;
const STEP = 0.2;
export const ZoomControl = ({ upperLimit = UPPER_LIMIT, lowerLimit = LOWER_LIMIT, initialZoomLevel = 1, step = STEP, }) => {
    const [zoomLevel, setZoomLevel] = useAtom(deviceZoomLevelAtom);
    const handleZoom = useCallback((type) => {
        if (type === 'IN' && zoomLevel <= upperLimit) {
            setZoomLevel(zoomLevel + step);
        }
        else if (type === 'OUT' && zoomLevel >= lowerLimit) {
            setZoomLevel(zoomLevel - step);
        }
    }, [setZoomLevel, zoomLevel]);
    useEffect(() => {
        setZoomLevel(initialZoomLevel);
    }, []);
    return (_jsxs(ZoomControlWrapper, { children: [_jsx(Button, { dataTestId: 'zoom-control-zoom-out', label: 'Zoom out', variant: 'secondary', onClick: () => handleZoom('OUT') }), _jsx(Button, { dataTestId: 'zoom-control-zoom-in', label: 'Zoom in', variant: 'secondary', onClick: () => handleZoom('IN') })] }));
};
