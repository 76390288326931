import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, useContext, useEffect, useMemo } from 'react';
import { Link, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { SideNav } from 'libs.nucleus.side_nav';
import { Spinner } from 'libs.nucleus.spinner';
import { StudyConfigurationContext } from '../../contexts';
import { useNavigation } from '../../hooks';
export const StudyWrapper = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const match = useMatch('/studies/:studyId/:section/*');
    const { navItems } = useNavigation();
    const { currentStudy, isInitialized } = useContext(StudyConfigurationContext);
    useEffect(() => {
        validateRoute();
    }, [currentStudy, isInitialized]);
    /**
     * Validates the current study route
     *  - If context is initialized without a study, redirects to the studies main page as there is no study to display
     *  - If there is no route match means the route contains only a possible studyId without a subsection then we
     * redirect to the build page
     */
    const validateRoute = () => {
        if (isInitialized && !currentStudy.name) {
            navigate('/studies');
        }
        if (!isInitialized && !match) {
            navigate('build');
        }
    };
    const studyCardProps = useMemo(() => {
        return { protocolNumber: currentStudy.protocolNumber, studyName: currentStudy.name };
    }, [currentStudy.name, currentStudy.protocolNumber]);
    return (_jsxs("div", { className: 'flex grow overflow-hidden', children: [_jsx("div", { className: 'flex flex-col bg-neutral-75', "data-testid": 'side-nav-menu', children: currentStudy.name !== '' && (_jsx(SideNav, { activeLinkPath: pathname, items: navItems, LinkComponent: Link, studyCard: studyCardProps })) }), _jsxs("div", { className: 'flex h-full w-full grow flex-col overflow-hidden bg-white', children: [!currentStudy.name && (_jsx("section", { className: 'flex items-center justify-center h-full', children: _jsx(Spinner, {}) })), currentStudy.name && (_jsx(Suspense, { fallback: _jsx("section", { className: 'flex items-center justify-center h-full', children: _jsx(Spinner, {}) }), children: _jsx(Outlet, {}) }))] })] }));
};
