import { AssetTypes } from 'libs.localization.common';
export const getActivityNamesAsset = (study) => ({
    type: AssetTypes.ACTIVITY_NAMES,
    id: study.id,
    version: '1',
    messages: study
        .getAllActivities()
        .filter((activity) => !activity?.details?.isBuiltIn && !activity?.details?.isVisitConfirmation)
        .reduce((acc, item) => item.details.libraryId && !acc.some((i) => i.namespace === item.details.libraryId)
        ? [
            ...acc,
            {
                namespace: item.details.libraryId,
                messages: { value: item.name },
            },
        ]
        : acc, []),
    preview: {
        engine: 'react',
        id: AssetTypes.ACTIVITY_NAMES,
        default_viewport: 'iosSmall',
        info: {
            display_name: 'Screening',
            list: [],
        },
    },
});
