import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import { Locale } from 'libs.i18n-core';
import { useProductLocalization } from 'libs.localization.react';
import { Spinner } from 'libs.nucleus.spinner';
import { AssetTranslationScreen } from './asset_translation_screen.component';
const baseLocale = Locale.en_US;
export const AssetReviewScreenList = ({ localizationEntry }) => {
    const { locale } = useParams();
    const { localizationEntry: productLocEntry, isLoading: isLoadingProductLocalzation } = useProductLocalization('product_previews.built_in~1_0');
    if (isLoadingProductLocalzation) {
        return _jsx(Spinner, {});
    }
    const isCompareMode = baseLocale !== locale;
    const localizationResources = localizationEntry?.localization_resources;
    const previewOptions = localizationEntry.metadata?.preview;
    const basePreview = localizationResources?.[baseLocale];
    const localePreview = localizationResources?.[locale];
    const { localization_resources: productLocResources } = productLocEntry;
    const { human_id: humanId } = localizationEntry;
    const baseProductMessages = productLocResources?.[baseLocale]?.[humanId];
    const localeProductMessages = productLocResources?.[locale]?.[humanId];
    const screens = Object.keys(localizationEntry?.metadata?.localization_resources_sort_index?.namespaces || {});
    return screens.map((key, index) => (_jsxs("div", { children: [_jsxs("div", { className: 'mb-2', children: [`${index + 1}. ${baseLocale}`, isCompareMode && ` compared to ${locale}`] }), _jsx(AssetTranslationScreen, { previewOptions: previewOptions, basePreview: basePreview?.[key], localePreview: localePreview?.[key], baseProductMessages: baseProductMessages, localeProductMessages: localeProductMessages }), index < screens.length - 1 && _jsx("hr", {})] }, key)));
};
