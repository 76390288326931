import { AssetTypes, LocalizationStatus } from 'libs.localization.common';
import { AssetRole } from '../types';
/**
 * Retrieves library data based on activities, locales, and view.
 * @returns An object of objects containing the status of each locale and path for each activity.
 */
export const getLibraryData = (activities, locales) => {
    if (!locales.length || !activities || typeof activities !== 'object') {
        return {};
    }
    const data = {};
    for (const activity of activities) {
        // filter out built-in activities: Authentication, Enter participant ID, and Visit confirmation
        if (activity.details.isBuiltIn || activity.details.isVisitConfirmation) {
            continue;
        }
        const localeData = {};
        for (const locale of locales) {
            localeData[locale] = locale === 'en-US' ? LocalizationStatus.READY : LocalizationStatus.NOT_STARTED; // @TODO: Query for the actual status
        }
        data[activity.name] = {
            statuses: localeData,
            type: `${activity.responderRole} activities`,
            path: activity.details.libraryId || '', // Replacing activity.details.code with activity.details.libraryId
            assetType: AssetTypes.ACTIVITY,
            id: activity.details.libraryId,
            buildVersion: activity.details.buildVersion,
            asset: {
                type: AssetTypes.ACTIVITY,
                id: activity.details.code,
                version: '1.0',
            },
            role: activity.responderRole === 'Site' ? AssetRole.SITE : AssetRole.PARTICIPANT,
        };
    }
    return data;
};
