import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAtom } from 'jotai';
import { useCallback, useContext, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { LocalizationStatus as StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { AssetTypes } from 'libs.localization.common';
import { useLocalization, useLocalizationExport } from 'libs.localization.react';
import { Locale, useLocalizeMessage } from 'libs.nucleus.i18n';
import { Text } from 'libs.nucleus.typography';
import { DeviceSelector, selectedDeviceAtom } from 'libs.preview';
import { PageHeader } from 'libs.react.components';
import { LocaleStatusProgress } from '../../components';
import { AssetsInfoPanel } from '../../components/assets_info_panel';
import { AssetReviewScreenList } from '../../components/review_screenlist/asset_review_screenlist.component';
import { TranslationWorkbenchContext } from '../../contexts';
import { deviceList as defaultDeviceList } from '../../utils/device_list';
import { getDevicelistForAssetType } from '../../utils/device_utils';
import { toSentenceCase } from '../../utils/string_utils';
export const ReviewView = ({ assetId, studyId }) => {
    const t = useLocalizeMessage();
    const [selectedDevice] = useAtom(selectedDeviceAtom);
    const { locale = Locale.en_US } = useParams();
    const [queryParams] = useSearchParams();
    const assetType = queryParams.get('assetType');
    const { logEvent } = useContext(FirebaseAppContext);
    const { exportLocalization } = useLocalizationExport();
    const { studyLocalizationStatus, study, studyAssets, notificationsAssets } = useContext(TranslationWorkbenchContext);
    const isStudyinDraft = studyLocalizationStatus !== StudyLocalizationStatus.READY;
    const asset = {
        id: assetId,
        type: assetType,
        version: '1', // @TODO figure out version
    };
    const { localizationEntry } = useLocalization(asset);
    const localeVersion = localizationEntry?.supported_locales?.[locale]?.version;
    let pageTitle = toSentenceCase(assetType);
    if (assetType === AssetTypes.RESOURCES) {
        const { studyResources } = study.getStudyMetadata();
        const resource = studyResources.find((r) => r.id === assetId);
        pageTitle = toSentenceCase(resource?.title) || t('Resource');
    }
    else if ([AssetTypes.NOTIFICATION_PUSH_NOTIFICATION, AssetTypes.NOTIFICATION_EMAIL, AssetTypes.NOTIFICATION_SMS].includes(assetType)) {
        // @TODO: Revisit this
        const notification = Object.keys(notificationsAssets).find((value) => notificationsAssets[value].id === assetId);
        pageTitle = toSentenceCase(notification) || t('Notification');
    }
    else {
        pageTitle =
            Object.keys(studyAssets).find((value) => studyAssets[value].assetType === assetType) || toSentenceCase(assetType);
    }
    const breadcrumbPages = useMemo(() => [
        { name: t('Translations'), path: `/studies/${studyId}/build/translations` },
        {
            name: pageTitle,
            path: `/studies/${studyId}/build/translations/${assetId}?assetType=${assetType}`,
        },
        { name: locale || '', path: `/studies/${studyId}/build/translations/${assetId}/review/${locale}` },
    ], [studyId, assetId]);
    const handleExport = useCallback(() => {
        if (asset) {
            const viewport = Object.entries(defaultDeviceList).find(([, value]) => value.device === selectedDevice.device)?.[0];
            exportLocalization({
                assets: [asset],
                resources: ['screens'],
                locales: [locale],
                ...(viewport && { viewport }),
            });
            logEvent('translations_screen_report_downloaded', {
                context: 'study',
                type: assetType,
                id: assetId,
                locale,
            });
        }
    }, [asset, locale, selectedDevice]);
    const headerOptions = [
        { name: t('Export'), onClick: () => handleExport(), variant: 'primary', disabled: isStudyinDraft },
    ];
    // @TODO Consume this info from firebase
    const info = [{ title: t('Name'), description: pageTitle }];
    const hasResource = localizationEntry.localization_resources && !!Object.keys(localizationEntry.localization_resources).length;
    const previewOptions = localizationEntry.metadata?.preview;
    const deviceList = getDevicelistForAssetType(assetType);
    const defaultDevice = previewOptions?.default_viewport && deviceList[previewOptions?.default_viewport]
        ? deviceList[previewOptions?.default_viewport]
        : deviceList[Object.keys(deviceList)[0]];
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: locale, breadcrumbPages: breadcrumbPages, options: headerOptions, customOptions: locale !== Locale.en_US && asset ? (_jsx(LocaleStatusProgress, { asset: asset, locale: locale, assetId: assetId, isDisabled: isStudyinDraft, trackingContext: { context: 'study', type: assetType } })) : (_jsx("span", {})) }), _jsx("hr", { className: 'mb-6' }), _jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(AssetsInfoPanel, { listItems: info, locale: locale, title: t('Information'), version: localeVersion }), _jsx("hr", {}), _jsxs("div", { className: 'flex flex-col gap-2', children: [_jsx(Text, { size: 'sm', children: t('Device size') }), defaultDevice && _jsx(DeviceSelector, { devices: deviceList, defaultDevice: defaultDevice, showDimensions: true })] }), hasResource && _jsx(AssetReviewScreenList, { localizationEntry: localizationEntry })] })] }));
};
