import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Duration } from 'luxon';
import { useContext, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { ActivityType, DefaultActivityId, LocalizationStatus, } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Dropdown } from 'libs.nucleus.dropdown';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Icon } from 'libs.nucleus.icon';
import { useDialog } from 'libs.nucleus.modal_dialog';
import { useBooleanState, useToastNotification } from 'libs.react.hooks';
import { EndingConditionType, StartingConditionType } from './activity_dependencies';
import { ActivityInstanceEditModal } from './activity_instance_edit_modal';
import { EnhancedMatrixRow, ScheduleTimelineContext } from '../../../components';
import { StudyConfigurationContext } from '../../../contexts';
import { useActivityUtils } from '../../../hooks';
const ACTIVITY_STYLES_MAP = {
    [ActivityType.SITE]: {
        background: 'bg-green-50',
        icon: 'bg-green-500',
    },
    [ActivityType.PARTICIPANT]: {
        background: 'bg-purple-50',
        icon: 'bg-purple-500',
    },
    [ActivityType.SITE_WORKFLOW]: {
        background: 'bg-blue-50',
        icon: 'bg-blue-500',
    },
};
export const ScheduleTimelineRow = ({ item, dataTestId, headers, getCellStyles, width, rowIndex, }) => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const { getActivityIcon } = useActivityUtils();
    const { logEvent } = useContext(FirebaseAppContext);
    const [activityToEdit, setActivityToEdit] = useState(undefined);
    const [visitToEdit, setVisitToEdit] = useState(undefined);
    const { currentStudy, assignStudy, currentTimelineId, studyId } = useContext(StudyConfigurationContext);
    const [isActivityInstanceEditModalOpen, openActivityInstanceEditModal, closeActivityInstanceEditModal] = useBooleanState();
    const { onEditActivityClick, onMoveActivityClick } = useContext(ScheduleTimelineContext);
    const dialog = useDialog();
    const { activities, isActivityOnVisit, onDeleteActivityClick, toggleActivityOnVisit, getVisitById, hasDependentNotifications, hasDependentWorkflows, } = useContext(StudyConfigurationContext);
    const readOnlyStudy = currentStudy.localizationStatus === LocalizationStatus.READY;
    const onActivityDelete = async () => {
        const hasNotificationsDependency = hasDependentNotifications(item);
        const hasWorkflowsDependency = hasDependentWorkflows(item);
        if (hasNotificationsDependency || hasWorkflowsDependency) {
            const body = hasNotificationsDependency
                ? translate('This activity cannot be deleted because it has a notification attached. Please modify or remove the notification first.')
                : translate('This activity cannot be deleted because it has a workflow attached. Please modify or remove the workflow first.');
            await dialog.open({
                title: translate('Unable to delete activity'),
                body,
                actionButtons: [{ label: translate('Ok') }],
            });
        }
        else {
            onDeleteActivityClick(item);
            logEvent('activity_deleted', {
                study_id: studyId,
                activity_id: item.id,
                activity_name: item.name,
                timeline_id: currentTimelineId,
            });
        }
    };
    const activityOptions = useMemo(() => {
        const filteredActivities = activities.filter((activity) => activity.id !== DefaultActivityId.VISIT_CONFIRMATION);
        return [
            {
                id: 'Edit',
                content: translate('Edit'),
                onClick: () => onEditActivityClick(item),
                disabled: readOnlyStudy,
            },
            {
                id: 'Move',
                content: translate('Move'),
                hidden: item.id === DefaultActivityId.AUTHENTICATION,
                onClick: () => onMoveActivityClick(item),
                disabled: filteredActivities.length === 1 || readOnlyStudy,
            },
            {
                id: 'Delete',
                content: translate('Delete'),
                destructive: true,
                hidden: item.details.isBuiltIn,
                onClick: onActivityDelete,
                disabled: readOnlyStudy,
            },
        ];
    }, [activities, item]);
    const handleCellCogClick = (event, activity, visit) => {
        event.stopPropagation();
        setActivityToEdit(activity);
        setVisitToEdit(visit);
        openActivityInstanceEditModal();
    };
    const handleOnSaveActivityInstance = ({ endingConditions, startingConditions }) => {
        if (visitToEdit && activityToEdit) {
            const activitySequence = visitToEdit.getActivitySequenceByActivityId(activityToEdit.id);
            const activity = currentStudy.activities[activityToEdit.id];
            let windowDuration;
            let endCriteria = 'until_completion';
            let relativeFromScheduledInstanceId;
            let relativeToScheduledInstanceId;
            let relativeFromOffset;
            let relativeToOffset;
            const baseCondition = {
                scheduleTimelineId: currentTimelineId,
                scheduleTimelineName: currentStudy.getScheduleTimelines()[currentTimelineId].name,
                encounterId: visitToEdit.id,
                encounterName: visitToEdit.name,
                activityName: activityToEdit.name,
                activityId: activityToEdit.id,
                activityInstanceId: activitySequence.instanceId,
            };
            const startConditions = [];
            const endConditions = [];
            if (activitySequence) {
                startingConditions.forEach((condition) => {
                    const fromOffset = condition.offset?.value ? Number(condition.offset.value) : 0;
                    switch (condition.option.value) {
                        case StartingConditionType.AFTER_ACTIVITY_COMPLETION:
                        case StartingConditionType.VISIT_CONFIRMATION:
                            relativeFromScheduledInstanceId = condition.activityCompletion.value;
                            relativeFromOffset = fromOffset;
                            startConditions.push({
                                ...baseCondition,
                                id: condition.id || v4(),
                                dependencyType: 'start',
                                dependencyId: condition.activityCompletion.meta?.activityId,
                                dependencyInstanceId: relativeFromScheduledInstanceId,
                                offsetAfterDateAnchor: fromOffset,
                                triggerType: 'activity_completion',
                            });
                            break;
                        case StartingConditionType.ACTIVITY_RESPONSE:
                            startConditions.push({
                                ...baseCondition,
                                id: condition.id || v4(),
                                dependencyType: 'start',
                                dependencyId: condition.activityResponse.activity.meta?.activityId,
                                dependencyInstanceId: condition.activityResponse.activity.value,
                                triggerType: 'screen_response',
                                triggerScreen: condition.activityResponse.screen.label,
                                triggerScreenKey: condition.activityResponse.screen.value,
                                comparisonOperator: '==',
                                comparisonValue: condition.activityResponse.response.value,
                            });
                            break;
                        case StartingConditionType.DATE_RESPONSE:
                            startConditions.push({
                                ...baseCondition,
                                id: condition.id || v4(),
                                dependencyType: 'start',
                                dependencyId: condition.activityResponse.activity.meta?.activityId,
                                dependencyInstanceId: condition.activityResponse.activity.value,
                                triggerType: 'date_screen_response',
                                triggerScreen: condition.activityResponse.screen.label,
                                triggerScreenKey: condition.activityResponse.screen.value,
                                offsetAfterDateAnchor: fromOffset,
                            });
                            break;
                        case StartingConditionType.STUDY_CREATION:
                            relativeFromScheduledInstanceId = '';
                            relativeFromOffset = fromOffset;
                            startConditions.push({
                                ...baseCondition,
                                id: condition.id || v4(),
                                dependencyType: 'start',
                                offsetAfterDateAnchor: fromOffset,
                                triggerType: 'study_creation',
                            });
                            break;
                    }
                });
                endingConditions.forEach((condition) => {
                    const toOffset = condition.offset?.value ? Number(condition.offset.value) : 0;
                    switch (condition.option.value) {
                        case EndingConditionType.AFTER_DURATION:
                            if (condition.duration && condition.durationPeriod) {
                                windowDuration = Duration.fromObject({
                                    [condition.durationPeriod.value]: condition.duration.value || 0,
                                }).toISO();
                                endConditions.push({
                                    ...baseCondition,
                                    id: condition.id || v4(),
                                    dependencyType: 'end',
                                    triggerType: 'duration',
                                    windowDuration,
                                });
                            }
                            break;
                        case EndingConditionType.UPON_COMPLETION:
                            relativeToScheduledInstanceId = activitySequence.instanceId;
                            endCriteria = 'until_completion';
                            endConditions.push({
                                ...baseCondition,
                                id: condition.id || v4(),
                                dependencyType: 'end',
                                triggerType: 'activity_completion',
                                dependencyId: activitySequence.activityId,
                                dependencyInstanceId: relativeToScheduledInstanceId,
                                endCriteria,
                            });
                            break;
                        case EndingConditionType.END_OF_DAY:
                            endCriteria = 'end_of_day';
                            endConditions.push({
                                ...baseCondition,
                                id: condition.id || v4(),
                                dependencyType: 'end',
                                triggerType: 'end_of_day',
                                endCriteria,
                            });
                            break;
                        case EndingConditionType.ACTIVITY_RESPONSE:
                            endConditions.push({
                                ...baseCondition,
                                id: condition.id || v4(),
                                dependencyType: 'end',
                                triggerType: 'screen_response',
                                dependencyId: condition.activityResponse.activity.meta?.activityId,
                                dependencyInstanceId: condition.activityResponse.activity.value,
                                triggerScreen: condition.activityResponse.screen.label,
                                triggerScreenKey: condition.activityResponse.screen.value,
                                comparisonOperator: '==',
                                comparisonValue: condition.activityResponse.response.value,
                            });
                            break;
                        case EndingConditionType.DATE_RESPONSE:
                            endConditions.push({
                                ...baseCondition,
                                id: condition.id || v4(),
                                dependencyType: 'end',
                                dependencyId: condition.activityResponse.activity.meta?.activityId,
                                dependencyInstanceId: condition.activityResponse.activity.value,
                                triggerType: 'date_screen_response',
                                triggerScreen: condition.activityResponse.screen.label,
                                triggerScreenKey: condition.activityResponse.screen.value,
                                offsetAfterDateAnchor: toOffset,
                            });
                            break;
                        case EndingConditionType.AFTER_ACTIVITY_COMPLETION:
                            if (condition.activityCompletion) {
                                relativeToScheduledInstanceId = condition.activityCompletion.value;
                                relativeToOffset = toOffset;
                                endConditions.push({
                                    ...baseCondition,
                                    id: condition.id || v4(),
                                    dependencyType: 'end',
                                    dependencyId: condition.activityCompletion.meta?.activityId,
                                    dependencyInstanceId: relativeToScheduledInstanceId,
                                    triggerType: 'activity_completion',
                                    offsetAfterDateAnchor: toOffset,
                                });
                            }
                            break;
                    }
                });
                activitySequence.timing = {
                    ...activitySequence.timing,
                    startConditions,
                    endConditions,
                    relativeToScheduledInstanceId,
                    relativeToOffset,
                    relativeFromScheduledInstanceId,
                    relativeFromOffset,
                    timingDetails: {
                        ...activitySequence.timing.timingDetails,
                        endCriteria,
                        windowDuration,
                    },
                };
            }
            currentStudy.activities[activityToEdit.id] = activity;
            assignStudy(currentStudy);
            closeActivityInstanceEditModal();
            addNotification({
                title: translate('Activity instance updated'),
                subtitle: translate('The configuration for the activity instance "{name}" has been updated successfully', {
                    name: activityToEdit.name,
                }),
                type: 'success',
            });
            logEvent('activity_instance_saved', {
                study_id: studyId,
                activity_id: activityToEdit.id,
                activity_name: activityToEdit.name,
                visit_id: visitToEdit.id,
                timeline_id: currentTimelineId,
            });
            setActivityToEdit(undefined);
            setVisitToEdit(undefined);
        }
    };
    const isActivitySelectionEnabled = !readOnlyStudy && !item.details.isBuiltIn;
    const isCogEnabled = !readOnlyStudy && (!item.details.isBuiltIn || item.id === DefaultActivityId.AUTHENTICATION);
    const handleToggleActivityOnVisit = (activity, visitId) => {
        if (isActivitySelectionEnabled) {
            toggleActivityOnVisit(activity, visitId);
            logEvent('activity_instance_toggled', {
                study_id: studyId,
                activity_id: activity.id,
                activity_name: activity.name,
                visit_id: visitId,
                timeline_id: currentTimelineId,
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedMatrixRow, { dataTestId: dataTestId, width: width, rowIndex: rowIndex, children: headers.map((header, index) => (_jsxs("div", { className: getCellStyles(index), style: { width: header.width, padding: '0.25rem' }, children: [header.key === 'activityName' && (_jsxs("div", { className: 'sticky left-0 flex h-full w-[17.5rem] items-center justify-between pl-4 pr-1 font-sans text-xs font-medium group', "data-testid": 'schedule-timeline-activity-name-cell-container', children: [getActivityIcon(item.details.type), _jsx("span", { className: 'flex-1 ml-4 line-clamp-2', "data-testid": 'schedule-timeline-row-name', title: item.name, children: item.name }), (readOnlyStudy || item.id !== DefaultActivityId.VISIT_CONFIRMATION) && (_jsx("div", { className: 'opacity-0 group-hover:opacity-100 group-focus:opacity-100 group-focus-within:opacity-100 has-[button[aria-expanded="true"]]:opacity-100', children: _jsx(Dropdown, { items: activityOptions, variant: 'kebab' }) }))] })), header.key !== 'activityName' &&
                            (isActivityOnVisit(item, header.key) ? (_jsxs("div", { className: `flex h-full w-full items-center justify-center rounded p-4 relative group ${ACTIVITY_STYLES_MAP[item.details.type]?.background} ${isActivitySelectionEnabled ? 'cursor-pointer' : ''}`, "data-testid": `selected-activity-${item.name}-${header.content}`, onClick: () => handleToggleActivityOnVisit(item, header.key), children: [_jsx("div", { className: `flex items-center justify-center p-[0.1875rem] rounded-full ${ACTIVITY_STYLES_MAP[item.details.type]?.icon}`, children: _jsx(Icon, { name: 'confirm', color: 'white', size: 'xs' }) }), isCogEnabled && (_jsx("div", { className: 'absolute right-2 rounded-full p-[0.1875rem] hover:bg-white text-neutral-400 flex items-center justify-center opacity-0 cursor-pointer group-hover:opacity-100 group-focus:opacity-100 group-focus-within:opacity-100', "data-testid": `activity-instance-cog-${item.name}-${header.content}`, onClick: (e) => handleCellCogClick(e, item, getVisitById(header.key)), children: _jsx(Icon, { name: 'cog', color: 'current', size: 'xs' }) }))] })) : (_jsx("div", { className: `h-full w-full rounded ${header.content && !readOnlyStudy ? 'cursor-pointer hover:bg-neutral-50' : ''}`, "data-testid": header.content ? `unselected-activity-${item.name}-${header.content}` : '', onClick: () => handleToggleActivityOnVisit(item, header.key) })))] }, `timeline-${item.id}-cell-${header.key}`))) }), isActivityInstanceEditModalOpen && activityToEdit && visitToEdit && (_jsx(ActivityInstanceEditModal, { activityToEdit: activityToEdit, visit: visitToEdit, onSave: handleOnSaveActivityInstance, onCancel: closeActivityInstanceEditModal }))] }));
};
