import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useMemo, useContext } from 'react';
import { SDKStudy, LocalizationStatus as StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { FlagContext } from 'libs.flags.react';
import { useNotificationsData } from '../hooks/use_notifications_data.hook';
import { useResourcesData } from '../hooks/use_resources_data.hook';
import { useResourcesPdfData } from '../hooks/use_resourcespdf_data.hook';
import { getLibraryData, getStudyData, TRANSLATION_MANAGEMENT_FLAGS } from '../utils';
export const TranslationWorkbenchContext = createContext({
    locales: [],
    siteLocales: [],
    participantLocales: [],
    isStudyEnglishOnly: false,
    resourcesAssets: {},
    libraryAssets: {},
    studyAssets: {},
    notificationsAssets: {},
    studyId: '',
    studyLocalizationStatus: StudyLocalizationStatus.NOT_STARTED,
    study: new SDKStudy({ name: '' }),
});
export const TranslationWorkbenchProvider = ({ children, study, updateStudy, }) => {
    const { localizationStatus: studyLocalizationStatus = StudyLocalizationStatus.NOT_STARTED } = study || {};
    const studyConfig = study?.getConfiguration() || {};
    const studyMetadata = study?.getStudyMetadata() || {};
    const locales = Array.from(new Set([...studyMetadata.participantLocales, ...studyMetadata.siteLocales]));
    const isStudyEnglishOnly = studyConfig.studyMetadata.enforceBaseLocale;
    const flagManager = useContext(FlagContext);
    const resourcesAssets = useResourcesData(study, locales);
    const resourcesPdfAssets = useResourcesPdfData(study, locales);
    const libraryAssets = useMemo(() => getLibraryData(study.getAllActivities(), locales), [study, locales]);
    const studyAssets = useMemo(() => getStudyData(study, locales), [study, locales]);
    const notificationsAssets = useNotificationsData(study, locales);
    const isNotificationsEnabled = flagManager.getValue(TRANSLATION_MANAGEMENT_FLAGS.customNotificationFlag);
    return (_jsx(TranslationWorkbenchContext.Provider, { value: {
            locales,
            siteLocales: studyMetadata.siteLocales,
            participantLocales: studyMetadata.participantLocales,
            isStudyEnglishOnly,
            resourcesAssets: { ...resourcesAssets, ...resourcesPdfAssets },
            libraryAssets,
            studyAssets,
            notificationsAssets: isNotificationsEnabled ? notificationsAssets : {},
            studyId: study.id,
            studyLocalizationStatus,
            study,
            updateStudy,
        }, children: children }));
};
