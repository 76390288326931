import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AssetTypes } from 'libs.localization.common';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { InlineNotification } from 'libs.nucleus.notification';
import { PageHeader } from 'libs.react.components';
import { SectionExport } from '../../components/section_export';
import { SectionImport } from '../../components/section_import';
import { TranslationWorkbenchContext } from '../../contexts';
import { toSentenceCase } from '../../utils/string_utils';
export const AssetsView = ({ studyId, assetId }) => {
    const t = useLocalizeMessage();
    const [queryParams] = useSearchParams();
    const assetType = queryParams.get('assetType');
    let pageTitle = toSentenceCase(assetType);
    const { locales, study, studyAssets, notificationsAssets } = useContext(TranslationWorkbenchContext);
    if (assetType === AssetTypes.RESOURCES) {
        const { studyResources } = study.getStudyMetadata();
        const resource = studyResources.find((r) => r.id === assetId);
        pageTitle = toSentenceCase(resource?.title) || t('Resource');
    }
    else if ([AssetTypes.NOTIFICATION_PUSH_NOTIFICATION, AssetTypes.NOTIFICATION_EMAIL, AssetTypes.NOTIFICATION_SMS].includes(assetType)) {
        // @TODO: Revisit this
        const notification = Object.keys(notificationsAssets).find((value) => notificationsAssets[value].id === assetId);
        pageTitle = toSentenceCase(notification) || t('Notification');
    }
    else {
        pageTitle =
            Object.keys(studyAssets).find((value) => studyAssets[value].assetType === assetType) || toSentenceCase(assetType);
    }
    const breadcrumbPages = useMemo(() => [
        { name: t('Translations'), path: `/studies/${studyId}/build/translations` },
        { name: pageTitle, path: `/studies/${studyId}/build/translations/${assetId}` },
    ], [assetId, assetType]);
    if (assetId === undefined || assetType === undefined) {
        return _jsx(InlineNotification, { title: t('Missing asset information') });
    }
    const asset = {
        type: assetType,
        id: assetId,
        version: '1', // @TODO figure this out
    };
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: pageTitle, breadcrumbPages: breadcrumbPages }), _jsx("hr", { className: 'mb-6' }), _jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(SectionExport, { asset: asset, trackingContext: { context: 'study' } }), _jsx("hr", {}), _jsx(SectionImport, { asset: asset, locales: locales, trackingContext: { view: 'study', studyId, asset: pageTitle } })] })] }));
};
