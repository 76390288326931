var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useContext } from 'react';
import { ApiClientEndpointsByService } from './api_client.constants';
import { ApiClientContext } from '../../contexts';
/**
 * The useApiClient is a hook that works together with the ApiClientContext to provide a simple way to make requests
 * In any general case, consumers should make calls using this hook and not the context directly
 * This is to ensure that the baseURL is set correctly
 *
 * @param {ApiClientService} service - The service that the client will be used for, needed to define the baseURL (aka endpoint)
 * @param {ApiClientRequestConfig} clientConfig - The client config object that will be merged with the default config
 */
export const useApiClient = (service, clientConfig = {}) => {
    const { apiClient } = useContext(ApiClientContext);
    const getConfig = (config = {}) => {
        const baseURL = ApiClientEndpointsByService[service];
        return Object.assign(Object.assign(Object.assign({}, clientConfig), { baseURL }), config);
    };
    const get = (path, config) => {
        return apiClient.get(path, getConfig(config));
    };
    const post = (path, payload, config) => __awaiter(void 0, void 0, void 0, function* () {
        return yield apiClient.post(path, payload, getConfig(config));
    });
    const put = (path, payload, config) => __awaiter(void 0, void 0, void 0, function* () {
        return yield apiClient.put(path, payload, getConfig(config));
    });
    const patch = (path, payload, config) => __awaiter(void 0, void 0, void 0, function* () {
        return yield apiClient.patch(path, payload, getConfig(config));
    });
    const deleteFn = (path, config) => __awaiter(void 0, void 0, void 0, function* () {
        return yield apiClient.delete(path, getConfig(config));
    });
    return {
        get,
        post,
        put,
        patch,
        delete: deleteFn,
    };
};
