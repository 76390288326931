import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Icon } from 'libs.nucleus.icon';
import { Input } from 'libs.nucleus.input';
import { Select } from 'libs.nucleus.select';
export const MatrixFilter = ({ filters, handleSearch, searchTerm, selectedFilters, handleFilter, }) => {
    const t = useLocalizeMessage();
    return (_jsxs(_Fragment, { children: [_jsx(Input, { placeholder: t('Search by locale or name'), id: 'study_translation_locale_matrix_search', startAdornment: _jsx(Icon, { name: 'search' }), onChange: handleSearch, value: searchTerm }), filters.map((filter) => {
                const handleChange = filter.multiple
                    ? (options) => handleFilter(filter.accessorKey, options.map((o) => o.value))
                    : (option) => handleFilter(filter.accessorKey, option.value);
                const value = filter.multiple
                    ? filter.options.filter((option) => selectedFilters[filter.accessorKey].includes(option.value))
                    : filter.options.find((option) => option.value === selectedFilters[filter.accessorKey]);
                const selectProps = filter.multiple
                    ? {
                        // eslint-disable-next-line @typescript-eslint/prefer-as-const
                        multiple: true,
                        value: value,
                        onChange: handleChange,
                        selectionLabel: value?.length
                            ? t('Select an option ({ count })', { count: value.length })
                            : '-',
                    }
                    : {
                        // eslint-disable-next-line @typescript-eslint/prefer-as-const
                        multiple: false,
                        value: value,
                        onChange: handleChange,
                    };
                return (_jsx("div", { className: filter.size, children: _jsx(Select, { dataTestId: `filter-${filter.accessorKey}`, label: filter.label, options: filter.options, disabled: filter.disabled, width: 'full', ...selectProps }, filter.label) }, filter.label));
            })] }));
};
