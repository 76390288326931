export const STUDY_MANAGEMENT_FLAGS = {
    cloneStudies: {
        id: 'cloneStudies',
        value: false,
        description: 'Enables the ability to clone a study',
        owner: 'Self Service Team',
        name: 'Clone studies',
        extra: { section: 'Studies' },
    },
    review: {
        id: 'review',
        value: false,
        description: 'Enables the review tab under Build section for a study',
        owner: 'Self Service Team',
        name: 'Review',
        extra: { section: 'Studies' },
    },
    chat: {
        id: 'chat',
        value: false,
        description: 'Enables AI chat',
        owner: 'Self Service Team',
        name: 'Medable chat',
        extra: { section: 'Studies' },
    },
    restoreVersion: {
        id: 'restoreVersion',
        value: false,
        description: 'Enables Restoring of study version',
        owner: 'Self Service Team',
        name: 'Restore Version',
        extra: { section: 'Studies' },
    },
};
