import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClientContext, useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Combobox } from 'libs.nucleus.combobox';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Spinner } from 'libs.nucleus.spinner';
import { Table } from 'libs.nucleus.table';
import { AuthContext } from 'libs.react.auth';
import { useApiClient } from 'libs.react.contexts';
import { useNameBuilder, useToastNotification } from 'libs.react.hooks';
import { IAMRole } from 'libs.react.types';
import { useShareStudyModalColumns } from './share_study_modal_columns.hook';
import { StudyConfigurationContext } from '../../contexts';
export const ShareStudyModal = ({ onClose, studyId }) => {
    const translate = useLocalizeMessage();
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const { buildName } = useNameBuilder();
    const { addNotification } = useToastNotification();
    const { logEvent } = useContext(FirebaseAppContext);
    const { getStudyConfiguration } = useContext(StudyConfigurationContext);
    const queryClient = useContext(QueryClientContext);
    const { entityId, user: loggedInUser } = useContext(AuthContext);
    const [usersWithAccess, setUsersWithAccess] = useState([]);
    const [usersToDelete, setUsersToDelete] = useState([]);
    const handleCloseWindow = () => {
        setUsersWithAccess([]);
        setUsersToDelete([]);
        onClose();
    };
    const mapUsersToOptions = (data, isAdded) => {
        return data.reduce((acc, identity) => {
            const { first, last } = identity;
            const builtName = buildName({ first, last });
            const isSameAsLoggedInUser = identity.id === loggedInUser?.id;
            const isStudyOwner = studyConfiguration?.ownerId === identity.id;
            const isStudyAdmin = identity.entitiesAccess.some((entityAccess) => entityAccess.roleGroups.some((roleGroup) => roleGroup === IAMRole.ADMIN));
            // If the user is an admin but not the owner, we don't want to show them in the list
            if (isStudyAdmin && !isStudyOwner) {
                return acc;
            }
            acc.push({
                label: `${identity.email}${builtName ? ` (${builtName})` : ''}`,
                value: identity.id,
                identity,
                isAlreadyAdded: isAdded,
                builtName: `${isSameAsLoggedInUser ? ` ${translate('{name} (You)', { name: builtName })}` : builtName}`,
                isStudyOwner,
                isSameAsLoggedInUser,
            });
            return acc;
        }, []);
    };
    const onRemove = (selectedUserData) => {
        setUsersWithAccess((prev) => prev.filter((option) => option.identity.id !== selectedUserData.identity.id));
        if (selectedUserData.isAlreadyAdded) {
            setUsersToDelete((prev) => [...prev, selectedUserData]);
        }
    };
    const getStudyDetails = async (studyId) => {
        const studyConfiguration = await getStudyConfiguration(studyId);
        if (studyConfiguration) {
            return studyConfiguration;
        }
        onClose();
        addNotification({
            title: translate('Error fetching study details'),
            subtitle: translate('Something went wrong when fetching the study details.'),
            type: 'error',
        });
        return null;
    };
    const { data: studyConfiguration, isLoading: isLoadingStudyConfiguration } = useQuery({
        queryKey: ['studyConfiguration', studyId],
        queryFn: () => getStudyDetails(studyId),
        enabled: !!studyId,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
    const fetchEligibleUsers = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/study/eligibleIdentities?resourcePath=entity/${entityId}/study/${studyId}`);
        return mapUsersToOptions(response.data, false);
    };
    const { data: eligibleUsers = [], isLoading: isFetchingEligibleUsers } = useQuery({
        queryKey: ['eligibleUsers', entityId, 'study', studyId],
        queryFn: fetchEligibleUsers,
        enabled: !!studyConfiguration,
    });
    const fetchUsersWithAccess = async () => {
        const { data: response } = await momClient.get(`/v1/entities/${entityId}/study/identities?resourcePath=entity/${entityId}/study/${studyId}`);
        return mapUsersToOptions(response.data, true);
    };
    const { data: fetchedUsersWithAccess = [], isLoading: isFetchingUsersWithAccess } = useQuery({
        queryKey: ['usersWithAccess', entityId, 'study', studyId],
        queryFn: fetchUsersWithAccess,
        enabled: !!studyConfiguration,
    });
    const saveUsers = async () => {
        const endpoint = (identityId) => `/v1/entities/${entityId}/identities/${identityId}/study/share`;
        const resourcePath = `entity/${entityId}/study/${studyId}`;
        const usersToAddPromises = usersWithAccess
            .filter((user) => !user.isAlreadyAdded)
            .map(async (user) => {
            await momClient.post(endpoint(user.identity.id), {
                resourcePath,
                role: 'roles/mom.studyBuilder',
            });
        });
        const usersToRemovePromises = usersToDelete.map(async (user) => {
            await momClient.delete(endpoint(user.identity.id), { data: { resourcePath } });
        });
        await Promise.all([...usersToAddPromises, ...usersToRemovePromises]);
    };
    const { isPending: isSavingUsers, mutate: handleSave } = useMutation({
        mutationFn: saveUsers,
        onSuccess: async () => {
            await queryClient?.invalidateQueries({ queryKey: ['usersWithAccess', entityId, 'study', studyId] });
            addNotification({
                title: translate('Share study'),
                subtitle: translate('Study shared successfully.'),
                type: 'success',
            });
            logEvent('study_shared', { study_id: studyId });
            handleCloseWindow();
        },
        onError: () => {
            addNotification({
                title: translate('Error sharing study'),
                subtitle: translate('Something went wrong when sharing the study.'),
                type: 'error',
            });
        },
    });
    const selectUserFormSection = useMemo(() => {
        const eligibleUsersOptions = [...eligibleUsers, ...fetchedUsersWithAccess]
            ?.filter((eligibleUser) => {
            const isUserGivenAccess = usersWithAccess.find((user) => user.identity.id === eligibleUser?.identity?.id);
            return !isUserGivenAccess;
        })
            .sort((a, b) => a.label.localeCompare(b.label));
        const sections = [
            {
                title: translate('Add users'),
                content: (_jsx(Combobox, { dataTestId: 'study-share-add-user-combobox', noOptionsFoundLabel: translate('User(s) not found'), options: eligibleUsersOptions, size: 'full', value: null, disabled: !eligibleUsersOptions.length, onChange: (selectedOption) => {
                        if (selectedOption === null) {
                            return;
                        }
                        setUsersWithAccess((prev) => [...prev, selectedOption]);
                        if (selectedOption.isAlreadyAdded) {
                            setUsersToDelete((prev) => prev.filter((user) => user.identity.id !== selectedOption.identity.id));
                        }
                    } })),
            },
        ];
        return sections;
    }, [eligibleUsers, usersWithAccess]);
    const USERS_COLUMNS = useShareStudyModalColumns(onRemove);
    const usersWithAccessFormSection = useMemo(() => {
        const sortedUsersWithAccess = [...usersWithAccess].sort((a, b) => {
            if (a.isStudyOwner && !b.isStudyOwner) {
                return -1;
            }
            if (!a.isStudyOwner && b.isStudyOwner) {
                return 1;
            }
            if (a.isSameAsLoggedInUser && !b.isSameAsLoggedInUser) {
                return -1;
            }
            if (!a.isSameAsLoggedInUser && b.isSameAsLoggedInUser) {
                return 1;
            }
            return 0;
        });
        const sections = [
            {
                title: translate('Users with access'),
                content: (_jsx(Table, { columns: USERS_COLUMNS, data: sortedUsersWithAccess, hasPagination: true, initialPageSize: 10, labelNoResults: translate('No users'), paginationNavigationScreenReaderLabel: translate('Users table navigation') })),
            },
        ];
        return sections;
    }, [usersWithAccess]);
    useEffect(() => {
        if (fetchedUsersWithAccess.length) {
            setUsersWithAccess(fetchedUsersWithAccess);
        }
    }, [fetchedUsersWithAccess]);
    if (isLoadingStudyConfiguration) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    if (!studyConfiguration) {
        return null;
    }
    return (_jsxs(ModalWindow, { title: translate(`Share ${studyConfiguration.name}`), isOpen: true, closeWindow: handleCloseWindow, footerPrimaryActionButton: {
            label: translate('Save'),
            disabled: !usersWithAccess.filter((user) => !user.isAlreadyAdded).length && !usersToDelete.length,
            onClick: () => handleSave(),
        }, footerSecondaryActionButtons: [{ label: translate('Cancel'), onClick: handleCloseWindow, disabled: false }], width: 'full', children: [(isFetchingEligibleUsers || isFetchingUsersWithAccess || isSavingUsers) && _jsx(Spinner, { wrapper: 'full' }), _jsx(FormSections, { sections: selectUserFormSection }), !!usersWithAccess.length && (_jsx("div", { className: 'mt-8 pt-8 border-t', children: _jsx(FormSections, { sections: usersWithAccessFormSection }) }))] }));
};
