import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { getLocaleMetaInfo } from 'libs.i18n-core';
import { Locale, useLocalizeMessage } from 'libs.nucleus.i18n';
import { RadioGroup, RadioGroupOption } from 'libs.nucleus.radio_group';
import { Table } from 'libs.nucleus.table';
var LOCALE_EXPORT;
(function (LOCALE_EXPORT) {
    LOCALE_EXPORT["BASE"] = "base";
    LOCALE_EXPORT["CUSTOM"] = "custom";
})(LOCALE_EXPORT || (LOCALE_EXPORT = {}));
export const SectionLocale = ({ onChange, locales }) => {
    const t = useLocalizeMessage();
    const [localeExport, setLocaleExport] = useState(LOCALE_EXPORT.BASE);
    const [selectedLocales, setSelectedLocales] = useState([Locale.en_US]);
    const columns = [
        {
            header: t('Code'),
            accessorKey: 'locale',
            cell: (value) => {
                return value.getValue();
            },
        },
        {
            header: t('Language/script'),
            accessorKey: 'language',
        },
        {
            header: t('Country/region'),
            accessorKey: 'region',
        },
    ];
    const data = locales.map((locale) => {
        const { language, region } = getLocaleMetaInfo({ appLocale: Locale.en_US, targetLocale: locale });
        return {
            locale,
            language,
            region,
        };
    });
    const onRowSelectionChange = useCallback((selectedRows) => {
        setSelectedLocales(selectedRows.map((row) => locales[row]));
    }, []);
    useEffect(() => {
        onChange({
            selectedLocales: localeExport === LOCALE_EXPORT.BASE ? [Locale.en_US] : selectedLocales,
        });
    }, [localeExport, selectedLocales]);
    return (_jsxs("div", { className: 'flex flex-col gap-5', children: [_jsxs(RadioGroup, { label: t('Locale selection'), value: localeExport, onChange: setLocaleExport, children: [_jsx(RadioGroupOption, { option: {
                            value: LOCALE_EXPORT.BASE,
                            label: t('Only export base language'),
                        } }), _jsx(RadioGroupOption, { option: {
                            value: LOCALE_EXPORT.CUSTOM,
                            label: t('Select locales to export'),
                        } })] }), localeExport === LOCALE_EXPORT.CUSTOM && (_jsx("div", { className: 'flex flex-col gap-5', children: _jsx(Table, { data: data, columns: columns, labelNoResults: t('No Locales'), useInternalSearch: true, hasPagination: true, initialPageSize: 10, labelSearchField: t('Search'), isSelectable: true, onRowSelectionChange: onRowSelectionChange, paginationNavigationScreenReaderLabel: t('Locale table navigation') }) }))] }));
};
