import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { FORMAT_TEXT_COMMAND, SELECTION_CHANGE_COMMAND, $getSelection, $isRangeSelection, } from 'lexical';
import { useEffect, useState, useCallback } from 'react';
import { Button } from 'libs.nucleus.button';
import { Icon } from 'libs.nucleus.icon';
import { FormatterWrapper } from './formatter_wrapper';
const actions = [
    { type: 'bold', icon: 'formatBold', label: 'Bold' },
    { type: 'italic', icon: 'formatItalics', label: 'Italic' },
    { type: 'underline', icon: 'formatUnderline', label: 'Underline' },
];
const LowPriority = 1;
export const FormatText = () => {
    const [editor] = useLexicalComposerContext();
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
        }
    }, []);
    useEffect(() => {
        return mergeRegister(editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                updateToolbar();
            });
        }), editor.registerCommand(SELECTION_CHANGE_COMMAND, () => {
            updateToolbar();
            return false;
        }, LowPriority));
    }, [editor]);
    const getIsActive = useCallback((type) => {
        if (type === 'bold' && isBold) {
            return true;
        }
        if (type === 'italic' && isItalic) {
            return true;
        }
        if (type === 'underline' && isUnderline) {
            return true;
        }
        return false;
    }, [isBold, isItalic, isUnderline]);
    const onClick = useCallback((type) => (event) => {
        event.preventDefault();
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, type);
    }, [editor]);
    return (_jsx("div", { className: 'flex gap-0.5', children: actions.map((action) => (_jsx(FormatterWrapper, { children: _jsx(Button, { label: action.label, variant: 'transparent', type: 'button', size: 'full', content: _jsx(Icon, { name: action.icon, size: 14, color: 'black' }), onClick: onClick(action.type), isActive: getIsActive(action.type) }) }, action.type))) }));
};
