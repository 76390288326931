import { useMutation } from '@tanstack/react-query';
import { useApiClient } from 'libs.react.contexts';
export const usePdfUpload = () => {
    const { post } = useApiClient("library" /* ApiClientService.LIBRARY */);
    return useMutation({
        mutationKey: ['uploadPdf'],
        mutationFn: async ({ payload, resourceId }) => {
            return post(`/v1/asset/${resourceId}/translations`, payload, {
                headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
            });
        },
    });
};
