export const TRANSLATION_MANAGEMENT_FLAGS = {
    customNotificationFlag: {
        id: 'customNotification',
        value: false,
        description: 'Adds custom notification rows to the translation section of any study',
        owner: 'Translation Team',
        name: 'Custom notification',
        extra: { section: 'Translation' },
    },
};
