import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { LocalizationStatus } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { useDialog } from 'libs.nucleus.modal_dialog';
import { Table } from 'libs.nucleus.table';
import { Text } from 'libs.nucleus.typography';
import { useBooleanState } from 'libs.react.hooks';
import { ParticipantStatusModal } from './study_participant_status_modal.component';
import { useStudyParticipantStatusesColumns } from './study_participant_statuses_columns.hook';
import { StudyConfigurationContext } from '../../../../contexts';
const sortStatuses = (statuses) => {
    return statuses.sort((statusA, statusB) => statusA.value.localeCompare(statusB.value));
};
export const StudyParticipantStatuses = () => {
    const translate = useLocalizeMessage();
    const dialog = useDialog();
    const { activities, assignStudy, currentStudy, studyId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const disableBuildConfig = currentStudy.localizationStatus === LocalizationStatus.READY;
    const [participantStatusToEdit, setParticipantStatusToEdit] = useState();
    const [isCreateStatusModalOpen, openCreateStatusModal, closeCreateStatusModal] = useBooleanState(false);
    const [participantStatuses, setParticipantStatuses] = useState(() => sortStatuses(currentStudy.getStudyMetadata().participantStatuses || []));
    /**
     * Opens the participant status modal on edit mode
     */
    const editParticipantStatus = (participantStatus) => {
        setParticipantStatusToEdit(participantStatus);
        openCreateStatusModal();
    };
    /**
     * Handles the save of the participant status
     * In order to save a new or existing status, it will decide if the status need to be replaced or added
     * it will also clean the list to have only one default status
     * @param participantStatus - The status to be saved
     */
    const handleOnSave = (participantStatus) => {
        const cleanedParticipantStatuses = cleanDefaultStatuses(participantStatus);
        const newParticipantStatuses = participantStatusToEdit
            ? cleanedParticipantStatuses.map((status) => (status.id === participantStatus.id ? participantStatus : status))
            : [...cleanedParticipantStatuses, participantStatus];
        logEvent(participantStatusToEdit ? 'study_status_edited' : 'study_status_added', {
            study_id: currentStudy.id,
            status_name: participantStatus.value,
        });
        setParticipantStatusToEdit(undefined);
        assignParticipantStatuses(newParticipantStatuses);
        closeCreateStatusModal();
    };
    /**
     * Returns a list of status where only one is set as default
     * @param participantStatus - The status taken as reference
     */
    const cleanDefaultStatuses = (participantStatus) => {
        return participantStatus.isDefault
            ? participantStatuses.map((status) => ({ ...status, isDefault: false }))
            : participantStatuses;
    };
    /**
     * Filters the statuses to delete the selected status from the list
     * @param participantStatus - The status to be deleted
     */
    const deleteParticipantStatus = async (participantStatus) => {
        const implicatedActivities = getImplicatedActivities(participantStatus.id);
        if (implicatedActivities.length > 0) {
            await dialog.open({
                title: translate('Status in use'),
                body: (_jsxs("section", { children: [translate('Please update the status on the following activities in your schedule before deleting this status.'), _jsx(Text, { as: 'p', className: 'mt-4', children: implicatedActivities.map((activity) => activity.name).join(', ') })] })),
                actionButtons: [{ label: translate('Okay') }],
            });
        }
        else {
            const confirm = await dialog.open({
                title: translate('Delete status'),
                body: translate('Are you sure you want to delete this status? This action cannot be undone.'),
                actionButtons: [
                    { label: translate('Delete'), isDestructive: true },
                    {
                        label: translate('Cancel'),
                    },
                ],
            });
            if (confirm) {
                const newParticipantStatuses = participantStatuses.filter(({ id }) => id !== participantStatus.id);
                assignParticipantStatuses(newParticipantStatuses);
                logEvent('study_status_removed', { study_id: studyId, status_name: participantStatus.value });
            }
        }
    };
    /**
     * Looks for the activities that are using the status to be deleted
     * @param idToDelete - The id of the status to be deleted
     */
    const getImplicatedActivities = (idToDelete) => {
        return activities.filter((activity) => Object.values(activity.visitInfoMap)
            .map((v) => v.statusId)
            .includes(idToDelete));
    };
    /**
     * Handles the assignation of a new list of statuses,
     * it will ensure the new list is sorted, and that the study information is updated
     * @param statuses - The new list of statuses
     */
    const assignParticipantStatuses = (statuses) => {
        setParticipantStatuses(sortStatuses(statuses));
        currentStudy.updateStudyMetadata({ participantStatuses: statuses });
        assignStudy(currentStudy);
    };
    /**
     * Closes the participant status modal
     */
    const onClose = () => {
        setParticipantStatusToEdit(undefined);
        closeCreateStatusModal();
    };
    const tableColumns = useStudyParticipantStatusesColumns({
        deleteParticipantStatus,
        editParticipantStatus,
        disableEditing: disableBuildConfig,
    });
    return (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx("div", { className: 'flex justify-end', children: _jsx(Button, { label: translate('Create status'), onClick: openCreateStatusModal, variant: 'secondary', disabled: disableBuildConfig }) }), _jsx(Table, { data: participantStatuses, columns: tableColumns, labelNoResults: translate('No participant statuses found') }), isCreateStatusModalOpen && (_jsx(ParticipantStatusModal, { initialParticipantStatus: participantStatusToEdit, onSave: handleOnSave, onClose: onClose }))] }));
};
