import { ParagraphNode, isHTMLElement, } from 'lexical';
import { textClasses } from '../utils';
export class CustomParagraphNode extends ParagraphNode {
    static getType() {
        return 'custom-paragraph';
    }
    static clone(node) {
        return new CustomParagraphNode(node.__key);
    }
    static importJSON(node) {
        return super.importJSON(node);
    }
    createDOM(config) {
        const dom = super.createDOM(config);
        dom.setAttribute('class', textClasses);
        return dom;
    }
    exportJSON() {
        return Object.assign(Object.assign({}, super.exportJSON()), { type: 'custom-paragraph', version: 1 });
    }
    /**
     * Overrides the exportDOM method to remove additional classes, styles, and attributes from a p tag.
     * @override
     */
    exportDOM(editor) {
        const { element } = super.exportDOM(editor);
        if (element && isHTMLElement(element)) {
            element.removeAttribute('class');
            element.removeAttribute('dir');
            element.removeAttribute('style');
        }
        return { element };
    }
}
