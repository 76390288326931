import { TextNode } from 'lexical';
import { textClasses } from '../utils';
export class CustomTextNode extends TextNode {
    static getType() {
        return 'custom_text';
    }
    static importJSON(node) {
        return super.importJSON(node);
    }
    static clone(node) {
        return new CustomTextNode(node.getTextContent(), node.__key);
    }
    createDOM(config) {
        const dom = super.createDOM(config);
        dom.setAttribute('class', textClasses);
        return dom;
    }
    exportJSON() {
        return Object.assign(Object.assign({}, super.exportJSON()), { type: 'custom_text', version: 1 });
    }
    /**
     * Overrides the exportDOM method to remove additional classes, styles, and attributes from text elements.
     * In Lexical text nodes are represented as spans. Probably this should be revisited and those should be represented as text nodes.
     * Example: `<p><span>Text</span></p>` should be `<p>Text</p>`
     * Example: `<span>Text</span>` should be`Text`
     * Example: `<b><strong>Text</strong></b>` should be `<strong>Text</strong>`
     * Example: `<i><em>Text</em></i>` should be `<em>Text</em>`
     * Example: `<u>Text</u>` should be `<u>Text</u>`
     *
     * @override
     */
    exportDOM(editor) {
        const element = super.createDOM(editor._config, editor);
        // This is the only way to properly add support for most clients,
        // even if it's semantically incorrect to have to resort to using
        // <b>, <u>, <s>, <i> elements.
        element.removeAttribute('class');
        return {
            element,
        };
    }
}
