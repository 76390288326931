import { jsx as _jsx } from "react/jsx-runtime";
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { InlineNotification } from 'libs.nucleus.notification';
export const TranslationStartedNotification = () => {
    const t = useLocalizeMessage();
    const { studyId } = useParams();
    return (_jsx("div", { className: 'mb-6', children: _jsx(InlineNotification, { title: t(`Translation started`), subtitle: t(`The Build is in read-only mode to not interfere with translation activities.`), action: _jsx(Link, { LinkComponent: RouterLink, to: `/studies/${studyId}/build/translations`, children: t('Go to Translations') }) }) }));
};
