import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { RadioGroup, RadioGroupOption } from 'libs.nucleus.radio_group';
import { H6 } from 'libs.nucleus.typography';
import { ActivitySelectionCard } from '../activity_selection_card.component';
export const ScheduledRemoteActivityTypeSelection = ({ onSelect, selectedType, }) => {
    const translate = useLocalizeMessage();
    const options = [
        {
            dataTestId: 'single-response-activity-button',
            description: translate('Allows a single response from participants (For example, a participant training diary)'),
            title: translate('One time activity'),
            type: 'once',
        },
        {
            dataTestId: 'ad-hoc-button',
            description: translate('Allows unlimited responses from participants. (For example, an adverse event)'),
            title: translate('Always available activity'),
            type: 'adhoc',
        },
        {
            dataTestId: 'repeating-activity-button',
            description: translate('Allows a single response to each instance from participants. (For example, a daily diary)'),
            title: translate('Recurring activity'),
            type: 'recurring',
        },
    ];
    return (_jsxs("section", { className: 'flex flex-col items-center justify-center w-full h-full gap-8', children: [_jsx("div", { className: 'w-1/2', children: _jsx(H6, { className: 'text-text-primary font-semibold', children: translate('Select response type') }) }), options.map((option) => (_jsx(ActivitySelectionCard, { selected: selectedType === option.type, onClick: () => onSelect(option.type), children: _jsx(RadioGroup, { onChange: onSelect, value: selectedType, children: _jsx(RadioGroupOption, { option: {
                            label: option.title,
                            description: option.description,
                            value: option.type,
                        } }) }) }, option.type)))] }));
};
