import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { LocalizationStatus as StudyLocalizationStatus } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { AssetTypes, LocalizationStatus } from 'libs.localization.common';
import { useLocalizationCollection } from 'libs.localization.react';
import { Locale, useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { InlineNotification } from 'libs.nucleus.notification';
import { AssetsLocaleList, DashboardExportAction, DashboardImportAction } from '../../components';
import { MatrixFilter } from '../../components/locale_matrix/filter.component';
import { TranslationWorkbenchContext } from '../../contexts';
import { AssetRole, StudyAssetType } from '../../types';
import { toSentenceCase } from '../../utils/string_utils';
export const StudyTranslationsDashboard = () => {
    const t = useLocalizeMessage();
    const { studyId } = useParams();
    const { logEvent } = useContext(FirebaseAppContext);
    const { locales: allLocales, isStudyEnglishOnly, libraryAssets, resourcesAssets, studyAssets, notificationsAssets, studyLocalizationStatus, siteLocales, participantLocales, study, } = useContext(TranslationWorkbenchContext);
    let showNoLocaleNotification = false;
    const locales = isStudyEnglishOnly ? [Locale.en_US] : allLocales;
    if (!isStudyEnglishOnly && locales.length === 1 && locales[0] === Locale.en_US) {
        showNoLocaleNotification = true;
    }
    const filterData = useMemo(() => [
        {
            label: t('Type'),
            accessorKey: 'type',
            disabled: false,
            size: 'w-[14rem]',
            multiple: true,
            options: Object.values(StudyAssetType)
                .filter((type) => type !== StudyAssetType.SITE_PARTICIPANT_RESOURCES)
                .map((type) => ({ label: toSentenceCase(type), value: type })),
        },
        {
            label: t('Locale(s)'),
            accessorKey: 'locale',
            size: 'w-[11rem]',
            options: locales
                .map((locale) => ({ label: locale, value: locale }))
                .filter((locale) => locale.value !== 'en-US'),
            multiple: true,
            disabled: locales.length === 1 && locales[0] === Locale.en_US,
        },
        {
            label: t('Status(es)'),
            accessorKey: 'status',
            size: 'w-[11rem]',
            disabled: false,
            options: Object.values(LocalizationStatus).map((status) => ({ label: toSentenceCase(status), value: status })),
            multiple: true,
        },
    ], [locales]);
    const initialFilters = filterData.reduce((acc, filter) => ({
        ...acc,
        [filter.accessorKey]: filter.multiple ? [] : 'all',
    }), {});
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFilters, setSelectedFilters] = useState(initialFilters);
    const allDefaultAssets = useMemo(() => ({ ...libraryAssets, ...studyAssets, ...resourcesAssets, ...notificationsAssets }), [libraryAssets, studyAssets, resourcesAssets]);
    const assetsLocale = useMemo(() => Object.keys(allDefaultAssets)
        .map((asset) => allDefaultAssets[asset].asset)
        .filter(Boolean), [allDefaultAssets]);
    const { localizationsMap, isLoading, snapshotTime } = useLocalizationCollection(assetsLocale);
    const assetsWithUpdatedStatuses = useMemo(() => {
        if (!localizationsMap || isLoading) {
            return {};
        }
        const updatedAssets = { ...allDefaultAssets }; // shallow copy deliberately
        const isLocaleBelongsToCell = (locale, role) => {
            if (role === AssetRole.PARTICIPANT) {
                return participantLocales.includes(locale);
            }
            if (role === AssetRole.SITE) {
                return siteLocales.includes(locale);
            }
            return true; // for both roles
        };
        Object.keys(updatedAssets).forEach((key) => {
            const assetData = updatedAssets[key];
            const { asset } = assetData;
            if (!asset) {
                return;
            }
            const { localizationEntry } = localizationsMap.get(asset); // this works because asset is passed as reference
            const { supported_locales } = localizationEntry || {};
            const statuses = {};
            locales.forEach((locale) => {
                if (!isLocaleBelongsToCell(locale, assetData.role)) {
                    statuses[locale] = LocalizationStatus.NOT_AVAILABLE;
                }
                else if (supported_locales?.[locale] === undefined) {
                    statuses[locale] = LocalizationStatus.NOT_STARTED;
                }
                else {
                    statuses[locale] = supported_locales[locale].status;
                }
            });
            assetData.statuses = statuses;
        });
        return updatedAssets;
    }, [allDefaultAssets, localizationsMap, isLoading, snapshotTime]);
    const filteredAssets = useMemo(() => {
        const temp = { ...assetsWithUpdatedStatuses };
        const filteredAssetsColumns = Object.entries(temp).map(([assetName, assetData]) => {
            let filteredStatuses = {};
            if (selectedFilters.locale?.length) {
                for (const locale of selectedFilters.locale) {
                    if (assetData.statuses[locale]) {
                        filteredStatuses[locale] = assetData.statuses[locale];
                    }
                }
            }
            else {
                const currentStatuses = assetData.statuses;
                delete currentStatuses['en-US'];
                filteredStatuses = currentStatuses;
            }
            return [assetName, { ...assetData, statuses: filteredStatuses }];
        });
        const filteredAssetsRows = filteredAssetsColumns.filter(([assetName, assetData]) => {
            return (Object.keys(selectedFilters).every((filterKey) => {
                const filterValue = selectedFilters[filterKey];
                if (filterValue === 'all') {
                    return true;
                }
                switch (filterKey) {
                    case 'type':
                        if ((filterValue.includes(StudyAssetType.PARTICIPANT_RESOURCES) &&
                            assetData.type === StudyAssetType.SITE_PARTICIPANT_RESOURCES) ||
                            (filterValue.includes(StudyAssetType.SITE_RESOURCES) &&
                                assetData.type === StudyAssetType.SITE_PARTICIPANT_RESOURCES)) {
                            return true;
                        }
                        return filterValue?.length ? filterValue.includes(assetData?.type || '') : true;
                    case 'status':
                        return filterValue?.length
                            ? Object.values(assetData.statuses).some((status) => filterValue.includes(status))
                            : true;
                    default:
                        return true;
                }
            }) && assetName.toLowerCase().includes(searchTerm.toLowerCase()));
        });
        return Object.fromEntries([...filteredAssetsRows]);
    }, [searchTerm, selectedFilters, assetsWithUpdatedStatuses]);
    const resourcesForExport = useMemo(() => {
        return Object.keys(filteredAssets).reduce((acc, key) => {
            const asset = filteredAssets[key];
            if (asset.assetType !== AssetTypes.RESOURCES_PDF) {
                acc[key] = asset;
            }
            return acc;
        }, {});
    }, [filteredAssets]);
    const handleSearch = useCallback((e) => {
        setSearchTerm(e.target.value);
    }, [setSearchTerm]);
    const handleFilter = useCallback((key, value) => {
        setSelectedFilters((prev) => ({ ...prev, [key]: value }));
        logEvent('translations_dashboard_filter', {
            studyId: study.id,
            filter: key,
            filterValue: Array.isArray(value) ? value.join(',') : value,
        });
    }, [setSelectedFilters]);
    const isStudyinDraft = studyLocalizationStatus === StudyLocalizationStatus.DRAFT;
    const isActionDisabled = studyLocalizationStatus !== StudyLocalizationStatus.READY;
    if (!studyId) {
        // No Study ID
        return null;
    }
    return (_jsxs("div", { className: 'flex flex-col gap-6', children: [showNoLocaleNotification && (_jsx(InlineNotification, { title: t('No locales configured'), subtitle: t('Please set required locales.'), action: _jsx(Link, { LinkComponent: RouterLink, to: `/studies/${studyId}/build#locales`, children: t('Go to locales settings') }) })), isStudyEnglishOnly && (_jsx(InlineNotification, { title: t(`Study is ${Locale.en_US} only`), subtitle: t(`Study was set as ${Locale.en_US} only in Build settings, so it doesn’t require any translation.`), action: _jsx(Link, { LinkComponent: RouterLink, to: `/studies/${studyId}/build#locales`, children: t('Go to locales settings') }) })), isStudyinDraft && (_jsx(InlineNotification, { title: t('Status changed to Draft'), subtitle: t('Base locale strings may change, which may invalidate current and new translations.'), type: 'warning' })), _jsxs("div", { className: 'flex justify-between gap-4', children: [_jsx("div", { className: 'flex gap-4 items-end flex-wrap', children: _jsx(MatrixFilter, { filters: filterData, searchTerm: searchTerm, selectedFilters: selectedFilters, handleFilter: handleFilter, handleSearch: handleSearch }) }), _jsxs("div", { className: 'flex gap-4 items-end', children: [_jsx(DashboardImportAction, { isDisabled: isActionDisabled, supportedLocales: locales, showTooltip: isActionDisabled, tooltipText: t('This action is disabled until the study configuration is completed and translation status is set to ready'), trackingContext: { context: 'study', id: study.id } }), _jsx(DashboardExportAction, { assets: resourcesForExport, locales: locales, isDisabled: isActionDisabled, showTooltip: isActionDisabled, tooltipText: t('This action is disabled until the study configuration is completed and translation status is set to ready'), trackingContext: { context: 'study', id: study.id } })] })] }), _jsx(AssetsLocaleList, { data: filteredAssets, isLoading: isLoading })] }));
};
