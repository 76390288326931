import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { createCommand, COMMAND_PRIORITY_EDITOR, $insertNodes, $getNodeByKey } from 'lexical';
import { useEffect, useRef } from 'react';
import { useImagesApi } from '../contexts/images_context';
import { $createImageNode, ImageNode, $isImageNode } from '../nodes/image';
export const INSERT_IMAGE_COMMAND = createCommand('UPLOAD_IMAGE_COMMAND');
const IMAGE_KEY = 'imageUrl'; // If we need to support multiple images this can become dynamic e.g. image_1, image_2, image_n
export const ImagesPlugin = () => {
    const [editor] = useLexicalComposerContext();
    const { addImages, removeImage } = useImagesApi();
    const imageNodeCache = useRef(new Map());
    useEffect(() => {
        if (!editor.hasNodes([ImageNode])) {
            throw new Error('ImagesPlugin: ImageNode has not been registered on editor');
        }
        const unregisterCommand = editor.registerCommand(INSERT_IMAGE_COMMAND, (payload) => {
            const imageNode = $createImageNode(Object.assign(Object.assign({}, payload), { imageKey: IMAGE_KEY }));
            $insertNodes([imageNode]);
            addImages({ [IMAGE_KEY]: payload.src });
            return true;
        }, COMMAND_PRIORITY_EDITOR);
        return unregisterCommand;
    }, [editor]);
    useEffect(() => {
        const cache = imageNodeCache.current;
        const unregisterListener = editor.registerMutationListener(ImageNode, (mutatedNodes) => {
            for (const [nodeKey, mutation] of mutatedNodes) {
                editor.getEditorState().read(() => {
                    if (mutation === 'created') {
                        const createdNode = $getNodeByKey(nodeKey);
                        if ($isImageNode(createdNode)) {
                            cache.set(nodeKey, createdNode.getImageKey());
                        }
                    }
                    if (mutation === 'destroyed') {
                        const destroyedNode = cache.get(nodeKey);
                        if (destroyedNode) {
                            removeImage(destroyedNode);
                            cache.delete(nodeKey);
                        }
                    }
                });
            }
        });
        return () => unregisterListener();
    }, [editor]);
    return null;
};
