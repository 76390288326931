import { useLocation } from 'react-router-dom';
/**
 * Hook to check whether this asset is being used under library or studies
 */
export function useAssetContext() {
    const { pathname } = useLocation();
    const isLibrary = pathname.startsWith('/ecoa/');
    const isStudy = pathname.startsWith('/studies/');
    const getReviewUrl = () => {
        return isStudy ? 'review' : 'translation';
    };
    return { isLibrary, isStudy, getReviewUrl };
}
