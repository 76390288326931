import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodes } from 'lexical';
import { useEffect } from 'react';
/**
 * This hook is used to deserialise the initial value of the editor.
 */
export const useLexicalDeserializer = (value) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!editor) {
            console.error('Editor instance is undefined');
            return;
        }
        editor.update(() => {
            const parser = new DOMParser();
            let content = '';
            if (typeof value === 'string') {
                content = value;
            }
            else if (typeof value === 'object' && typeof (value === null || value === void 0 ? void 0 : value.content) === 'string') {
                // eslint-disable-next-line prefer-destructuring
                content = value === null || value === void 0 ? void 0 : value.content;
            }
            const dom = parser.parseFromString(content, 'text/html');
            const imgs = dom.querySelectorAll('img');
            imgs.forEach((img) => {
                const src = img.getAttribute('src');
                const pattern = /\{([^}]+)\}/;
                const match = src === null || src === void 0 ? void 0 : src.match(pattern);
                const imageKey = match && match[1] && match[1];
                if (imageKey && (value === null || value === void 0 ? void 0 : value.images)) {
                    img.dataset.imageKey = imageKey;
                    img.setAttribute('src', value.images[imageKey]);
                }
            });
            const nodes = $generateNodesFromDOM(editor, dom);
            if (nodes && nodes.length > 0) {
                $insertNodes(nodes);
            }
        });
    }, []);
};
