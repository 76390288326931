import { AssetTypes } from 'libs.localization.common';
export const getSignInStudyNameAsset = (study) => {
    const { isNoPIIEnabled } = study.getStudyMetadata();
    const assetType = isNoPIIEnabled ? AssetTypes.SIGN_IN_STUDY_NAME_PII : AssetTypes.SIGN_IN_STUDY_NAME;
    return {
        type: assetType,
        id: study.id,
        version: '1',
        messages: [
            {
                namespace: 'study_name',
                messages: { value: study.displayName || study.name },
            },
        ],
        preview: {
            engine: 'react',
            id: assetType,
            default_viewport: 'mobile',
            info: {
                display_name: 'Study Name',
                list: [],
            },
        },
    };
};
