import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import { useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown } from 'libs.nucleus.dropdown';
import { Icon } from 'libs.nucleus.icon';
import { Text } from 'libs.nucleus.typography';
import { EnhancedMatrixRow } from './enhanced_matrix_row.component';
const DEFAULT_CELL_WIDTH = 160;
export const EnhancedMatrix = (props) => {
    const { headers, sections, minColumnsDisplayed, stickyColumn = false } = props;
    const headerRef = useRef(null);
    const [headerContainer, setHeaderContainer] = useState();
    const [isExpanded, setIsExpanded] = useState(() => {
        return sections.reduce((acc, section) => {
            acc[section.header] = true;
            return acc;
        }, {});
    });
    const [maxHeight, setMaxHeight] = useState('9999px');
    const [overflowVisible, setOverflowVisible] = useState({ ...isExpanded });
    useEffect(() => {
        if (headerRef.current) {
            setMaxHeight(`${headerRef.current.scrollHeight}px`);
        }
    }, []);
    useEffect(() => {
        setHeaderContainer(headerRef.current);
    }, []);
    const internalHeaders = useMemo(() => {
        const sortedHeaders = [...headers];
        sortedHeaders.sort((a, b) => {
            if (a.order === undefined || b.order === undefined) {
                return 0;
            }
            return a.order - b.order;
        });
        if (minColumnsDisplayed && minColumnsDisplayed > headers.length) {
            const emptyHeaders = Array.from({ length: minColumnsDisplayed - headers.length - 1 }, (_, i) => ({
                key: `${i}`,
                content: '',
            }));
            return [...sortedHeaders, ...emptyHeaders];
        }
        return sortedHeaders;
    }, [headers]);
    /**
     * In order to set the correct width for the table, we need to calculate the width of each column.
     * This is necessary to avoid the table to fix its own size to the size of its parent container.
     */
    const dynamicRowWidth = useMemo(() => {
        const willSeparate = internalHeaders.some((header) => header.order !== undefined && header.order > 0);
        return internalHeaders.reduce((acc, header) => {
            return acc + (header.width || DEFAULT_CELL_WIDTH);
        }, willSeparate ? 20 : 0);
    }, [internalHeaders]);
    const getCellStyles = (index, rowIndex, isHeader = false) => {
        const hasSeparator = index > 0 &&
            internalHeaders[index].order !== undefined &&
            internalHeaders[index].order !== 0 &&
            internalHeaders[index - 1].order === 0;
        let styles = `relative flex w-40 border-b border-r last:border-r-0 font-sans text-xs font-medium ${hasSeparator ? "border-l ml-5 before:content-[''] before:absolute before:bg-gray-50 before:-left-[1.3125rem] before:-top-px before:w-5 before:h-[calc(100%+2px)] before:-mb-px" : ''} ${rowIndex === 0 ? 'border-t' : ''}`;
        if (isHeader) {
            styles += ' border-t justify-between pl-4 pr-1 items-center bg-neutral-75';
        }
        else {
            styles += ' bg-white';
        }
        if (index === 0 && stickyColumn) {
            styles += ' sticky left-0 z-[1]';
        }
        return styles;
    };
    const handleSectionClick = (section) => {
        const isSectionExpanded = !isExpanded[section];
        setIsExpanded({ ...isExpanded, [section]: isSectionExpanded });
        if (isSectionExpanded) {
            setTimeout(() => {
                setOverflowVisible({ ...overflowVisible, [section]: true });
            }, 300);
        }
        else {
            setOverflowVisible({ ...overflowVisible, [section]: false });
        }
    };
    return (_jsxs("section", { className: 'overflow-x-auto bg-white text-text-primary pb-3', "data-testid": 'enhanced-matrix', ref: headerRef, children: [_jsx(EnhancedMatrixRow, { dataTestId: 'enhanced-matrix-header-row', width: dynamicRowWidth, children: internalHeaders.map((header, index) => (_jsxs("div", { className: `group ${getCellStyles(index, 0, true)}`, style: { width: header.width }, children: [_jsxs("span", { className: 'truncate', "data-testid": typeof header.content === 'string' && header.content
                                ? 'enhanced-matrix-header-title'
                                : 'enhanced-matrix-header-empty-title', title: typeof header.content === 'string' ? header.content : '', children: [header.content, header.subtitle && (_jsx(Text, { size: 'xxs', className: 'text-neutral-600', children: header.subtitle }))] }), header.options && (_jsx("div", { className: 'opacity-0 group-hover:opacity-100 group-focus:opacity-100 group-focus-within:opacity-100 has-[button[aria-expanded="true"]]:opacity-100', children: _jsx(Dropdown, { items: header.options, variant: 'kebab', portalContainer: headerContainer }) }))] }, `header-cell-${header.key}`))) }), sections.map((section) => (_jsxs("div", { className: `${!isExpanded[section.header] ? 'border-b' : ''}`, style: { width: dynamicRowWidth }, "data-testid": section.dataTestId, children: [!section.isStatic && (_jsxs("div", { className: 'flex px-4 py-1.5 items-center gap-2', children: [_jsx("div", { className: 'sticky left-4 z-[1] text-neutral-450 cursor-pointer', onClick: () => handleSectionClick(section.header), children: _jsx(Icon, { name: isExpanded[section.header] ? 'chevronDown' : 'chevronRight', color: 'current' }) }), _jsx(Text, { className: 'text-secondary font-medium sticky left-12 z-[1]', size: 'xs', children: section.header })] })), _jsxs("div", { style: { maxHeight: isExpanded[section.header] ? maxHeight : '0' }, className: `transition-[max-height] duration-300 ease-in-out ${overflowVisible[section.header] ? 'overflow-visible' : 'overflow-hidden'}`, children: [section.items.map((item, index) => (_jsx(section.RowComponent, { dataTestId: `enhanced-matrix-row-${index}`, getCellStyles: (cellIndex) => getCellStyles(cellIndex, index), headers: internalHeaders, item: item, rowIndex: index, width: dynamicRowWidth, defaultCellWidth: DEFAULT_CELL_WIDTH }, `item-row-${index}`))), section.minRowsDisplayed &&
                                Array.from({ length: section.minRowsDisplayed - section.items.length }).map((_, rowIndex) => (_jsx(EnhancedMatrixRow, { dataTestId: `enhanced-matrix-empty-row-${rowIndex}`, rowIndex: rowIndex + section.items.length, width: dynamicRowWidth, children: internalHeaders.map((header, index) => (_jsx("div", { className: getCellStyles(index, !section.items.length ? rowIndex : -1), style: { width: header.width } }, `empty-row-${rowIndex}-cell-${index}`))) }, `empty-row-${rowIndex}`)))] })] }, section.header)))] }));
};
