import { AssetTypes, LocalizationStatus } from 'libs.localization.common';
import { Locale } from 'libs.nucleus.i18n';
import { AssetRole, StudyAssetType } from '../types';
import { toSnakeCase } from '../utils/string_utils';
const mockTemplates = [
    {
        id: 'a9cf4ac4-fd8d-4cf6-9922-0ced4fb726cc',
        html: '',
        name: 'My Push Template',
        type: 'push',
        plain: 'Please open the app and enter your weight. Thank you for your participation.',
        subject: 'Check your weight',
        description: '',
    },
    {
        id: 'a9cf4ac4-fd8d-4cf6-9922-0ced4fb726cb',
        html: '',
        name: 'My SMS Template',
        type: 'sms',
        plain: 'Thank you for your participation. Please open the app and enter your weight',
        subject: '',
        description: '',
    },
    {
        id: 'a9cf4ac4-fd8d-4cf6-9922-0ced4fb726ca',
        html: 'Thank you for your participation. <v>Your weight has been recorded.</b> Please open the app for more information.',
        name: 'My Email Template',
        type: 'email',
        plain: '',
        subject: 'Thank you for your participation',
        description: '',
    },
];
function getType(type) {
    switch (type) {
        case 'push':
            return AssetTypes.NOTIFICATION_PUSH_NOTIFICATION;
        case 'sms':
            return AssetTypes.NOTIFICATION_SMS;
        case 'email':
            return AssetTypes.NOTIFICATION_EMAIL;
        default:
            return AssetTypes.NOTIFICATION_PUSH_NOTIFICATION;
    }
}
export const useNotificationsData = (study, locales) => {
    const data = {};
    mockTemplates.forEach((template) => {
        const localeData = {};
        for (const locale of locales) {
            localeData[locale] = locale === Locale.en_US ? LocalizationStatus.READY : LocalizationStatus.NOT_STARTED;
        }
        data[template.name] = {
            id: template.id,
            type: StudyAssetType.NOTIFICATIONS,
            path: template.id,
            statuses: localeData,
            role: AssetRole.PARTICIPANT,
            assetType: getType(template.type),
            asset: {
                type: getType(template.type),
                id: template.id,
                version: '1',
                human_id: toSnakeCase(template.name),
                messages: [
                    {
                        namespace: template.id,
                        messages: ['plain', 'subject', 'html'].reduce((acc, key) => {
                            if (template[key]) {
                                acc[key] = template[key];
                            }
                            return acc;
                        }, {}),
                    },
                ],
                preview: {
                    engine: 'react',
                    id: getType(template.type),
                    default_viewport: 'iosSmall',
                    info: {
                        display_name: template.name,
                        list: [],
                    },
                },
            },
        };
    });
    return data;
};
