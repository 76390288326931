import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { H2 } from 'libs.nucleus.typography';
import { AuthWrapper } from '../../components';
import { AuthContext, AuthRoute } from '../../contexts';
/**
 * The reset password page is accessed when the user receives an email with a token to reset the password
 * the token can only be used once, and it might expire if not used in time
 */
export const ResetPasswordPage = () => {
    const translate = useLocalizeMessage();
    const { resetPassword, error, setError } = useContext(AuthContext);
    const navigate = useNavigate();
    const { token } = useParams();
    const [passwordError, setPasswordError] = useState();
    const [password, setPassword] = useState('');
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
    const isSubmitDisabled = !password || !confirmPassword || password !== confirmPassword;
    useEffect(() => {
        validatePasswords();
    }, [password, passwordTouched, confirmPassword, confirmPasswordTouched]);
    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const onConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };
    const onPasswordBlur = () => {
        setPasswordTouched(true);
    };
    const onNewPasswordBlur = () => {
        setConfirmPasswordTouched(true);
    };
    const validatePasswords = () => {
        const hasError = passwordTouched && confirmPasswordTouched && password !== confirmPassword;
        setPasswordError(hasError ? translate('Passwords need to match') : undefined);
    };
    const onSubmit = async () => {
        if (!isSubmitDisabled && token) {
            await resetPassword(password, token);
            navigate(AuthRoute.LOGIN);
        }
    };
    const onGoBack = () => {
        setError(undefined);
        navigate(AuthRoute.LOGIN);
    };
    return (_jsxs(AuthWrapper, { onSubmit: onSubmit, error: error, children: [_jsxs("div", { className: 'flex flex-col gap-4', children: [_jsx(H2, { children: translate('Reset password') }), _jsx("span", { children: translate('If there is an account with that address, you will receive an email to reset the password.') }), _jsx(Input, { errorMessage: passwordError, hasError: !!passwordError, id: 'password', label: translate('New password'), onBlur: onPasswordBlur, onChange: onPasswordChange, required: true, type: 'password', value: password, width: 'full' }), _jsx(Input, { id: 'confirmPassword', label: translate('Confirm password'), onChange: onConfirmPasswordChange, onBlur: onNewPasswordBlur, required: true, type: 'password', value: confirmPassword, width: 'full' })] }), _jsxs("div", { className: 'flex flex-col gap-4 pt-8', children: [_jsx(Button, { label: translate('Submit'), disabled: isSubmitDisabled }), _jsx(Button, { label: translate('Go back'), variant: 'transparent', onClick: onGoBack })] })] }));
};
