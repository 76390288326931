import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
export const useShareStudyModalColumns = (onRemove) => {
    const translate = useLocalizeMessage();
    return useMemo(() => [
        { header: translate('Name'), accessorKey: 'builtName' },
        { header: translate('Email'), accessorKey: 'identity.email' },
        {
            header: translate('Role'),
            accessorFn: (option) => {
                if (option.isStudyOwner) {
                    return translate('Owner');
                }
                return option.identity.resourceAccess.roleGroups
                    .map((roleGroup) => roleGroup.displayName)
                    .join(', ');
            },
        },
        {
            id: 'remove',
            cell: ({ row }) => {
                if (!row.original.isStudyOwner && !row.original.isSameAsLoggedInUser) {
                    return (_jsx(Button, { label: translate('Remove'), size: 'xs', variant: 'transparent', onClick: () => onRemove(row.original) }));
                }
            },
        },
    ], []);
};
