import { jsx as _jsx } from "react/jsx-runtime";
import { QueryClientContext, useQuery } from '@tanstack/react-query';
import { createContext, useCallback, useEffect, useState, useContext, useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { SDKStudy, Error as SDKError, ActivityType } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { useDialog } from 'libs.nucleus.modal_dialog';
import { AuthContext } from 'libs.react.auth';
import { useApiClient } from 'libs.react.contexts';
import { useToastNotification } from 'libs.react.hooks';
import { LibraryResourceStatus, LibraryEndpoint, } from 'libs.react.types';
import { sanitizeStudy } from '../../utils/study';
import { StudiesContext } from '../studies';
const mockedStudy = new SDKStudy({ name: '' });
const sortActivities = (study, timelineId) => {
    return study
        .mapActivities(study.visitActivities[timelineId])
        .sort((activityA, activityB) => (activityA.details.order || 0) - (activityB.details.order || 0));
};
const getActivityLibraryIds = (study) => {
    return study.getAllActivities().reduce((acc, activity) => {
        return activity.details.libraryId ? [...acc, activity.details.libraryId] : acc;
    }, []);
};
const missingProvider = () => {
    throw Error('Missing the study configuration provider');
};
export const StudyConfigurationContext = createContext({
    activities: [],
    activityScreens: {},
    assignStudy: missingProvider,
    cloneStudy: missingProvider,
    configId: '',
    createNewStudy: missingProvider,
    currentStudy: mockedStudy,
    getStudy: missingProvider,
    getStudyConfiguration: missingProvider,
    getVisitById: missingProvider,
    isActivityOnVisit: missingProvider,
    hasDependentNotifications: missingProvider,
    hasDependentWorkflows: missingProvider,
    isInitialized: false,
    isLoading: true,
    isValidVisit: missingProvider,
    onDeleteActivityClick: missingProvider,
    setOnboarding: missingProvider,
    scheduledActivities: [],
    studyId: '',
    toggleActivityOnVisit: missingProvider,
    visits: [],
    currentTimelineId: '',
    changeCurrentTimelineId: missingProvider,
});
export const StudyConfigurationProvider = ({ children }) => {
    const { createStudy, updateStudy } = useContext(StudiesContext);
    const { entityId } = useContext(AuthContext);
    const queryClient = useContext(QueryClientContext);
    const libraryClient = useApiClient("library" /* ApiClientService.LIBRARY */);
    const { logEvent } = useContext(FirebaseAppContext);
    const dialog = useDialog();
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const match = useMatch('/studies/:studyId/:section/*');
    const [activities, setActivities] = useState([]);
    const [currentStudy, setCurrentStudy] = useState(mockedStudy);
    const [visits, setVisits] = useState([]);
    const [scheduledActivities, setScheduledActivities] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false);
    const [studyId, setStudyId] = useState('');
    const [configId, setConfigId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentTimelineId, setCurrentTimelineId] = useState(currentStudy.timelineInfo.main.id);
    const [persistedTimelineId, setPersistedTimelineId] = useState(currentStudy.timelineInfo.main.id);
    useEffect(() => {
        if (match && match.params.studyId && match.params.section && match.params.studyId !== studyId) {
            setStudyId(match.params.studyId);
            getStudy(match.params.studyId);
        }
    }, [match?.params]);
    const getStudy = async (id = studyId) => {
        setIsLoading(true);
        const studyConfiguration = await getStudyConfiguration(id);
        if (studyConfiguration) {
            try {
                setConfigId(studyConfiguration.id);
                const study = sanitizeStudy(studyConfiguration.data);
                assignStudy(study, false);
                setCurrentTimelineId(study.timelineInfo.main.id);
            }
            catch (error) {
                addNotification({
                    title: translate('Error parsing the study'),
                    subtitle: error instanceof SDKError && error.message
                        ? error.message
                        : translate('Something went wrong when parsing the study.'),
                    type: 'error',
                });
            }
        }
        setIsLoading(false);
        setIsInitialized(true);
    };
    /**
     * Fetches all activities in use in the current study to have the screens available
     */
    const getActivityScreens = async () => {
        const activityIds = getActivityLibraryIds(currentStudy);
        const { data: response } = await libraryClient.get(LibraryEndpoint.GET_ACTIVITY_RESOURCES, { params: { entityId, limit: 200, id: activityIds, status: LibraryResourceStatus.PUBLISHED } });
        return response.data?.reduce((acc, activity) => {
            acc[activity.id] = activity.data?.screens ?? [];
            return acc;
        }, {});
    };
    /**
     * Verifies if the stored activity screens are sufficient for the activities included in the current study
     */
    const validateActivityScreens = async (study) => {
        const activityIds = getActivityLibraryIds(study);
        const missingScreens = activityIds.filter((id) => !activityScreens[id]);
        if (queryClient && missingScreens.length > 0) {
            await queryClient.invalidateQueries({ queryKey: ['activity', 'screens'] });
        }
    };
    const { data: activityScreens = {} } = useQuery({
        queryKey: ['activity', 'screens'],
        queryFn: getActivityScreens,
        enabled: !!currentStudy.name,
    });
    const getStudyConfiguration = async (id) => {
        setIsLoading(true);
        const searchParams = new URLSearchParams({ studyId: id, status: LibraryResourceStatus.DRAFT });
        const { data: response } = await libraryClient.get(`${LibraryEndpoint.GET_STUDY_CONFIGS}?${searchParams.toString()}`);
        setIsLoading(false);
        return response.data && response.data.length > 0 ? response.data[0] : undefined;
    };
    const createNewStudy = async (study) => {
        setIsLoading(true);
        try {
            const newStudyId = await createStudy(study);
            assignStudy(study, false);
            return newStudyId;
        }
        finally {
            setIsLoading(false);
        }
    };
    const changeCurrentTimelineId = useCallback((timelineId) => {
        if (timelineId !== currentTimelineId) {
            setCurrentTimelineId(timelineId);
            setActivities(sortActivities(currentStudy, timelineId));
            const studyVisitSchedule = currentStudy.visitSchedule[timelineId];
            setVisits(studyVisitSchedule.map((visitId) => currentStudy.visits[timelineId][visitId]));
            setScheduledActivities(currentStudy.scheduledActivities[timelineId]);
        }
    }, [currentStudy, currentTimelineId]);
    /**
     * It creates a new study cloning all the information available on the source Study
     * we modify the rosetta json directly (ideally we should have a util directly on the SDK for this)
     * additionally, we remove the id to force the creation of a new study
     *
     * @param studyToClone - The study to be cloned
     * @param newStudyDetails - The edited details to be assigned to the new study
     */
    const cloneStudy = (studyToClone, newStudyDetails) => {
        const newStudy = SDKStudy.fromRosetta({ ...studyToClone.toRosetta(), id: undefined });
        if (newStudyDetails) {
            newStudy.name = newStudyDetails.name;
            newStudy.description = newStudyDetails.description;
            newStudy.protocolNumber = newStudyDetails.protocolNumber;
            newStudy.displayName = newStudyDetails.displayName;
        }
        return newStudy;
    };
    /**
     * Updates the information of the current Study with the new changes made
     */
    const assignStudy = (newStudy, updateLibraryConfig = true) => {
        setCurrentStudy(newStudy);
        setActivities(sortActivities(newStudy, currentTimelineId));
        const studyVisitSchedule = newStudy.visitSchedule[currentTimelineId];
        setVisits(studyVisitSchedule.map((visitId) => newStudy.visits[currentTimelineId][visitId]));
        setScheduledActivities(newStudy.scheduledActivities[currentTimelineId]);
        if (updateLibraryConfig) {
            updateStudy(configId, newStudy);
        }
        validateActivityScreens(newStudy);
    };
    /**
     * Checks if the given activity is part of the given visit schedule
     * @param activity - Activity
     * @param visitId - string
     */
    const isActivityOnVisit = (activity, visitId) => {
        const visit = getVisitById(visitId);
        return !!visit && !!visit.activities[activity.id];
    };
    /**
     * Used to persist the previous value of the schedule timeline id (if there is any)
     * so that we can provide a better UX when the user navigates through the Onboarding and Schedule tabs
     * @param isOnboardingEnabled
     */
    const setOnboarding = (isOnboardingEnabled) => {
        if (isOnboardingEnabled) {
            changeCurrentTimelineId(currentStudy.timelineInfo.onboarding.id);
            if (currentTimelineId !== currentStudy.timelineInfo.onboarding.id) {
                setPersistedTimelineId(currentTimelineId);
            }
        }
        else {
            changeCurrentTimelineId(persistedTimelineId);
        }
    };
    /**
     * Given the id of a visit, it will return the full visit object
     * @param id
     */
    const getVisitById = (id) => {
        return Object.values(currentStudy.getAllVisits()).find((visit) => visit.id === id);
    };
    /**
     * Given an id, it will validate if there is a visit defined for that id
     */
    const isValidVisit = (id) => {
        return visits.some((visit) => visit.id === id);
    };
    /**
     * Removes the selected activity from the array
     * @param activity
     */
    const removeActivity = (activity) => {
        currentStudy.removeVisitActivity({ timelineId: currentTimelineId, activity });
        addNotification({
            title: translate('Activity Removed'),
            subtitle: translate(`The activity "${activity.name}" has been removed from the timeline.`),
            type: 'success',
        });
        assignStudy(currentStudy);
    };
    const onDeleteActivityClick = async (activity) => {
        const ok = await dialog.open({
            title: translate('Delete Activity'),
            body: translate('Are you sure you want to delete this activity? This action cannot be undone.'),
            actionButtons: [{ isDestructive: true, label: translate('Delete') }, { label: translate('Cancel') }],
        });
        if (ok) {
            removeActivity(activity);
            logEvent('study_activity_deleted', { study_id: currentStudy.id, activity_name: activity.name });
        }
    };
    /**
     * Checks if the given activity has any dependent notifications
     * @param activity - Activity
     */
    const hasDependentNotifications = (activity) => {
        const { notifications } = currentStudy;
        return Object.values(notifications).some((notification) => {
            return notification.activityId === activity.id || notification.encounterId === activity.id;
        });
    };
    const hasDependentWorkflows = (activity) => {
        const { activityDependencies } = currentStudy;
        return Object.values(activityDependencies).some((dependency) => {
            return dependency.activityId === activity.id || dependency.encounterId === activity.id;
        });
    };
    /**
     * Given an activity and a visit,
     * it will include or exclude the activity on/from the visit depending on the previous visit schedule
     * @param activity
     * @param visitId
     */
    const toggleActivityOnVisit = (activity, visitId) => {
        const visit = getVisitById(visitId);
        if (visit) {
            if (visit.activities[activity.id]) {
                visit.removeActivity(activity);
                // TODO: add some sort of remove confirmation if the activity has a status update
                // attached to it, once we get design for it
                const status = activity.visitInfoMap[visitId]?.statusId;
                delete activity.visitInfoMap[visitId];
                // If the last visit info was removed above, we should store the status in a temp visit info
                if (activity.details.type === ActivityType.PARTICIPANT && Object.keys(activity.visitInfoMap).length === 0) {
                    activity.visitInfoMap.temp = { statusId: status };
                }
            }
            else {
                // status updates apply to all visits for participant facing tasks
                if (activity.details.type === ActivityType.PARTICIPANT) {
                    const status = Object.values(activity.visitInfoMap)[0]?.statusId;
                    if (status) {
                        activity.visitInfoMap[visitId] = { ...(activity.visitInfoMap[visitId] || {}), statusId: status };
                        // If the temp visit info was used, we should remove it
                        delete activity.visitInfoMap.temp;
                    }
                }
                visit.addActivity(activity);
            }
            logEvent('study_activity_scheduled', {
                activity_name: activity.name,
                assigned: !!visit.activities[activity.id],
                study_id: currentStudy.id,
                visit_name: visit.name,
            });
        }
        assignStudy(currentStudy);
    };
    const value = useMemo(() => ({
        activities,
        activityScreens,
        assignStudy,
        changeCurrentTimelineId,
        cloneStudy,
        configId,
        createNewStudy,
        currentStudy,
        currentTimelineId,
        getStudy,
        getStudyConfiguration,
        getVisitById,
        hasDependentNotifications,
        hasDependentWorkflows,
        isActivityOnVisit,
        isInitialized,
        isLoading,
        isValidVisit,
        onDeleteActivityClick,
        scheduledActivities,
        setOnboarding,
        studyId,
        toggleActivityOnVisit,
        visits,
    }), [
        activities,
        activityScreens,
        assignStudy,
        changeCurrentTimelineId,
        cloneStudy,
        configId,
        createNewStudy,
        currentStudy,
        currentTimelineId,
        getStudy,
        getStudyConfiguration,
        getVisitById,
        hasDependentNotifications,
        hasDependentWorkflows,
        isActivityOnVisit,
        isInitialized,
        isLoading,
        isValidVisit,
        onDeleteActivityClick,
        scheduledActivities,
        setOnboarding,
        studyId,
        toggleActivityOnVisit,
        visits,
    ]);
    return _jsx(StudyConfigurationContext.Provider, { value: value, children: children });
};
