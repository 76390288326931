import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Duration } from 'luxon';
import { useCallback, useContext, useState } from 'react';
import { v4 } from 'uuid';
import { ActivityType, LocalizationStatus, } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Dropdown } from 'libs.nucleus.dropdown';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { useDialog } from 'libs.nucleus.modal_dialog';
import { useBooleanState, useToastNotification } from 'libs.react.hooks';
import { StartingConditionType, EndingConditionType } from './activity_dependencies';
import { RemoteActivityEditModal } from './remote_activity_edit_modal';
import { StudyConfigurationContext } from '../../../contexts';
import { useActivityUtils } from '../../../hooks';
import { EnhancedMatrixRow } from '../../enhanced_matrix';
const gradientMap = {
    [ActivityType.PARTICIPANT]: 'repeating-linear-gradient(-45deg, #E7E1FF, #E7E1FF 10px, #F6F3FF 10px, #F6F3FF 20px)',
    [ActivityType.SITE]: 'repeating-linear-gradient(-45deg, #E1F7E7, #E1F7E7 10px, #F3FFF6 10px, #F3FFF6 20px)',
    [ActivityType.SITE_WORKFLOW]: 'repeating-linear-gradient(-45deg, #E1E9FF, #E1E9FF 10px, #F3F7FF 10px, #F3F7FF 20px)',
};
export const ScheduleTimelineRemoteRow = ({ item, dataTestId, headers, getCellStyles, width, rowIndex, defaultCellWidth, }) => {
    const { addNotification } = useToastNotification();
    const translate = useLocalizeMessage();
    const dialog = useDialog();
    const { assignStudy, currentStudy, visits, currentTimelineId, hasDependentNotifications, hasDependentWorkflows, studyId, } = useContext(StudyConfigurationContext);
    const [isRemoteActivityModalOpen, openRemoteActivityModal, closeRemoteActivityModal] = useBooleanState(false);
    const [remoteActivityToEdit, setRemoteActivityToEdit] = useState(undefined);
    const { getActivityIcon } = useActivityUtils();
    const { logEvent } = useContext(FirebaseAppContext);
    const disableBuildConfig = currentStudy.localizationStatus === LocalizationStatus.READY;
    const onEditRemoteActivity = useCallback((remoteActivity) => {
        setRemoteActivityToEdit(remoteActivity.scheduledActivity);
        openRemoteActivityModal();
    }, []);
    const onDeleteRemoteActivity = async (scheduledActivityId) => {
        const ok = await dialog.open({
            title: translate('Delete remote activity'),
            body: translate('Are you sure you want to delete this activity? This action cannot be undone.'),
            actionButtons: [{ isDestructive: true, label: translate('Delete') }, { label: translate('Cancel') }],
        });
        if (ok && currentStudy) {
            currentStudy.removeScheduledActivity({ timelineId: currentTimelineId, scheduledActivityId });
            assignStudy(currentStudy);
        }
    };
    const activityOptions = [
        { id: 'Edit', content: translate('Edit'), onClick: () => onEditRemoteActivity(item), disabled: disableBuildConfig },
        {
            id: 'Delete',
            content: translate('Delete'),
            destructive: true,
            onClick: async () => {
                const hasNotificationsDependency = hasDependentNotifications(item);
                const hasWorkflowsDependency = hasDependentWorkflows(item);
                if (hasNotificationsDependency || hasWorkflowsDependency) {
                    await dialog.open({
                        title: translate('Unable to delete activity'),
                        body: hasNotificationsDependency
                            ? translate('This activity cannot be deleted because it has a notification attached. Please modify or remove the notification first.')
                            : translate('This activity cannot be deleted because it has a workflow attached. Please modify or remove the workflow first.'),
                        actionButtons: [{ label: translate('Ok') }],
                    });
                }
                else {
                    onDeleteRemoteActivity(item.id);
                    addNotification({
                        title: translate('Remote Activity Removed'),
                        subtitle: translate('The remote activity "{name}" has been removed from the timeline.', {
                            name: item.name,
                        }),
                        type: 'success',
                    });
                    logEvent('remote_activity_deleted', {
                        study_id: studyId,
                        remote_activity_id: item.id,
                        remote_activity_name: item.name,
                        timeline_id: currentTimelineId,
                    });
                }
            },
            disabled: disableBuildConfig,
        },
    ];
    const onRemoteActivityCancel = useCallback(() => {
        setRemoteActivityToEdit(undefined);
        closeRemoteActivityModal();
    }, []);
    const onActivityScheduleSave = (activitySchedule) => {
        const startConditions = [];
        const endConditions = [];
        const baseCondition = {
            scheduleTimelineId: currentTimelineId,
            scheduleTimelineName: currentStudy.getScheduleTimelines()[currentTimelineId].name,
            activityName: item.name,
            activityId: remoteActivityToEdit.activityId,
            encounterId: '',
            encounterName: '',
        };
        activitySchedule.startConditions?.forEach((condition) => {
            const fromOffset = condition.offset?.value ? Number(condition.offset.value) : 0;
            switch (condition.option.value) {
                case StartingConditionType.AFTER_ACTIVITY_COMPLETION:
                case StartingConditionType.VISIT_CONFIRMATION:
                    startConditions.push({
                        ...baseCondition,
                        id: condition.id || v4(),
                        dependencyType: 'start',
                        dependencyId: condition.activityCompletion.meta?.activityId,
                        offsetAfterDateAnchor: fromOffset,
                        triggerType: 'activity_completion',
                        encounterId: condition.visitId?.value ?? '',
                        encounterName: condition.visitId?.label ?? '',
                    });
                    break;
                case StartingConditionType.ACTIVITY_RESPONSE:
                    startConditions.push({
                        ...baseCondition,
                        id: condition.id || v4(),
                        dependencyType: 'start',
                        dependencyId: condition.activityResponse.activity.meta?.activityId,
                        triggerType: 'screen_response',
                        triggerScreen: condition.activityResponse.screen.label,
                        triggerScreenKey: condition.activityResponse.screen.value,
                        comparisonOperator: '==',
                        comparisonValue: condition.activityResponse.response.value,
                    });
                    break;
                case StartingConditionType.DATE_RESPONSE:
                    startConditions.push({
                        ...baseCondition,
                        id: condition.id || v4(),
                        dependencyType: 'start',
                        dependencyId: condition.activityResponse.activity.meta?.activityId,
                        triggerType: 'date_screen_response',
                        triggerScreen: condition.activityResponse.screen.label,
                        triggerScreenKey: condition.activityResponse.screen.value,
                        offsetAfterDateAnchor: fromOffset,
                    });
                    break;
                case StartingConditionType.STUDY_CREATION:
                    startConditions.push({
                        ...baseCondition,
                        id: condition.id || v4(),
                        dependencyType: 'start',
                        offsetAfterDateAnchor: fromOffset,
                        triggerType: 'study_creation',
                    });
                    break;
            }
        });
        activitySchedule.endConditions?.forEach((condition) => {
            if (activitySchedule.type === 'recurring' &&
                (condition.type === EndingConditionType.NUM_OF_OCURRENCES || condition.type === EndingConditionType.ENDLESS)) {
                return;
            }
            const toOffset = condition.offset?.value ? Number(condition.offset.value) : 0;
            switch (condition.option.value) {
                case EndingConditionType.AFTER_DURATION:
                    if (condition.duration && condition.durationPeriod) {
                        const windowDuration = Duration.fromObject({
                            [condition.durationPeriod.value]: condition.duration.value || 0,
                        }).toISO();
                        endConditions.push({
                            ...baseCondition,
                            id: condition.id || v4(),
                            dependencyType: 'end',
                            triggerType: 'duration',
                            windowDuration,
                        });
                    }
                    break;
                case EndingConditionType.UPON_COMPLETION:
                    endConditions.push({
                        ...baseCondition,
                        id: condition.id || v4(),
                        dependencyType: 'end',
                        triggerType: 'activity_completion',
                        dependencyId: remoteActivityToEdit.activityId,
                        endCriteria: 'until_completion',
                    });
                    break;
                case EndingConditionType.END_OF_DAY:
                    endConditions.push({
                        ...baseCondition,
                        id: condition.id || v4(),
                        dependencyType: 'end',
                        triggerType: 'end_of_day',
                        endCriteria: 'end_of_day',
                    });
                    break;
                case EndingConditionType.ACTIVITY_RESPONSE:
                    endConditions.push({
                        ...baseCondition,
                        id: condition.id || v4(),
                        dependencyType: 'end',
                        triggerType: 'screen_response',
                        dependencyId: condition.activityResponse.activity.meta?.activityId,
                        triggerScreen: condition.activityResponse.screen.label,
                        triggerScreenKey: condition.activityResponse.screen.value,
                        comparisonOperator: '==',
                        comparisonValue: condition.activityResponse.response.value,
                    });
                    break;
                case EndingConditionType.DATE_RESPONSE:
                    endConditions.push({
                        ...baseCondition,
                        id: condition.id || v4(),
                        dependencyType: 'end',
                        dependencyId: condition.activityResponse.activity.meta?.activityId,
                        triggerType: 'date_screen_response',
                        triggerScreen: condition.activityResponse.screen.label,
                        triggerScreenKey: condition.activityResponse.screen.value,
                        offsetAfterDateAnchor: toOffset,
                    });
                    break;
                case EndingConditionType.AFTER_ACTIVITY_COMPLETION:
                case EndingConditionType.VISIT_CONFIRMATION:
                    endConditions.push({
                        ...baseCondition,
                        id: condition.id || v4(),
                        dependencyType: 'end',
                        dependencyId: condition.activityCompletion.meta?.activityId,
                        triggerType: 'activity_completion',
                        offsetAfterDateAnchor: toOffset,
                        encounterId: condition.visitId?.value ?? '',
                        encounterName: condition.visitId?.label ?? '',
                    });
                    break;
            }
        });
        currentStudy.editScheduledActivityTiming({
            timelineId: currentTimelineId,
            scheduledActivityId: remoteActivityToEdit.id,
            timing: {
                ...remoteActivityToEdit.timing,
                startConditions,
                endConditions,
                timingDetails: { specificTime: activitySchedule.specificStartTime },
                ...(activitySchedule.type === 'recurring' &&
                    activitySchedule.repetition?.repetitionEvery && {
                    recurrenceRule: {
                        frequency: activitySchedule.repetition.repetitionEveryPeriod.value,
                        interval: Number(activitySchedule.repetition.repetitionEvery),
                        ...(activitySchedule.repetition.count && { count: Number(activitySchedule.repetition.count) }),
                    },
                }),
            },
        });
        assignStudy(currentStudy);
        closeRemoteActivityModal();
        addNotification({
            title: translate('Remote Activity Updated'),
            subtitle: translate('The configuration for the remote activity "{name}" has been updated successfully', {
                name: item.name,
            }),
            type: 'success',
        });
        logEvent('remote_activity_updated', {
            study_id: studyId,
            activity_id: remoteActivityToEdit.id,
            activity_name: item.name,
            timeline_id: currentTimelineId,
        });
    };
    const filteredVisits = visits.filter((visit) => visit.encounterDetails.visitType === 'standard');
    // Find the index of the start and end triggers for the remote activity in the visits array
    let cellWidth = defaultCellWidth;
    let startIndex = filteredVisits.findIndex((visit) => item.startTrigger.includes(visit.name));
    startIndex = startIndex === -1 ? 0 : startIndex;
    let endIndex = filteredVisits.findIndex((visit) => item.endTrigger.includes(visit.name));
    endIndex = endIndex === -1 ? filteredVisits.length : endIndex;
    if (endIndex > startIndex) {
        // If the end trigger is set, calculate the width of the cell based on the start and end triggers
        cellWidth = cellWidth * (endIndex - startIndex);
    }
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedMatrixRow, { dataTestId: dataTestId, width: width, rowIndex: rowIndex, children: headers.map((header, index) => {
                    // We hide as many extra cells as the cells consumed by remote activities happening in multiple visits to ensure the row end is aligned
                    if ((index - 1 > startIndex && endIndex === -1) || (index - 1 > startIndex && index <= endIndex)) {
                        return null;
                    }
                    // we subtract 1 from the index to account for the activity name cell when calculating the width of the cell and the background gradient
                    return (_jsxs("div", { className: getCellStyles(index), style: {
                            width: header.key === 'activityName'
                                ? header.width
                                : index - 1 === startIndex
                                    ? cellWidth
                                    : defaultCellWidth,
                            padding: '0.25rem',
                            flexShrink: 0,
                        }, children: [header.key === 'activityName' && (_jsxs("div", { className: 'flex h-full w-[17.5rem] items-center justify-between pl-4 pr-1 font-sans text-xs font-medium group', children: [getActivityIcon(item.type), _jsx("span", { className: 'flex-1 ml-4 line-clamp-2', "data-testid": 'schedule-timeline-remote-row-name', title: item.name, children: item.name }), _jsx("div", { className: 'opacity-0 group-hover:opacity-100 group-focus:opacity-100 group-focus-within:opacity-100 has-[button[aria-expanded="true"]]:opacity-100', children: _jsx(Dropdown, { items: activityOptions, variant: 'kebab' }) })] })), header.key !== 'activityName' && (_jsx("div", { className: 'flex h-full w-full items-center justify-center rounded-[0.25rem]', style: {
                                    background: index - 1 === startIndex ? gradientMap[item.type] : 'initial',
                                } }))] }, `timeline-${item.id}-remote-cell-${header.key}`));
                }) }), isRemoteActivityModalOpen && (_jsx(RemoteActivityEditModal, { initialActivity: remoteActivityToEdit, activityName: item.name, onCancel: onRemoteActivityCancel, onSave: onActivityScheduleSave, activityType: item.type }))] }));
};
