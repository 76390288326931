import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useLocalization } from 'libs.localization.react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Spinner } from 'libs.nucleus.spinner';
import { useAssetContext } from '../../hooks/use_asset_context.hook';
import { TableImport } from '../import_table';
import { EmptySection } from '../section_empty';
export const SectionImport = ({ asset, locales = [] }) => {
    const translate = useLocalizeMessage();
    const { localizationEntry, isLoading } = useLocalization(asset);
    const { getReviewUrl } = useAssetContext();
    const supportedLocales = useMemo(() => Object.values(localizationEntry?.supported_locales || {}).filter(({ locale }) => locale !== localizationEntry?.base_locale), [localizationEntry.supported_locales, localizationEntry.base_locale]);
    const data = supportedLocales
        .filter(({ locale }) => locales.includes(locale))
        .map(({ locale, last_updated, version, status }) => ({
        locale: locale,
        status,
        last_updated: last_updated.toDate().toISOString(),
        version: version,
        reviewPath: `${getReviewUrl()}/${locale}?assetType=${asset?.type}`,
    }));
    if (supportedLocales.length > 0) {
        return _jsx(TableImport, { data: data });
    }
    if (isLoading) {
        return _jsx(Spinner, { wrapper: 'full' });
    }
    return _jsx(EmptySection, { text: translate('Imported strings will appear here') });
};
