import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const SIZE = {
    xs: 'h-3 w-3',
    sm: 'h-5 w-5',
    base: 'h-8 w-8',
    lg: 'h-12 w-12',
    xl: 'h-16 w-16',
};
const COLOR = {
    primary: 'text-primary',
    secondary: 'text-neutral-400',
};
export const Loader = ({ size = 'base', color = 'primary' }) => {
    return (_jsx("section", { className: 'absolute inset-0 z-10 flex items-center justify-center bg-white/60', children: _jsxs("svg", { className: `animate-spin ${SIZE[size]} ${COLOR[color]}`, xmlns: 'http://www.w3.org/2000/svg', fill: 'none', viewBox: '0 0 24 24', children: [_jsx("circle", { className: 'opacity-25', cx: '12', cy: '12', r: '10', stroke: 'currentColor', strokeWidth: '4' }), _jsx("path", { className: 'opacity-75', fill: 'currentColor', d: 'M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z' })] }) }));
};
