import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext, useMemo, useState } from 'react';
import { LocalizationStatus } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Button } from 'libs.nucleus.button';
import { Dropdown } from 'libs.nucleus.dropdown';
import { LocaleContext, useLocalizeMessage } from 'libs.nucleus.i18n';
import { useDialog } from 'libs.nucleus.modal_dialog';
import { Table } from 'libs.nucleus.table';
import { Toggle } from 'libs.nucleus.toggle';
import { Text } from 'libs.nucleus.typography';
import { SelectLocalesModal, getLocalesTableArray } from 'libs.react.components';
import { useBooleanState } from 'libs.react.hooks';
import { TranslationStartedNotification } from 'libs.translation_workbench';
import { EditLocaleModal } from './edit_locale_modal.component';
import { StudyConfigurationContext } from '../../../contexts';
const stringSortFn = (a, b) => a.code.localeCompare(b.code);
const getInitialLocales = (currentLocale, participantLocales = [], siteLocales = [], enforceBaseLocale) => {
    let locales = getLocalesTableArray(currentLocale);
    if (enforceBaseLocale) {
        locales = locales.filter((locale) => locale.code === 'en-US');
    }
    const studyLocales = locales.reduce((acc, locale) => {
        const type = participantLocales.includes(locale.code) && siteLocales.includes(locale.code)
            ? 'both'
            : participantLocales.includes(locale.code)
                ? 'participant'
                : siteLocales.includes(locale.code)
                    ? 'site'
                    : undefined;
        if (type) {
            acc.push({ ...locale, type });
        }
        return acc;
    }, []);
    return studyLocales.sort(stringSortFn);
};
export const StudyLocales = () => {
    const translate = useLocalizeMessage();
    const currentLocale = useContext(LocaleContext);
    const { currentStudy, assignStudy, studyId } = useContext(StudyConfigurationContext);
    const [isAddLocalesModalOpen, openAddLocalesModal, closeAddLocalesModal] = useBooleanState(false);
    const [isEditLocaleModalOpen, openEditLocaleModal, closeEditLocaleModal] = useBooleanState(false);
    const { logEvent } = useContext(FirebaseAppContext);
    const disableBuildConfig = currentStudy.localizationStatus === LocalizationStatus.READY;
    const [locales, setLocales] = useState(getInitialLocales(currentLocale, currentStudy.getStudyMetadata().participantLocales, currentStudy.getStudyMetadata().siteLocales, currentStudy.getStudyMetadata().enforceBaseLocale));
    const dialog = useDialog();
    const [localeToEdit, setLocaleToEdit] = useState();
    const { enforceBaseLocale } = currentStudy.getStudyMetadata();
    const handleOnAddLocales = (selectedLocales) => {
        const newLocales = [...locales, ...selectedLocales];
        setLocales(newLocales.sort(stringSortFn));
        const siteLocales = [];
        const participantLocales = [];
        newLocales.forEach((locale) => {
            switch (locale.type) {
                case 'site':
                    siteLocales.push(locale.code);
                    break;
                case 'participant':
                    participantLocales.push(locale.code);
                    break;
                case 'both':
                    siteLocales.push(locale.code);
                    participantLocales.push(locale.code);
                    break;
            }
        });
        currentStudy.updateStudyMetadata({ participantLocales, siteLocales });
        assignStudy(currentStudy);
        logEvent('locales_added', { study_id: studyId, locales: newLocales.map((locale) => locale.code).join(', ') });
        closeAddLocalesModal();
    };
    const localesColumns = useMemo(() => {
        return [
            { header: translate('Code'), accessorKey: 'code' },
            { header: translate('Language/Script'), accessorKey: 'language' },
            { header: translate('Country/Region'), accessorKey: 'region' },
            {
                header: translate('Availability'),
                accessorFn: (locale) => locale.type === 'site'
                    ? translate('Site')
                    : locale.type === 'participant'
                        ? translate('Participant')
                        : translate('Site and Participant'),
            },
            {
                id: 'options',
                size: 20,
                cell: ({ row }) => {
                    const dropdownItems = [
                        {
                            id: 'edit',
                            content: translate('Edit'),
                            onClick: () => handleOpenEditLocaleModal(row.original),
                            disabled: disableBuildConfig,
                        },
                        {
                            id: 'delete',
                            content: translate('Delete'),
                            onClick: () => onDeleteLocale(row.original),
                            destructive: true,
                            disabled: row.original.code === 'en-US' || disableBuildConfig,
                        },
                    ];
                    return (_jsx("section", { className: 'flex flex-col items-end', children: _jsx(Dropdown, { position: 'bottom-end', items: dropdownItems, variant: 'kebab' }) }));
                },
            },
        ];
    }, [locales]);
    const onDeleteLocale = async (locale) => {
        // @TODO check the status of the locale in the study.
        // if status === LocaleStatus.NOT_STARTED, delete the locale without confirmation
        // if status !== LocaleStatus.NOT_STARTED, show a confirmation dialog
        const ok = await dialog.open({
            title: translate('Remove locale'),
            body: (_jsx("div", { className: 'min-h-[300px]', children: translate(`The ${locale.code} locale has been used for translation in this study. This locale will be removed from displaying in translation workflows. Progress will be archived and disabled.`) })),
            actionButtons: [{ isDestructive: true, label: translate('Remove') }, { label: translate('Cancel') }],
        });
        if (ok) {
            removeLocale(locale);
            logEvent('locale_removed', { study_id: studyId, locale: locale.code });
        }
    };
    const removeLocale = (localeToRemove) => {
        const newLocales = locales.filter((locale) => locale.code !== localeToRemove.code).map((locale) => ({ ...locale }));
        setLocales(newLocales.sort(stringSortFn));
        const { siteLocales } = currentStudy.getStudyMetadata();
        const { participantLocales } = currentStudy.getStudyMetadata();
        switch (localeToRemove.type) {
            case 'site':
                siteLocales.splice(siteLocales.indexOf(localeToRemove.code), 1);
                break;
            case 'participant':
                participantLocales.splice(participantLocales.indexOf(localeToRemove.code), 1);
                break;
            case 'both':
                siteLocales.splice(siteLocales.indexOf(localeToRemove.code), 1);
                participantLocales.splice(participantLocales.indexOf(localeToRemove.code), 1);
                break;
        }
        currentStudy.updateStudyMetadata({ participantLocales, siteLocales });
        assignStudy(currentStudy);
    };
    const handleOpenEditLocaleModal = (locale) => {
        setLocaleToEdit(locale);
        openEditLocaleModal();
    };
    const handleCloseEditLocaleModal = () => {
        setLocaleToEdit(undefined);
        closeEditLocaleModal();
    };
    const handleOnEditLocale = (localeType) => {
        const newLocales = locales.map((locale) => {
            if (locale.code === localeToEdit?.code) {
                return { ...locale, type: localeType };
            }
            return locale;
        });
        setLocales(newLocales);
        const { siteLocales } = currentStudy.getStudyMetadata();
        const { participantLocales } = currentStudy.getStudyMetadata();
        switch (localeType) {
            case 'site':
                if (localeToEdit.type !== 'both') {
                    siteLocales.push(localeToEdit.code);
                }
                participantLocales.splice(participantLocales.indexOf(localeToEdit.code), 1);
                break;
            case 'participant':
                if (localeToEdit.type !== 'both') {
                    participantLocales.push(localeToEdit.code);
                }
                siteLocales.splice(siteLocales.indexOf(localeToEdit.code), 1);
                break;
            case 'both':
                if (localeToEdit.type === 'site') {
                    participantLocales.push(localeToEdit.code);
                }
                else {
                    siteLocales.push(localeToEdit.code);
                }
                break;
        }
        currentStudy.updateStudyMetadata({ participantLocales, siteLocales });
        assignStudy(currentStudy);
        logEvent('locale_updated', { study_id: studyId, locale: localeToEdit.code, type: localeType });
        closeEditLocaleModal();
        setLocaleToEdit(undefined);
    };
    const handleEnglishOnlyToggle = (value) => {
        currentStudy.updateStudyMetadata({ enforceBaseLocale: value });
        assignStudy(currentStudy);
        setLocales(getInitialLocales(currentLocale, currentStudy.getStudyMetadata().participantLocales, currentStudy.getStudyMetadata().siteLocales, value));
        logEvent('enforce_US_locale_only_toggled', { study_id: studyId, us_locale_only: value });
    };
    return (_jsx(_Fragment, { children: _jsxs("div", { className: 'flex flex-col gap-5', children: [disableBuildConfig && _jsx(TranslationStartedNotification, {}), _jsxs("div", { className: 'flex gap-6', "data-testid": 'study-notification-en-us-only', children: [_jsx(Text, { className: 'font-medium', size: 'sm', children: translate('The study is en-US only') }), _jsx(Text, { className: 'text-secondary flex-1', size: 'sm', children: translate('Multiple tasks like translation are not needed and will be disabled') }), _jsx(Toggle, { checked: enforceBaseLocale, dataTestId: 'english-only-toggle', disabled: disableBuildConfig, onChange: handleEnglishOnlyToggle })] }), _jsx("hr", { className: 'mb-6' }), _jsx("div", { className: 'ml-auto', children: _jsx(Button, { label: translate('Select locales'), onClick: openAddLocalesModal, disabled: enforceBaseLocale || disableBuildConfig }) }), _jsx(Table, { data: locales, columns: localesColumns, labelNoResults: translate('No locales'), getRowId: 'code', hasPagination: true, paginationNavigationScreenReaderLabel: translate('Site locale table navigation'), initialPageSize: 10 }), isAddLocalesModalOpen && (_jsx(SelectLocalesModal, { onAddLocales: handleOnAddLocales, onClose: closeAddLocalesModal, showLocaleTypeSelectStep: true, hideLocales: locales.map((locale) => locale.code) })), isEditLocaleModalOpen && (_jsx(EditLocaleModal, { onSave: handleOnEditLocale, onClose: handleCloseEditLocaleModal, initialLocaleType: localeToEdit?.type ?? 'both' }))] }) }));
};
