import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { ToastNotification, ToastNotificationContext } from 'libs.nucleus.notification';
const DEFAULT_TIMEOUT = 10000;
export const useToastNotification = () => {
    const { add, remove } = useContext(ToastNotificationContext);
    const addNotification = (notification, timeout = DEFAULT_TIMEOUT) => {
        const id = add(_jsx(ToastNotification, Object.assign({}, notification)));
        setTimeout(() => {
            remove(id);
        }, timeout);
    };
    return {
        addNotification,
    };
};
