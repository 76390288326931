import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FirebaseAppContext } from 'libs.firebase_react';
import { AssetTypes } from 'libs.localization.common';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Link } from 'libs.nucleus.link';
import { Spinner } from 'libs.nucleus.spinner';
import { TranslationWorkbenchContext } from '../../contexts';
import { sortLocales } from '../../utils';
import { AssetBaseVersion } from '../locale_matrix/asset_base_version.component';
import { BuildVersion } from '../locale_matrix/build_version.component';
import { LocaleStatusIcon } from '../locale_status_icon';
import { EmptySection } from '../section_empty';
export const AssetsLocaleList = ({ data, isLoading = false }) => {
    const t = useLocalizeMessage();
    const { logEvent } = useContext(FirebaseAppContext);
    const { studyId } = useContext(TranslationWorkbenchContext);
    const allLocales = useMemo(() => {
        let allLocalesList = [];
        for (const key in data) {
            if (data[key].statuses && data[key].path) {
                allLocalesList = allLocalesList.concat(Object.keys(data[key].statuses));
            }
        }
        return sortLocales(Array.from(new Set(allLocalesList)));
    }, [data]);
    const handleLinkClick = (asset) => {
        logEvent('translations_asset_click', {
            asset,
            studyId,
        });
    };
    if (isLoading) {
        return (_jsx("div", { className: 'flex items-center justify-center p-20 grid place-items-center rounded-lg border border-500 min-h-36 gap-5', "data-testid": 'assets-locale-list--loading', children: _jsx(Spinner, {}) }));
    }
    if (Object.keys(data).length === 0) {
        return _jsx(EmptySection, { text: t('No Results') });
    }
    return (_jsx("div", { className: 'overflow-x-auto relative', children: _jsxs("table", { className: 'min-w-auto divide-y divide-neutral-100 table-fixed', "data-testid": 'assets-locale-list', children: [_jsx("thead", { className: 'sticky top-0 z-10 w-144 text-left text-xs text-secondary bg-neutral-75', children: _jsxs("tr", { children: [_jsx("th", { scope: 'col', className: 'px-6 py-3 sticky left-0 z-10 font-medium bg-neutral-75', children: t('Name') }), _jsx("th", { scope: 'col', className: 'py-3 font-medium w-8', children: t('Build') }), _jsx("th", { scope: 'col', className: 'px-6 py-3 font-medium', children: t('Type') }), allLocales.map((locale) => (_jsx("th", { scope: 'col', className: 'px-6 py-3 font-medium whitespace-nowrap', children: locale }, locale)))] }) }), _jsx("tbody", { className: 'bg-white divide-y divide-neutral-100', children: Object.keys(data).map((asset) => (_jsxs("tr", { children: [_jsx("td", { className: 'px-6 py-4 max-w-[300px] sticky left-0 z-10 w-144 bg-white', children: _jsx(Link, { LinkComponent: RouterLink, to: `${data[asset].path}?assetType=${data[asset].assetType}`, onClick: () => handleLinkClick(asset), children: t(asset) }) }), _jsx("td", { className: 'py-4 whitespace-nowrap w-8 text-center', children: _jsx("span", { className: 'text-sm text-neutral-600', children: data[asset]?.asset?.type === AssetTypes.ACTIVITY ? (_jsx(BuildVersion, { version: data[asset]?.buildVersion || data[asset]?.asset?.version })) : (_jsx(AssetBaseVersion, { asset: data[asset].asset })) }) }), _jsx("td", { className: 'px-6 py-4 whitespace-nowrap', children: _jsx("span", { className: 'text-sm text-neutral-600', children: t(data[asset].type || '') }) }), allLocales.map((locale) => (_jsx("td", { className: 'px-6 py-4 whitespace-nowrap text-center', children: _jsx(LocaleStatusIcon, { status: data[asset].statuses?.[locale] }) }, `${asset}-${locale}`)))] }, asset))) })] }) }));
};
