import styled from 'styled-components';
export const PreviewSection = styled.section `
  margin: ${(props) => (props.$noFrame ? '0' : '1em')};
`;
export const IFrame = styled.iframe `
  border: ${(props) => (props.$noFrame ? '0' : '1px solid rgba(209, 213, 219, 1)')};
  border-radius: ${(props) => (props.$noFrame ? '0' : '6px')};
  box-shadow: ${(props) => props.$noFrame ? 'none' : '0px 1px 2px 0px rgba(0, 0, 0, 0.06),0px 1px 3px 0px rgba(0, 0, 0, 0.1)'};
  transition: all 300ms ease-in-out;
  transform-origin: top left;
`;
export const Message = styled.div `
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: grid;
  place-items: center;
  border: ${(props) => (props.$noFrame ? '0' : '1px solid rgba(209, 213, 219, 1)')};
  border-radius: ${(props) => (props.$noFrame ? '0' : '6px')};
  box-shadow: ${(props) => props.$noFrame ? 'none' : '0px 1px 2px 0px rgba(0, 0, 0, 0.06),0px 1px 3px 0px rgba(0, 0, 0, 0.1)'};
  transition: all 300ms ease-in-out;
  transform-origin: top left;
`;
export const DeviceSelectorWrapper = styled.div `
  display: flex;
  gap: 1.5em;
`;
export const ZoomControlWrapper = styled.div `
  padding: 0.5em;
  display: flex;
  gap: 16px;
`;
