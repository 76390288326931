import { useContext, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { DefaultActivityId, DefaultTimelineId } from '@axon/rosetta-sdk';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { sanitizeHtmlString } from 'libs.utils.sanitize';
import { StudyConfigurationContext } from '../../../contexts';
export var StatusConditionType;
(function (StatusConditionType) {
    StatusConditionType["AFTER_ACTIVITY_COMPLETION"] = "afterActivityCompletion";
    StatusConditionType["ACTIVITY_RESPONSE"] = "activityResponse";
})(StatusConditionType || (StatusConditionType = {}));
export const DEFAULT_RESPONSE_KEY = '';
export const useScheduleTimelineEditActivityModal = (activity) => {
    const translate = useLocalizeMessage();
    const { activityScreens, currentTimelineId, currentStudy } = useContext(StudyConfigurationContext);
    const completionOption = {
        label: translate('Set a status based on completion of activity'),
        value: StatusConditionType.AFTER_ACTIVITY_COMPLETION,
    };
    /**
     * List of participant statuses available to used as Dropdown Options
     */
    const statusOptions = useMemo(() => {
        const { participantStatuses } = currentStudy.getStudyMetadata();
        const options = [{ label: translate('-select status-'), value: '' }];
        return options.concat(participantStatuses.map((status) => ({ label: status.value, value: status.id })));
    }, [currentStudy]);
    /**
     * List of VRS Screens available in the current activity
     */
    const vrsScreens = useMemo(() => {
        const screens = activityScreens[activity.details.libraryId ?? ''] ?? [];
        return screens.filter((screen) => screen.type === 'vrs');
    }, [activityScreens, currentStudy]);
    /**
     * Lists of responses and screens to be used as Dropdown Options
     */
    const { responseOptions, screenOptions } = useMemo(() => {
        const screenOptions = [{ label: translate('-select screen-'), value: '' }];
        const responseOptions = {
            [DEFAULT_RESPONSE_KEY]: [{ label: translate('-select response-'), value: '' }],
        };
        // only add activity choice if it has associated screens
        if (vrsScreens.length > 0) {
            for (const screen of vrsScreens) {
                screenOptions.push({ label: screen.name, value: screen.key });
                responseOptions[screen.key] = [{ label: translate('-select response-'), value: '', isDisabled: true }];
                for (const choice of screen.choices) {
                    responseOptions[screen.key].push({ label: sanitizeHtmlString(choice.display), value: choice.value });
                }
            }
        }
        return { responseOptions, screenOptions };
    }, []);
    /**
     * List of Status Conditions that include the different selection for each condition
     */
    const [statusConditions, setStatusConditions] = useState(() => {
        const participantStatusConditions = currentStudy
            .getParticipantStatusConditions()
            .filter((savedCondition) => savedCondition.activityId === activity.id);
        const newConditions = participantStatusConditions.map((condition) => {
            const sharedProps = {
                id: condition.id,
                status: statusOptions.find((status) => status.value === condition.updatesParticipantStatusTo) || statusOptions[0],
            };
            if (condition.triggerType === 'activity_completion') {
                return {
                    ...sharedProps,
                    option: completionOption,
                    type: StatusConditionType.AFTER_ACTIVITY_COMPLETION,
                };
            }
            const screen = screenOptions.find((screen) => screen.value === condition.triggerScreenKey) || screenOptions[0];
            return {
                ...sharedProps,
                option: completionOption,
                type: StatusConditionType.ACTIVITY_RESPONSE,
                screen,
                response: responseOptions[screen.value]?.find((response) => response.value === condition.comparisonValue) ||
                    responseOptions[DEFAULT_RESPONSE_KEY][0],
            };
        });
        return newConditions.length > 0
            ? newConditions
            : [
                {
                    id: v4(),
                    option: completionOption,
                    type: StatusConditionType.AFTER_ACTIVITY_COMPLETION,
                    status: statusOptions[0],
                },
            ];
    });
    /**
     * Available options to be selected as Status Condition
     */
    const statusConditionOptions = useMemo(() => {
        const options = [{ ...completionOption, isDisabled: statusConditions.length > 1 }];
        if (activity.id !== DefaultActivityId.AUTHENTICATION && vrsScreens.length > 0) {
            options.push({
                label: translate('Set a status based on a response'),
                value: StatusConditionType.ACTIVITY_RESPONSE,
            });
        }
        return options;
    }, [currentStudy, statusConditions, vrsScreens]);
    /**
     * Lists of timelines to be used as Dropdown Options
     */
    const timelineOptions = useMemo(() => {
        const timelineOptions = [{ label: translate('-select schedule-'), value: '', isDisabled: true }];
        Object.values(currentStudy.timelineInfo)
            .filter((timeline) => timeline.id !== currentTimelineId && timeline.id !== DefaultTimelineId.ONBOARDING)
            .map((timeline) => timelineOptions.push({ label: timeline.name, value: timeline.id }));
        return timelineOptions;
    }, []);
    const [scheduleConditions, setScheduleConditions] = useState(() => {
        const conditions = currentStudy
            .getScheduleTimelineConditions()
            .filter((savedCondition) => savedCondition.activityId === activity.id);
        if (conditions.length === 0) {
            return [
                {
                    id: v4(),
                    response: responseOptions[DEFAULT_RESPONSE_KEY][0],
                    screen: screenOptions[0],
                    timeline: timelineOptions[0],
                },
            ];
        }
        return conditions.map((condition) => {
            const screen = screenOptions.find((screen) => screen.value === condition.triggerScreenKey) || screenOptions[0];
            return {
                id: condition.id,
                screen,
                response: responseOptions[screen.value]?.find((response) => response.value === condition.comparisonValue) ||
                    responseOptions[DEFAULT_RESPONSE_KEY][0],
                timeline: timelineOptions.find((timeline) => timeline.value === condition.scheduleTimelineId) || timelineOptions[0],
            };
        });
    });
    return {
        responseOptions,
        scheduleConditions,
        screenOptions,
        setScheduleConditions,
        setStatusConditions,
        statusConditionOptions,
        statusConditions,
        statusOptions,
        timelineOptions,
        vrsScreens,
    };
};
