// Base classes applied to all inputs
const baseClasses = [
    'box-border',
    'max-w-full',
    'border-solid',
    'bg-neutral-0',
    'font-sans',
    'text-sm',
    'font-medium',
    'not-italic',
    'shadow-sm',
    'rounded-md',
    'border',
    'p-4',
    'mt-2',
    'focus:outline-none',
    'focus:ring-1',
    'disabled:bg-neutral-200',
    'disabled:text-text-disabled',
    'disabled:border-neutral-200',
].join(' ');
export const getInputClassnames = (hasError, type) => {
    const typeClasses = type === 'textarea'
        ? 'overflow-auto resize-y min-h-40 list-disc list-numeric [&>ul]:ps-8 [&>ol]:ps-8'
        : 'overflow-hidden flex content-center h-10 items-center !text-nowrap';
    const errorClasses = hasError
        ? 'text-text-error border-red-500 hover:border-red-500 focus:ring-red-500 focus:border-red-500'
        : 'text-text-primary border-neutral-300 hover:border-neutral-500 focus:ring-purple-500 focus:border-purple-500';
    // Combine all classes
    return `${baseClasses} ${errorClasses} ${typeClasses}`;
};
export const linkClasses = 'box-border font-sans text-sm text-purple-500 underline focus:outline-none';
export const textClasses = 'box-border font-sans text-sm text-text-primary';
export const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const EMAIL_REGEX = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
export const validateUrl = (url) => {
    return url === 'https://' || URL_REGEX.test(url);
};
/*
 * Extended the sanitization logic to support img tags and also content with only images.
 * Previously if there was no content besides an image the image was dropped and the value became
 * an empty string.
 *
 * TODO: Remove this logic from here and properly control how nodes export to dom by using the exportDOM
 * on each node. This causes 0.5ms - 1ms performance decrease when typing.
 */
export const transformValue = (input, retainTarget) => {
    const trimmedInput = input.trim();
    if (!trimmedInput) {
        return '';
    }
    // Create a temporary element to parse the HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = trimmedInput;
    const allowedTags = [
        'p',
        'div',
        'span',
        'a',
        'b',
        'strong',
        'i',
        'em',
        'u',
        'img',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'ul',
        'ol',
        'li',
    ];
    const allowedAttributes = {
        a: retainTarget ? ['href', 'target'] : ['href'],
        img: ['src', 'alt', 'title'],
    };
    const cleanElement = (element) => {
        if (!allowedTags.includes(element.tagName.toLowerCase())) {
            // Remove disallowed element completely
            element.parentNode.removeChild(element);
        }
        else {
            // Remove disallowed attributes
            Array.from(element.attributes).forEach((attr) => {
                const tagAllowedAttrs = allowedAttributes[element.tagName.toLowerCase()] || [];
                if (!tagAllowedAttrs.includes(attr.name)) {
                    element.removeAttribute(attr.name);
                }
            });
            // Clean child elements
            Array.from(element.childNodes).forEach((child) => {
                if (child.nodeType === Node.ELEMENT_NODE) {
                    cleanElement(child);
                }
            });
        }
    };
    // Clean the temporary element
    Array.from(tempElement.childNodes)
        .slice()
        .reverse()
        .forEach((node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
            cleanElement(node);
        }
    });
    const removeEmptyTags = (node) => {
        Array.from(node.childNodes)
            .slice()
            .reverse()
            .forEach((child) => {
            var _a;
            if (child.nodeType === Node.ELEMENT_NODE && child instanceof Element) {
                removeEmptyTags(child);
                const childElement = child;
                const tagName = childElement.tagName.toLowerCase();
                const isEmptyContent = ((_a = childElement.textContent) === null || _a === void 0 ? void 0 : _a.trim()) === '' && !childElement.querySelector('*');
                if (isEmptyContent && tagName !== 'img') {
                    childElement.parentNode.removeChild(childElement);
                }
            }
        });
    };
    removeEmptyTags(tempElement);
    const cleanedHtml = tempElement.innerHTML.trim();
    return cleanedHtml || '';
};
