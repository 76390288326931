import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useContext, useMemo, useState } from 'react';
import { Checkbox } from 'libs.nucleus.checkbox';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { RadioGroup, RadioGroupOption } from 'libs.nucleus.radio_group';
import { Select } from 'libs.nucleus.select';
import { TextArea } from 'libs.nucleus.text_area';
import { SELF_SERVICE_INFO_TYPES } from '../../../constants';
import { StudyConfigurationContext } from '../../../contexts';
const getInitialParticipantGenerationFormat = (metadata) => {
    if (metadata?.subjectIdGeneration === 'Automatic') {
        return 'Automatic';
    }
    return metadata?.subjectIdAsATask ? 'Activity' : 'Manual';
};
export const StudyParticipantDetailsEditModal = ({ initialMetadata, onCancel, onSave, }) => {
    const { currentStudy, isLoading } = useContext(StudyConfigurationContext);
    const translate = useLocalizeMessage();
    const [isPristine, setIsPristine] = useState(true);
    const [studyMetadata, setStudyMetadata] = useState(initialMetadata);
    const [participantIdGenerationFormat, setParticipantIdGenerationFormat] = useState(getInitialParticipantGenerationFormat(initialMetadata));
    const enrollmentStatusOptions = useMemo(() => {
        const statuses = currentStudy
            .getStudyMetadata()
            .participantStatuses.map(({ value, id }) => ({ label: value, value: id }));
        return [{ value: '', label: translate('-select-') }, ...statuses];
    }, []);
    const [selectedEnrollmentStatus, setSelectedEnrollmentStatus] = useState(() => enrollmentStatusOptions.find((status) => status.value === initialMetadata.subjectEnrollmentStatusId) ||
        enrollmentStatusOptions[0]);
    const isEditEnabled = !isLoading && !isPristine;
    const onParticipantNumberFormatChange = useCallback((event) => {
        setStudyMetadata((prevMetadata) => ({ ...prevMetadata, subjectIdFormat: event.target.value }));
        setIsPristine(false);
    }, []);
    const onSupportHeaderChange = useCallback((event) => {
        setStudyMetadata((prevMetadata) => ({
            ...prevMetadata,
            selfServiceInformation: {
                ...prevMetadata.selfServiceInformation,
                [SELF_SERVICE_INFO_TYPES.SUPPORT]: event.target.value,
            },
        }));
        setIsPristine(false);
    }, []);
    const onIsNoPIIEnabledChange = useCallback((isNoPIIEnabled) => {
        setStudyMetadata((prevMetadata) => ({ ...prevMetadata, isNoPIIEnabled }));
        setIsPristine(false);
    }, []);
    const onAuthenticationNameChange = useCallback((useName) => {
        setStudyMetadata((prevMetadata) => ({
            ...prevMetadata,
            authentication: {
                ...prevMetadata.authentication,
                firstName: useName,
                lastName: useName,
            },
        }));
        setIsPristine(false);
    }, []);
    const onAuthenticationPhoneChange = useCallback((usePhoneNumber) => {
        setStudyMetadata((prevMetadata) => ({
            ...prevMetadata,
            authentication: {
                ...prevMetadata.authentication,
                phoneNumber: usePhoneNumber,
            },
        }));
        setIsPristine(false);
    }, []);
    const onParticipantIdGenerationFormatChange = (value) => {
        setParticipantIdGenerationFormat(value);
        setStudyMetadata((prevMetadata) => ({
            ...prevMetadata,
            subjectIdGeneration: value === 'Activity' ? 'Manual' : value,
            subjectIdAsATask: value === 'Activity',
        }));
        setIsPristine(false);
    };
    const onEnrollmentStatusChange = (enrollmentStatus) => {
        setSelectedEnrollmentStatus(enrollmentStatus);
        setStudyMetadata((prevMetadata) => ({
            ...prevMetadata,
            subjectEnrollmentStatusId: enrollmentStatus.value || undefined,
        }));
        setIsPristine(false);
    };
    const savePrimaryButton = {
        label: translate('Save'),
        onClick: () => onSave(studyMetadata),
        disabled: !isEditEnabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const handleSave = (event) => {
        event.preventDefault();
        if (isEditEnabled) {
            onSave(studyMetadata);
        }
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && isEditEnabled) {
            onSave(studyMetadata);
        }
    };
    const formSections = useMemo(() => {
        const registrationMethods = [
            {
                value: false,
                label: translate('Email'),
                description: translate('Participants will register and sign with their email address and be able to reset password on their own'),
            },
            {
                value: true,
                label: translate('Username'),
                description: translate('Participants will register and sign in with a username. Sites will provide app invite codes directly (not via email) and if participants lose their password sites will provide temporary passwords directly(not via email)'),
            },
        ];
        const sections = [
            {
                title: translate('Participant details'),
                content: (_jsxs("section", { className: 'flex flex-col gap-6', children: [_jsxs(RadioGroup, { label: translate('Participant number creation'), value: participantIdGenerationFormat, onChange: onParticipantIdGenerationFormatChange, children: [_jsx(RadioGroupOption, { option: {
                                        value: 'Automatic',
                                        label: translate('Automatic'),
                                        description: translate('Automatically generate a participant number based on the format spec entered below'),
                                    } }), _jsx(RadioGroupOption, { option: {
                                        value: 'Manual',
                                        label: translate('Manual'),
                                        description: translate('Site users will be able to manually enter the participant number upon participant creation'),
                                    } }), _jsx(RadioGroupOption, { option: {
                                        value: 'Activity',
                                        label: translate('Manual - site activity'),
                                        description: translate('Site users will be able to manually enter the participant number as an activity'),
                                    } })] }), _jsx(Input, { id: 'edit-participant-number-format-input', label: translate('Participant number format'), description: translate('Enter the number format you would like your participant numbers to display in'), onChange: onParticipantNumberFormatChange, value: studyMetadata.subjectIdFormat, placeholder: translate('P#####'), required: true }), _jsx(Select, { dataTestId: 'edit-participant-enrollment-status', label: translate('Enrollment status'), description: translate('Select the status that will trigger upon completion of an enrolling consent'), onChange: onEnrollmentStatusChange, options: enrollmentStatusOptions, value: selectedEnrollmentStatus }), _jsx(RadioGroup, { label: translate('Participant registration method'), value: studyMetadata.isNoPIIEnabled, onChange: onIsNoPIIEnabledChange, required: true, children: registrationMethods.map((option) => (_jsx(RadioGroupOption, { option: option }, option.label))) })] })),
            },
        ];
        if (studyMetadata.isNoPIIEnabled) {
            sections.push({
                title: translate('Support information'),
                description: translate('Enabled for participants in the app when Username is selected for registration method above.'),
                content: (_jsx(TextArea, { id: 'edit-support-header-textarea', label: translate('Description'), description: translate('e.g. Please contact your study site team to retrieve your username or reset password'), onChange: onSupportHeaderChange, value: studyMetadata.selfServiceInformation[SELF_SERVICE_INFO_TYPES.SUPPORT], "aria-label": translate('Edit Support Information Textarea'), disabled: !studyMetadata.isNoPIIEnabled, width: 'lg' })),
            });
        }
        else {
            sections.push({
                title: translate('Account creation information'),
                description: translate('Select the fields that should be present when a participant creates their account for the study.'),
                content: (_jsxs("section", { className: 'flex flex-col gap-4', children: [_jsx(Checkbox, { label: translate('First and last name'), checked: studyMetadata.authentication?.firstName || false, onChange: onAuthenticationNameChange }), _jsx(Checkbox, { label: translate('Phone number'), checked: studyMetadata.authentication?.phoneNumber || false, onChange: onAuthenticationPhoneChange })] })),
            });
        }
        return sections;
    }, [studyMetadata]);
    return (_jsx(ModalWindow, { title: translate('Edit details'), isOpen: true, closeWindow: onCancel, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: _jsx("form", { onSubmit: handleSave, autoComplete: 'off', onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) }) }));
};
