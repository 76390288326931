import { AssetTypes } from 'libs.localization.common';
import { AssetRole } from '../types';
export const mapStudyAssetToRole = (asset) => {
    switch (asset) {
        case AssetTypes.ACTIVITY_NAMES:
            return AssetRole.BOTH;
        case AssetTypes.SIGN_IN_SUPPORT_INFORMATION:
        case AssetTypes.SIGN_IN_STUDY_NAME_PII:
        case AssetTypes.SIGN_IN_STUDY_NAME:
        case AssetTypes.RESOURCE_TITLES:
            return AssetRole.PARTICIPANT;
        case AssetTypes.PROTOCOL_SCHEDULE:
        case AssetTypes.PARTICIPANT_STATUSES:
        case AssetTypes.SITE_VISIT_SCHEDULE:
            return AssetRole.SITE;
        default:
            return AssetRole.BOTH;
    }
};
