import { Duration } from 'luxon';
import { useCallback, useContext, useState } from 'react';
import { PlainTime } from 'temporal-luxon';
import { v4 } from 'uuid';
import { DefaultActivityId, DefaultTimelineId } from '@axon/rosetta-sdk';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { sanitizeHtmlString } from 'libs.utils.sanitize';
import { StudyConfigurationContext } from '../../../../contexts';
import { useTimeframes } from '../../../../hooks';
import { getSelectOption } from '../../../../utils';
export var StartingConditionType;
(function (StartingConditionType) {
    StartingConditionType["VISIT_CONFIRMATION"] = "visitConfirmation";
    StartingConditionType["AFTER_ACTIVITY_COMPLETION"] = "afterActivityCompletion";
    StartingConditionType["ACTIVITY_RESPONSE"] = "activityResponse";
    StartingConditionType["DATE_RESPONSE"] = "dateResponse";
    StartingConditionType["STUDY_CREATION"] = "studyCreation";
})(StartingConditionType || (StartingConditionType = {}));
export var EndingConditionType;
(function (EndingConditionType) {
    EndingConditionType["AFTER_ACTIVITY_COMPLETION"] = "afterActivityCompletion";
    EndingConditionType["AFTER_DURATION"] = "afterDuration";
    EndingConditionType["END_OF_DAY"] = "endOfDay";
    EndingConditionType["UPON_COMPLETION"] = "uponCompletion";
    EndingConditionType["ACTIVITY_RESPONSE"] = "activityResponse";
    EndingConditionType["DATE_RESPONSE"] = "dateResponse";
    EndingConditionType["VISIT_CONFIRMATION"] = "visitConfirmation";
    EndingConditionType["ENDLESS"] = "endless";
    EndingConditionType["NUM_OF_OCURRENCES"] = "numOcurrences";
})(EndingConditionType || (EndingConditionType = {}));
export const useActivityDependencyUtils = ({ currentActivity, currentVisit, isRemoteActivity, }) => {
    const translate = useLocalizeMessage();
    const { activities, currentTimelineId, currentStudy, activityScreens } = useContext(StudyConfigurationContext);
    const { dayOptions, periodOptions } = useTimeframes({ emptyValue: '0' });
    const activitySequence = !isRemoteActivity
        ? currentVisit.getActivitySequenceByActivityId(currentActivity.id)
        : undefined;
    const onboardingVisit = currentStudy.getTimelineVisits(DefaultTimelineId.ONBOARDING)[0];
    const allVisitOptions = [
        { label: '-select visit-', value: '', isDisabled: true },
        ...currentStudy.getTimelineVisits(currentTimelineId).reduce((acc, visit) => {
            if (visit.encounterDetails.visitType !== 'standard') {
                return acc;
            }
            acc.push({ label: visit.name, value: visit.id });
            return acc;
        }, []),
    ];
    const onboardingActivities = currentStudy
        .getTimelineVisitActivities(DefaultTimelineId.ONBOARDING)
        .sort((a, b) => a.name.localeCompare(b.name));
    // if it's remote, we pull all the activities from the current timeline
    const visitActivities = !isRemoteActivity
        ? activities
            .filter((activity) => currentVisit.activities[activity.id] && activity.id !== DefaultActivityId.VISIT_CONFIRMATION)
            .sort((a, b) => a.name.localeCompare(b.name))
        : [
            ...onboardingActivities,
            // sort activities but do not filter out remote activities
            ...currentStudy
                .getAllTimelineActivities(currentTimelineId)
                .filter((activity) => activity.id !== DefaultActivityId.VISIT_CONFIRMATION)
                .sort((a, b) => a.name.localeCompare(b.name)),
        ];
    const isOnboarding = currentTimelineId === DefaultTimelineId.ONBOARDING;
    const allInstanceActivities = isOnboarding || isRemoteActivity ? visitActivities : [...onboardingActivities, ...visitActivities];
    const onboardingInstanceIds = !isRemoteActivity
        ? onboardingActivities.reduce((acc, activity) => {
            acc[activity.id] = onboardingVisit.getActivitySequenceByActivityId(activity.id)?.instanceId ?? '';
            return acc;
        }, {})
        : {};
    const visitInstanceIds = !isRemoteActivity
        ? visitActivities.reduce((acc, activity) => {
            acc[activity.id] = currentVisit.getActivitySequenceByActivityId(activity.id)?.instanceId ?? '';
            return acc;
        }, {})
        : {};
    const visitConfirmationSequence = !isRemoteActivity
        ? currentVisit.getActivitySequenceByActivityId(DefaultActivityId.VISIT_CONFIRMATION)
        : undefined;
    const visitConfirmationOption = !isRemoteActivity
        ? visitConfirmationSequence && {
            label: (currentVisit && currentVisit.activities[visitConfirmationSequence.activityId].name) ?? '',
            value: visitConfirmationSequence.instanceId,
            meta: { activityId: DefaultActivityId.VISIT_CONFIRMATION },
        }
        : {
            label: currentStudy.getActivityById(DefaultActivityId.VISIT_CONFIRMATION)?.name ?? '',
            value: DefaultActivityId.VISIT_CONFIRMATION,
            meta: { activityId: DefaultActivityId.VISIT_CONFIRMATION },
        };
    const getActivityIdToUse = (activity, options) => {
        if (isRemoteActivity) {
            return { id: activity.id, activityLabel: activity.name };
        }
        if (onboardingInstanceIds[activity.id] &&
            !options.find((option) => option.value === onboardingInstanceIds[activity.id])) {
            return {
                id: onboardingInstanceIds[activity.id],
                activityLabel: !isOnboarding ? `${activity.name} (Onboarding)` : activity.name,
            };
        }
        return { id: visitInstanceIds[activity.id] || activity.id, activityLabel: activity.name };
    };
    const getActivityResponseOptions = useCallback((screenType) => {
        const options = {
            activities: [{ label: translate('-select activity-'), value: '', isDisabled: true }],
            screenOptions: {
                '': {
                    screens: [{ label: translate('-select screen-'), value: '', isDisabled: true }],
                    responses: { '': [{ label: translate('-select response-'), value: '', isDisabled: true }] },
                },
            },
        };
        for (const activity of allInstanceActivities) {
            // When we reach the current activity, we stop adding activities on sequential order but skip the activity in concurrent order
            if (!isRemoteActivity && activity.id === currentActivity.id) {
                if (currentVisit.encounterDetails.sequentialActivityOrder) {
                    break;
                }
                else {
                    continue;
                }
            }
            const { id, activityLabel } = getActivityIdToUse(activity, options.activities);
            const screens = activityScreens[activity.details.libraryId ?? '']?.filter((screen) => screen.type === screenType) ?? [];
            // only add activity choice if it has associated screens
            if (screens.length === 0) {
                continue;
            }
            if (!options.activities.find((option) => option.value === id)) {
                options.activities.push({ label: activityLabel, value: id, meta: { activityId: activity.id } });
                options.screenOptions[id] = {
                    screens: [{ label: translate('-select screen-'), value: '', isDisabled: true }],
                    responses: { '': [{ label: translate('-select response-'), value: '', isDisabled: true }] },
                };
                for (const screen of screens) {
                    options.screenOptions[id].screens.push({ label: screen.name, value: screen.key });
                    options.screenOptions[id].responses[screen.key] = [
                        { label: translate('-select response-'), value: '', isDisabled: true },
                    ];
                    // only need responses for vrs screens
                    if (screenType === 'vrs') {
                        for (const response of screen.choices) {
                            options.screenOptions[id].responses[screen.key].push({
                                label: sanitizeHtmlString(response.display),
                                value: response.value,
                            });
                        }
                    }
                }
            }
        }
        // sort the options alphabetically
        for (const key in options.screenOptions) {
            options.screenOptions[key].screens.sort((a, b) => a.label.localeCompare(b.label));
            for (const screenKey in options.screenOptions[key].responses) {
                options.screenOptions[key].responses[screenKey].sort((a, b) => a.label.localeCompare(b.label));
            }
        }
        return options;
    }, []);
    const getActivityCompletionOptions = useCallback(() => {
        const options = [];
        for (const activity of allInstanceActivities) {
            // When we reach the current activity, we stop adding activities on sequential order but skip the activity in concurrent order
            if (activity.id === (isRemoteActivity ? currentActivity?.activityId : currentActivity.id)) {
                if (!isRemoteActivity && currentVisit.encounterDetails.sequentialActivityOrder) {
                    break;
                }
                else {
                    continue;
                }
            }
            const { id, activityLabel } = getActivityIdToUse(activity, options);
            options.push({ label: activityLabel, value: id, meta: { activityId: activity.id } });
        }
        return [
            {
                label: translate('-select activity-'),
                value: '',
                isDisabled: true,
            },
            ...options.filter((option, index, self) => {
                return option && self.findIndex((o) => o.value === option.value) === index;
            }),
        ];
    }, []);
    const activityCompletionOptions = getActivityCompletionOptions();
    const dateTimeResponseOptions = getActivityResponseOptions('date_time');
    const vrsResponseOptions = getActivityResponseOptions('vrs');
    const startingConditionOptions = {
        visitConfirmation: { label: translate('Visit Confirmation'), value: StartingConditionType.VISIT_CONFIRMATION },
        studyCreation: { label: translate('Study creation'), value: StartingConditionType.STUDY_CREATION },
        afterActivityCompletion: {
            label: translate('After completion of another activity'),
            value: StartingConditionType.AFTER_ACTIVITY_COMPLETION,
        },
        activityResponse: {
            label: translate("After another activity's response"),
            value: StartingConditionType.ACTIVITY_RESPONSE,
        },
        dateResponse: { label: translate('After date/time response'), value: StartingConditionType.DATE_RESPONSE },
    };
    const endingConditionOptions = {
        numOcurrences: { label: translate('# of occurences'), value: EndingConditionType.NUM_OF_OCURRENCES },
        visitConfirmation: {
            label: translate('Visit Confirmation'),
            value: EndingConditionType.VISIT_CONFIRMATION,
        },
        afterDuration: { label: translate('After duration'), value: EndingConditionType.AFTER_DURATION },
        afterActivityCompletion: {
            label: translate('After completion of another activity'),
            value: EndingConditionType.AFTER_ACTIVITY_COMPLETION,
        },
        endOfDay: { label: translate('End of day (11:59 PM)'), value: EndingConditionType.END_OF_DAY },
        uponCompletion: { label: translate('Upon completion'), value: EndingConditionType.UPON_COMPLETION },
        activityResponse: {
            label: translate("Based on another activity's response"),
            value: EndingConditionType.ACTIVITY_RESPONSE,
        },
        dateResponse: { label: translate('After date/time response'), value: EndingConditionType.DATE_RESPONSE },
        endless: { label: translate('Endless'), value: EndingConditionType.ENDLESS },
    };
    const [specificStartTime, setSpecificStartTime] = useState(() => {
        const timing = isRemoteActivity ? currentActivity?.timing : activitySequence?.timing;
        if (timing?.timingDetails?.specificTime) {
            return { time: PlainTime.from(Duration.fromISO(timing.timingDetails.specificTime).toObject()) };
        }
        return { time: null };
    });
    const [repetition, setRepetition] = useState(() => {
        if (!isRemoteActivity || !currentActivity?.timing.recurrenceRule) {
            return { repetitionEveryPeriod: periodOptions[1] };
        }
        return {
            repetitionEvery: currentActivity.timing.recurrenceRule.interval
                ? currentActivity.timing.recurrenceRule.interval.toString()
                : '',
            repetitionEveryPeriod: getSelectOption(periodOptions, currentActivity.timing.recurrenceRule.frequency),
            count: currentActivity.timing.recurrenceRule.count
                ? currentActivity.timing.recurrenceRule.count.toString()
                : undefined,
        };
    });
    const recurrenceEndingOptions = [
        { label: translate('# of occurences'), value: EndingConditionType.NUM_OF_OCURRENCES },
        { label: translate('Endless (end of study)'), value: EndingConditionType.ENDLESS },
        { label: translate('Completion of another activity'), value: EndingConditionType.AFTER_ACTIVITY_COMPLETION },
        { label: translate('Visit confirmation'), value: EndingConditionType.VISIT_CONFIRMATION },
        { label: translate('Activity response'), value: EndingConditionType.ACTIVITY_RESPONSE },
    ];
    // If the activity has no start trigger, we want to default it to the visit's confirmation activity
    const [startingConditions, setStartingConditions] = useState(() => {
        const options = [];
        const timing = isRemoteActivity ? currentActivity?.timing : activitySequence?.timing;
        const offset = getSelectOption(dayOptions, String(timing?.relativeFromOffset || 0));
        if (timing) {
            timing.startConditions?.forEach((condition) => {
                const idToCheck = isRemoteActivity ? condition.dependencyId : condition.dependencyInstanceId;
                switch (condition.triggerType) {
                    case 'activity_completion':
                        // Activity completion can be either Visit confirmation, which we treat as its own option,
                        // Or any other activity completion, which we treat as a separate option
                        if (idToCheck === visitConfirmationOption?.value) {
                            // we need to check if visit confirmation doesnt already exist in the list of starting conditions
                            if (!options.find((item) => item.option.value === StartingConditionType.VISIT_CONFIRMATION)) {
                                options.push({
                                    id: condition.id || v4(),
                                    option: startingConditionOptions.visitConfirmation,
                                    activityCompletion: visitConfirmationOption,
                                    offset: getSelectOption(dayOptions, String(condition.offsetAfterDateAnchor || 0)),
                                    type: StartingConditionType.VISIT_CONFIRMATION,
                                    visitId: getSelectOption(allVisitOptions, condition.encounterId),
                                });
                            }
                        }
                        else {
                            // if it's not a visit confirmation, we still need to check if the completion condition hasnt already been added
                            if (!options.find((item) => item.option.value === StartingConditionType.AFTER_ACTIVITY_COMPLETION &&
                                item.activityCompletion?.value === idToCheck)) {
                                options.push({
                                    id: condition.id || v4(),
                                    option: startingConditionOptions.afterActivityCompletion,
                                    activityCompletion: getSelectOption(activityCompletionOptions, idToCheck),
                                    offset: getSelectOption(dayOptions, String(condition.offsetAfterDateAnchor || 0)),
                                    type: StartingConditionType.AFTER_ACTIVITY_COMPLETION,
                                });
                            }
                        }
                        break;
                    case 'screen_response': {
                        // If the screen response condition has no dependencyInstanceId set, we skip adding the condtion as it is invalid
                        if (!idToCheck) {
                            break;
                        }
                        const screen = vrsResponseOptions.screenOptions[idToCheck]?.screens.find((s) => s.value === condition.triggerScreenKey);
                        const response = screen &&
                            vrsResponseOptions.screenOptions[idToCheck]?.responses[screen.value]?.find((r) => r.value === condition.comparisonValue);
                        // if the screen or response doesnt exist, we skip adding the condition. This can happen if the activtiy, screen or response is deleted
                        if (!screen || !response) {
                            break;
                        }
                        // check that this response hasnt already been added
                        if (!options.find((item) => item.option.value === StartingConditionType.ACTIVITY_RESPONSE &&
                            item.activityResponse.activity.value === idToCheck &&
                            item.activityResponse.screen.value === screen.value &&
                            item.activityResponse.response.value === response.value)) {
                            options.push({
                                id: condition.id || v4(),
                                option: startingConditionOptions.activityResponse,
                                activityResponse: {
                                    activity: vrsResponseOptions.activities.find((a) => a.value === idToCheck),
                                    screen,
                                    response,
                                },
                                type: StartingConditionType.ACTIVITY_RESPONSE,
                            });
                        }
                        break;
                    }
                    case 'date_screen_response': {
                        // If the date entry condition has no dependencyInstanceId set, we skip adding the condtion as it is invalid
                        if (!idToCheck) {
                            break;
                        }
                        const screen = dateTimeResponseOptions.screenOptions[idToCheck]?.screens.find((s) => s.value === condition.triggerScreenKey);
                        // if the screen doesnt exist, we skip adding the condition. This can happen if the activtiy or screen is deleted
                        if (!screen) {
                            break;
                        }
                        // check that this response hasnt already been added
                        if (!options.find((item) => item.option.value === StartingConditionType.DATE_RESPONSE &&
                            item.activityResponse.activity.value === idToCheck &&
                            item.activityResponse.screen.value === screen.value)) {
                            options.push({
                                id: condition.id || v4(),
                                option: startingConditionOptions.dateResponse,
                                activityResponse: {
                                    activity: dateTimeResponseOptions.activities.find((a) => a.value === idToCheck),
                                    screen,
                                    // response doesnt matter for date responses and so it gets set to default
                                    response: dateTimeResponseOptions.screenOptions[''].responses[''][0],
                                },
                                offset: getSelectOption(dayOptions, String(condition.offsetAfterDateAnchor || 0)),
                                type: StartingConditionType.DATE_RESPONSE,
                            });
                        }
                        break;
                    }
                    case 'study_creation':
                        // we need to check if study creation doesnt already exist in the list of starting conditions
                        if (!options.find((item) => item.option.value === StartingConditionType.STUDY_CREATION)) {
                            options.push({
                                id: condition.id || v4(),
                                option: startingConditionOptions.studyCreation,
                                offset: getSelectOption(dayOptions, String(condition.offsetAfterDateAnchor || 0)),
                                type: StartingConditionType.STUDY_CREATION,
                            });
                        }
                        break;
                }
            });
        }
        // if no conditions were found at all, we still need to initialize a condition
        // if no visit confirmation is available, eg for an onboarding activity, we default to the first activity completion
        if (options.length === 0) {
            options.push(visitConfirmationOption
                ? {
                    id: v4(),
                    option: startingConditionOptions.visitConfirmation,
                    activityCompletion: visitConfirmationOption,
                    offset,
                    type: StartingConditionType.VISIT_CONFIRMATION,
                }
                : {
                    id: v4(),
                    option: startingConditionOptions.studyCreation,
                    offset,
                    type: StartingConditionType.STUDY_CREATION,
                });
        }
        return options;
    });
    const [endingConditions, setEndingConditions] = useState(() => {
        const options = [];
        const timing = isRemoteActivity ? currentActivity?.timing : activitySequence?.timing;
        const instanceId = isRemoteActivity ? currentActivity?.activityId : activitySequence?.instanceId;
        if (timing) {
            timing.endConditions?.forEach((condition) => {
                const idToCheck = isRemoteActivity ? condition.dependencyId : condition.dependencyInstanceId;
                switch (condition.triggerType) {
                    case 'activity_completion':
                        // if the dependency instance id is the same as the activity instance itself, we treat it as an upon completion condition
                        if (instanceId === idToCheck) {
                            // we need to check if upon completion doesnt already exist in the list of ending conditions
                            if (!options.find((item) => item.option.value === EndingConditionType.UPON_COMPLETION)) {
                                options.push({
                                    id: condition.id || v4(),
                                    option: endingConditionOptions.uponCompletion,
                                    type: EndingConditionType.UPON_COMPLETION,
                                });
                            }
                        }
                        else if (idToCheck === visitConfirmationOption?.value) {
                            // Activity completion can be Visit confirmation, which we treat as its own option
                            // we need to check if visit confirmation doesnt already exist in the list of starting conditions
                            if (!options.find((item) => item.option.value === EndingConditionType.VISIT_CONFIRMATION)) {
                                options.push({
                                    id: condition.id || v4(),
                                    option: endingConditionOptions.visitConfirmation,
                                    activityCompletion: visitConfirmationOption,
                                    offset: getSelectOption(dayOptions, String(condition.offsetAfterDateAnchor || 0)),
                                    type: EndingConditionType.VISIT_CONFIRMATION,
                                    visitId: getSelectOption(allVisitOptions, condition.encounterId),
                                });
                            }
                        }
                        else {
                            // check that this completion condition hasnt already been added
                            if (!options.find((item) => item.option.value === EndingConditionType.AFTER_ACTIVITY_COMPLETION &&
                                item.activityCompletion?.value === idToCheck)) {
                                options.push({
                                    id: condition.id || v4(),
                                    option: endingConditionOptions.afterActivityCompletion,
                                    activityCompletion: getSelectOption(activityCompletionOptions, idToCheck),
                                    offset: getSelectOption(dayOptions, String(condition.offsetAfterDateAnchor || 0)),
                                    type: EndingConditionType.AFTER_ACTIVITY_COMPLETION,
                                });
                            }
                        }
                        break;
                    case 'duration': {
                        const duration = condition.windowDuration ? Duration.fromISO(condition.windowDuration).toObject() : {};
                        // check that we havent already added a duration condition
                        if (!options.find((item) => item.option.value === EndingConditionType.AFTER_DURATION &&
                            item.duration.value === String(Object.values(duration)[0]) &&
                            item.durationPeriod.value === Object.keys(duration)[0])) {
                            options.push({
                                id: condition.id || v4(),
                                option: endingConditionOptions.afterDuration,
                                duration: getSelectOption(dayOptions, String(Object.values(duration)[0])),
                                durationPeriod: getSelectOption(periodOptions, Object.keys(duration)[0]),
                                type: EndingConditionType.AFTER_DURATION,
                            });
                        }
                        break;
                    }
                    case 'screen_response': {
                        // If the screen response condition has no dependencyInstanceId set, we skip adding the condtion as it is invalid
                        if (!idToCheck) {
                            break;
                        }
                        const screen = vrsResponseOptions.screenOptions[idToCheck]?.screens.find((s) => s.value === condition.triggerScreenKey);
                        const response = screen &&
                            vrsResponseOptions.screenOptions[idToCheck]?.responses[screen.value]?.find((r) => r.value === condition.comparisonValue);
                        // if the screen or response doesnt exist, we skip adding the condition. This can happen if the activtiy, screen or response is deleted
                        if (!screen || !response) {
                            break;
                        }
                        // check that this response hasnt already been added
                        if (!options.find((item) => item.option.value === EndingConditionType.ACTIVITY_RESPONSE &&
                            item.activityResponse.activity.value === idToCheck &&
                            item.activityResponse.screen.value === screen.value &&
                            item.activityResponse.response.value === response.value)) {
                            options.push({
                                id: condition.id || v4(),
                                option: endingConditionOptions.activityResponse,
                                activityResponse: {
                                    activity: vrsResponseOptions.activities.find((a) => a.value === idToCheck),
                                    screen,
                                    response,
                                },
                                type: EndingConditionType.ACTIVITY_RESPONSE,
                            });
                        }
                        break;
                    }
                    case 'date_screen_response': {
                        // If the date entry condition has no dependencyInstanceId set, we skip adding the condtion as it is invalid
                        if (!idToCheck) {
                            break;
                        }
                        const screen = dateTimeResponseOptions.screenOptions[idToCheck]?.screens.find((s) => s.value === condition.triggerScreenKey);
                        // if the screen doesnt exist, we skip adding the condition. This can happen if the activtiy or screen is deleted
                        if (!screen) {
                            break;
                        }
                        // check that this response hasnt already been added
                        if (!options.find((item) => item.option.value === EndingConditionType.DATE_RESPONSE &&
                            item.activityResponse.activity.value === idToCheck &&
                            item.activityResponse.screen.value === screen.value)) {
                            options.push({
                                id: condition.id || v4(),
                                option: endingConditionOptions.dateResponse,
                                activityResponse: {
                                    activity: dateTimeResponseOptions.activities.find((a) => a.value === idToCheck),
                                    screen,
                                    // response doesnt matter for date responses and so it gets set to default
                                    response: dateTimeResponseOptions.screenOptions[''].responses[''][0],
                                },
                                offset: getSelectOption(dayOptions, String(condition.offsetAfterDateAnchor || 0)),
                                type: EndingConditionType.DATE_RESPONSE,
                            });
                        }
                        break;
                    }
                    case 'end_of_day':
                        // check that this condition hasnt already been added
                        if (!options.find((item) => item.type === EndingConditionType.END_OF_DAY)) {
                            options.push({
                                id: condition.id || v4(),
                                option: endingConditionOptions.endOfDay,
                                type: EndingConditionType.END_OF_DAY,
                            });
                        }
                        break;
                }
            });
        }
        // If no options were added, we default to the end of day condition
        return options.length > 0
            ? options
            : [
                !isRemoteActivity
                    ? { id: v4(), option: endingConditionOptions.endOfDay, type: EndingConditionType.END_OF_DAY }
                    : currentActivity
                        ? {
                            id: v4(),
                            option: endingConditionOptions.endless,
                            type: EndingConditionType.ENDLESS,
                        }
                        : {
                            id: v4(),
                            option: endingConditionOptions.visitConfirmation,
                            type: EndingConditionType.VISIT_CONFIRMATION,
                            activityCompletion: visitConfirmationOption,
                            offset: dayOptions[0],
                        },
            ];
    });
    const resetConditions = () => {
        setStartingConditions([
            visitConfirmationOption
                ? {
                    id: v4(),
                    option: startingConditionOptions.visitConfirmation,
                    activityCompletion: visitConfirmationOption,
                    offset: dayOptions[0],
                    type: StartingConditionType.VISIT_CONFIRMATION,
                }
                : {
                    id: v4(),
                    option: startingConditionOptions.studyCreation,
                    offset: dayOptions[0],
                    type: StartingConditionType.STUDY_CREATION,
                },
        ]);
        setEndingConditions([
            !isRemoteActivity
                ? { id: v4(), option: endingConditionOptions.endOfDay, type: EndingConditionType.END_OF_DAY }
                : currentActivity
                    ? {
                        id: v4(),
                        option: endingConditionOptions.endless,
                        type: EndingConditionType.ENDLESS,
                    }
                    : {
                        id: v4(),
                        option: endingConditionOptions.visitConfirmation,
                        type: EndingConditionType.VISIT_CONFIRMATION,
                        activityCompletion: visitConfirmationOption,
                        offset: dayOptions[0],
                    },
        ]);
    };
    return {
        startingConditions,
        endingConditions,
        setStartingConditions,
        setEndingConditions,
        startingConditionOptions,
        endingConditionOptions,
        onboardingVisit,
        visitConfirmationOption,
        getActivityResponseOptions,
        activityCompletionOptions,
        dateTimeResponseOptions,
        vrsResponseOptions,
        dayOptions,
        periodOptions,
        allVisitOptions,
        specificStartTime,
        setSpecificStartTime,
        repetition,
        setRepetition,
        resetConditions,
        recurrenceEndingOptions,
    };
};
