import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EM_DASH } from 'libs.constants';
import { Text } from 'libs.nucleus.typography';
export const ListInformation = ({ dataTestId = 'list-information', items, descriptionColor = 'primary', useDefaultEmptyDescription = true, }) => {
    /**
     * Render the description content
     * If the description is a string or an array with only one item, it will be rendered as a text
     * If the description is a multi-option array, it will be rendered as a list
     * @param description
     */
    const descriptionContent = (description) => {
        if (Array.isArray(description) && description.length > 1) {
            return (_jsx("ul", { className: 'list-disc pl-3 ml-2', children: description.map((desc) => (_jsx("li", { children: _jsx(Text, { size: 'sm', className: 'text-text-primary', children: desc }) }, desc))) }));
        }
        return (_jsx(Text, { size: 'sm', className: `${descriptionColor === 'primary' ? 'text-text-primary' : 'text-secondary'}`, children: (typeof description === 'string' ? description : description[0]) ||
                (useDefaultEmptyDescription ? EM_DASH : '') }));
    };
    return (_jsx("ul", { className: 'm-0 p-0', "data-testid": dataTestId, children: items.map((item) => (!item.hideIfEmpty || !!item.description) && (_jsxs("li", { className: 'flex items-start border-t border-neutral-100 px-4 py-6 odd:bg-neutral-50 gap-2', "data-testid": item.dataTestId, children: [_jsxs("div", { className: 'flex flex-col flex-1 basis-3/12', children: [_jsx(Text, { size: 'sm', className: 'font-medium text-text-primary', children: item.title }), item.subtitle && (_jsx(Text, { size: 'xs', className: 'text-text-secondary', children: item.subtitle }))] }), _jsx("div", { className: 'flex-1 basis-8/12', children: typeof item.description === 'string' || Array.isArray(item.description)
                        ? descriptionContent(item.description)
                        : item.description || descriptionContent('') }), item.actionContent && (_jsx("div", { className: 'flex flex-1 basis-1/12 justify-end', children: _jsx("div", { children: item.actionContent }) }))] }, `${item.title}-${item.subtitle || ''}}`))) }));
};
