import { faker } from '@faker-js/faker';
import { PRODUCT_PACKAGES_MAP, PRODUCT_VERSIONS_MAP, ProductTemplate, SDKStudy } from '@axon/rosetta-sdk';
export const generateRandomOrgName = (environment = 'int-dev') => {
    const adjective = faker.word.adjective({ length: { min: 3, max: 8 } });
    const adverb = faker.word.adverb({ length: { min: 3, max: 8 } });
    const noun = faker.word.noun({ length: { min: 3, max: 8 } });
    return `${environment}.${adjective}-${adverb}-${noun}`;
};
export const sanitizeStudy = (study) => {
    const rosettaStudy = typeof study === 'string' ? SDKStudy.fromRosetta(JSON.parse(study)) : SDKStudy.fromRosetta(study);
    // TODO: We set the default versions for all studies, this need to be moved later on to a more dynamic place
    rosettaStudy.setProductTemplate(ProductTemplate.r310);
    rosettaStudy.setProductPackages(PRODUCT_PACKAGES_MAP.r310);
    rosettaStudy.setProductVersions(PRODUCT_VERSIONS_MAP.r310);
    return rosettaStudy;
};
