import { jsx as _jsx } from "react/jsx-runtime";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useState } from 'react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { useImagesContext } from '../contexts/images_context';
import { INSERT_IMAGE_COMMAND } from '../plugins/images_plugin';
export const ImageUploadModal = ({ isOpen, onClose, onUpload, uploader, canceled }) => {
    const [editor] = useLexicalComposerContext();
    const [imageUrl, setImageUrl] = useState('');
    const { images } = useImagesContext();
    const t = useLocalizeMessage();
    const onSave = () => {
        const payload = {
            src: imageUrl,
            alt: '',
            title: '',
        };
        editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
        onUpload && onUpload(imageUrl);
        setImageUrl('');
        onClose();
    };
    const onDelete = () => setImageUrl('');
    const onCancel = () => {
        setImageUrl('');
        onClose(true);
    };
    const savePrimaryButton = {
        label: t('Import'),
        onClick: onSave,
        disabled: !imageUrl,
    };
    const cancelSecondaryButton = {
        label: t('Cancel'),
        onClick: onCancel,
    };
    return (_jsx(ModalWindow, { title: t('Insert image'), isOpen: isOpen, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], closeWindow: onCancel, width: 'sm', dataTestId: 'upload-image-modal', children: uploader({ onSuccess: setImageUrl, onDelete, canceled, hasImages: !!Object.keys(images).length }) }));
};
