import { mapStudyAssetToRole } from './data_utils';
import { getDeviceList } from './device_list';
import { AssetRole } from '../types';
export function getDevicelistForAssetType(assetType) {
    const role = mapStudyAssetToRole(assetType);
    if (role === AssetRole.SITE) {
        return getDeviceList('large');
    }
    return getDeviceList();
}
