import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { DEPLOYMENT_STATUS } from '../../constants';
export const DeploymentContext = createContext({
    jobs: {},
    getStudyJobs: () => ({ latestJob: '', deployedOrgCode: '' }),
    addJob: () => undefined,
    getJobStatus: () => '',
    updateJobStatus: () => undefined,
});
export const DeploymentProvider = ({ children }) => {
    const deploymentJobs = localStorage.getItem('deploymentJobs');
    const [jobs, setJobs] = useState(deploymentJobs ? JSON.parse(deploymentJobs) : {});
    useEffect(() => {
        localStorage.setItem('deploymentJobs', JSON.stringify(jobs));
    }, [jobs]);
    const getStudyJobs = useCallback((studyId) => {
        return jobs[studyId];
    }, [jobs]);
    const addJob = useCallback(({ jobId, studyId }) => {
        setJobs((prevJobs) => ({
            ...prevJobs,
            [studyId]: { ...prevJobs[studyId], [jobId]: DEPLOYMENT_STATUS.CREATED, latestJob: jobId },
        }));
    }, [jobs]);
    const getJobStatus = useCallback(({ jobId, studyId }) => jobs[studyId][jobId], [jobs]);
    const updateJobStatus = useCallback(({ jobId, studyId, status }) => {
        setJobs((prevJobs) => ({
            ...prevJobs,
            [studyId]: { ...prevJobs[studyId], [jobId]: status },
        }));
    }, [jobs]);
    const jobsObj = useMemo(() => ({
        jobs,
        getStudyJobs,
        addJob,
        getJobStatus,
        updateJobStatus,
    }), [jobs]);
    return _jsx(DeploymentContext.Provider, { value: jobsObj, children: children });
};
