import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { SelectLocaleTypeStep } from 'libs.react.components';
export const EditLocaleModal = ({ onClose, onSave, initialLocaleType }) => {
    const translate = useLocalizeMessage();
    const [localeType, setLocaleType] = useState(initialLocaleType);
    const [isPristine, setIsPristine] = useState(true);
    const cancelButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const doneButton = {
        disabled: isPristine || initialLocaleType === localeType,
        label: translate('Save'),
        onClick: () => onSave(localeType),
    };
    const handleLocaleTypeChange = (localeType) => {
        setLocaleType(localeType);
        setIsPristine(false);
    };
    return (_jsx(ModalWindow, { title: translate('Edit locale'), isOpen: true, closeWindow: onClose, footerSecondaryActionButtons: [cancelButton], footerPrimaryActionButton: doneButton, width: 'lg', children: _jsx("section", { className: 'min-h-[30rem]', children: _jsx(SelectLocaleTypeStep, { localeType: localeType, onLocaleTypeChange: handleLocaleTypeChange }) }) }));
};
