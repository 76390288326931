export const deviceList = {
    iosSmall: {
        device: 'iOS small',
        width: 375,
        height: 667,
        scale: 1,
        hasLandscape: false,
    },
    iosLarge: {
        device: 'iOS large',
        width: 375,
        height: 812,
        scale: 1,
        hasLandscape: false,
    },
    androidSmall: {
        device: 'Android Small',
        width: 360,
        height: 800,
        scale: 1,
        hasLandscape: false,
    },
    androidLarge: {
        device: 'Android Large',
        width: 412,
        height: 915,
        scale: 1,
        hasLandscape: false,
    },
    iPadPortrait: {
        device: 'iPad(Portrait)',
        width: 810,
        height: 1010,
        scale: 0.5,
        hasLandscape: false,
    },
    iPadLandscape: {
        device: 'iPad(Landscape)',
        width: 1010,
        height: 810,
        scale: 0.5,
        hasLandscape: true,
    },
    desktopSmall: {
        device: 'Desktop Small',
        width: 1366,
        height: 768,
        scale: 0.5,
        hasLandscape: false,
    },
    desktopLarge: {
        device: 'Desktop Large',
        width: 1920,
        height: 1080,
        scale: 0.5,
        hasLandscape: false,
    },
};
export function getDeviceList(type = 'all') {
    const devices = {};
    if (type === 'all') {
        return deviceList;
    }
    for (const key in deviceList) {
        const device = deviceList[key];
        if (type === 'mobile' &&
            (device.device.toLowerCase().includes('ios') || device.device.toLowerCase().includes('android'))) {
            devices[key] = device;
        }
        else if (type === 'large' &&
            (device.device.toLowerCase().includes('desktop') || device.device.toLowerCase().includes('ipad'))) {
            devices[key] = device;
        }
    }
    return devices;
}
