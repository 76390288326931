import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from 'libs.react.components';
import { SitesWrapper } from './components/sites_wrapper';
// import {
//   ChatContextProvider,
//   DeploymentProvider,
//   StudyConfigurationProvider,
//   StudiesProvider,
//   VariablesProvider,
// } from './contexts-back';
const StudiesDashboardPage = lazy(() => import('./pages/studies_dashboard/studies_dashboard.page'));
const SitesHome = lazy(() => import('./pages/sites_home/sites_home.page'));
const ProvidersWrapper = ({ children }) => {
    return children;
};
export const SiteManagementApp = () => {
    return (_jsx(ProvidersWrapper, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, path: 'dashboard', element: _jsx(StudiesDashboardPage, {}) }), _jsxs(Route, { element: _jsx(SitesWrapper, {}), children: [_jsx(Route, { index: true, path: 'home', element: _jsx(SitesHome, {}) }), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, {}) })] })] }) }));
};
export { SITE_MANAGEMENT_FLAGS } from './constants/developer_flags.constants';
