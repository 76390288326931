import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { $isLinkNode } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { SELECTION_CHANGE_COMMAND, $getSelection, $createTextNode } from 'lexical';
import { useEffect, useState, useCallback } from 'react';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Icon } from 'libs.nucleus.icon';
import { useBooleanState } from 'libs.react.hooks';
import { FormatLinkModal } from './format_link_modal';
import { FormatterWrapper } from './formatter_wrapper';
import { useLinkTargetContext } from '../contexts/link_target_context';
import { CustomLinkNode } from '../nodes';
const LowPriority = 1;
const isLinkSelected = (selectionNodes) => {
    if (selectionNodes.length === 1) {
        // lexical only returns the innermost text node for a link, so we need to manually get the parent node
        const node = selectionNodes[0];
        const parentNode = node.getParent();
        const parentNodeTyped = (parentNode === null || parentNode === void 0 ? void 0 : parentNode.getType()) === 'custom_link'
            ? parentNode
            : (parentNode === null || parentNode === void 0 ? void 0 : parentNode.getType()) === 'custom_auto_link'
                ? parentNode
                : null;
        return { node, parentNode: parentNodeTyped, isLink: $isLinkNode(parentNode) };
    }
    return { node: null, parentNode: null, isLink: false };
};
export const FormatLink = () => {
    const [editor] = useLexicalComposerContext();
    const [isLink, setIsLink] = useState(false);
    const [linkInfo, setLinkInfo] = useState({ url: '', text: '' });
    const translate = useLocalizeMessage();
    const [isFormatLinkModalOpen, openFormatLinkModal, closeFormatLinkModal] = useBooleanState(false);
    const { retainTarget } = useLinkTargetContext();
    const updateToolbar = useCallback(() => {
        editor.getEditorState().read(() => {
            const selection = $getSelection();
            if (selection) {
                const selectionNodes = selection.getNodes();
                setIsLink(isLinkSelected(selectionNodes).isLink);
            }
            else {
                setIsLink(false);
            }
        });
    }, []);
    useEffect(() => {
        return mergeRegister(editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                updateToolbar();
            });
        }), editor.registerCommand(SELECTION_CHANGE_COMMAND, () => {
            updateToolbar();
            return false;
        }, LowPriority));
    }, [editor]);
    const onClick = () => (event) => {
        event.preventDefault();
        editor.getEditorState().read(() => {
            var _a, _b, _c;
            const selection = $getSelection();
            if (selection) {
                const selectionNodes = selection.getNodes();
                const nodeInfo = isLinkSelected(selectionNodes);
                setIsLink(nodeInfo.isLink);
                let editLinkInfo = { url: '', text: '' };
                if (nodeInfo.isLink) {
                    editLinkInfo = {
                        url: ((_a = nodeInfo.parentNode) === null || _a === void 0 ? void 0 : _a.getURL()) || '',
                        text: ((_b = nodeInfo.node) === null || _b === void 0 ? void 0 : _b.getTextContent()) || '',
                    };
                }
                else {
                    editLinkInfo = {
                        url: '',
                        text: ((_c = window.getSelection()) === null || _c === void 0 ? void 0 : _c.toString()) || '',
                    };
                }
                setLinkInfo(editLinkInfo);
            }
            openFormatLinkModal();
        });
    };
    const handleOnSave = (updatedlink) => {
        editor.update(() => {
            const selection = $getSelection();
            const selectionNodes = (selection === null || selection === void 0 ? void 0 : selection.getNodes()) || [];
            if (selection) {
                const nodeInfo = isLinkSelected(selectionNodes);
                const linkNode = new CustomLinkNode(updatedlink.url, retainTarget);
                const textNode = $createTextNode(updatedlink.text || updatedlink.url);
                linkNode.append(textNode);
                if (nodeInfo.isLink) {
                    const parentLinkNode = nodeInfo.parentNode;
                    parentLinkNode.insertAfter(linkNode);
                    parentLinkNode.remove();
                }
                else {
                    if (selectionNodes.length === 1) {
                        const node = selectionNodes[0];
                        if (node.getType() === 'text' || node.getType() === 'custom_text') {
                            const selectedTextPoints = selection.getStartEndPoints() || [];
                            if (selectedTextPoints.length > 0) {
                                // if the offsets are the same, we don't want to split the text node, just apply the link to the whole text
                                if (selectedTextPoints[0].offset === selectedTextPoints[1].offset) {
                                    node.insertAfter(linkNode);
                                    node.remove();
                                }
                                else {
                                    // the selected text will be the second value in the split text array,
                                    // unless whole text is selected, then itll only have one value
                                    const splitTextNodes = node.splitText(selectedTextPoints[0].offset, selectedTextPoints[1].offset);
                                    if (splitTextNodes.length === 1) {
                                        splitTextNodes[0].insertAfter(linkNode);
                                        splitTextNodes[0].remove();
                                    }
                                    else {
                                        splitTextNodes[1].insertAfter(linkNode);
                                        splitTextNodes[1].remove();
                                    }
                                }
                            }
                        }
                        else if (node.getType() === 'custom_paragraph' || node.getType() === 'paragraph') {
                            node.append(linkNode);
                        }
                    }
                    else {
                        // find the nearest paragraph node to append the link
                        const nodeToInsertAfter = selection
                            .getNodes()
                            .findLast((node) => node.getType() === 'paragraph' || node.getType() === 'custom-paragraph');
                        nodeToInsertAfter.append(linkNode);
                    }
                }
                closeFormatLinkModal();
            }
        });
    };
    return (_jsxs(FormatterWrapper, { children: [_jsx(Button, { label: translate('Link'), variant: 'transparent', type: 'button', size: 'full', content: _jsx(Icon, { name: 'link', size: 20, color: 'black' }), onClick: onClick(), isActive: isLink }), isFormatLinkModalOpen && (_jsx(FormatLinkModal, { onSave: handleOnSave, onCancel: closeFormatLinkModal, initialLink: linkInfo }))] }));
};
