import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QueryClientContext } from '@tanstack/react-query';
import { useContext, useMemo, useState } from 'react';
import zxcvbn from 'zxcvbn';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Input } from 'libs.nucleus.input';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Select } from 'libs.nucleus.select';
import { Spinner } from 'libs.nucleus.spinner';
import { TextArea } from 'libs.nucleus.text_area';
import { AuthContext } from 'libs.react.auth';
import { useApiClient } from 'libs.react.contexts';
import { useToastNotification } from 'libs.react.hooks';
import { ErrorUtils } from 'libs.react.utils';
import { StudyConfigurationContext } from '../../contexts';
import { useJobChecker } from '../../hooks';
import { generateRandomOrgName } from '../../utils/study';
const DEFAULT_STUDY_TEST_FORM = {
    name: '',
    description: '',
    password: '',
    secondPassword: '',
    version: { label: '-select-', value: '' },
};
const MINIMUM_SCORE = 2;
export const TestModal = ({ onClose, onSave, versions, initialTest }) => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { checkJobStatus } = useJobChecker();
    const momClient = useApiClient("mom" /* ApiClientService.MOM */);
    const { logEvent } = useContext(FirebaseAppContext);
    const { studyId } = useContext(StudyConfigurationContext);
    const { entityId } = useContext(AuthContext);
    const queryClient = useContext(QueryClientContext);
    const [isPasswordStrong, setIsPasswordStrong] = useState(false);
    const [isPasswordPristine, setIsPasswordPristine] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isPristine, setIsPristine] = useState(true);
    const [studyTestForm, setStudyTestForm] = useState({
        ...DEFAULT_STUDY_TEST_FORM,
        name: initialTest?.metadata.name || '',
        description: initialTest?.metadata.description || '',
    });
    const saveDisabled = isPristine ||
        (initialTest
            ? !studyTestForm.version.value
            : !studyTestForm.name ||
                !isPasswordStrong ||
                !studyTestForm.password ||
                !studyTestForm.version.value ||
                studyTestForm.password !== studyTestForm.secondPassword);
    const versionOptions = useMemo(() => versions.map((version) => ({
        label: translate(`${version.metadata.name} (${version.externalVersion})`),
        value: version.id,
    })), [versions]);
    const orgCode = useMemo(() => generateRandomOrgName(process.env.TEST_DEPLOY_ENV), []);
    const saveTest = async () => {
        if (!saveDisabled) {
            try {
                setIsLoading(true);
                let endpoint = `/v1/entities/${entityId}/studies/${studyId}/deploy`;
                const options = {
                    studyConfigId: studyTestForm.version.value,
                    metadata: {
                        description: studyTestForm.description,
                        name: studyTestForm.name,
                    },
                };
                if (initialTest) {
                    options.environmentId = initialTest.id;
                }
                else {
                    endpoint += '?autoProvision=true';
                    options.adminPassword = studyTestForm.password;
                    options.cortexEnv = orgCode;
                }
                const { data: response } = await momClient.post(endpoint, options);
                checkJobStatus(response.data.jobId, {
                    interval: 3000,
                    onInterval: (jobId, response, endPolling) => handleEnvironmentCreation(jobId, response, endPolling),
                });
                logEvent(initialTest ? 'test_sandbox_updated' : 'test_sandbox_created', {
                    study_id: studyId,
                    test_sandbox_name: studyTestForm.name,
                    test_sandbox_version: studyTestForm.version.label,
                    test_sandbox_description: studyTestForm.description,
                });
            }
            catch (error) {
                console.error('Error deploying study configuration', error);
                onDeployError();
            }
        }
    };
    const invalidateEnvironments = async () => {
        await queryClient?.invalidateQueries({ queryKey: ['entities', entityId, 'studies', studyId, 'environments'] });
    };
    /**
     * We validate the job til the environment is created, after that is when the deployment starts
     */
    const handleEnvironmentCreation = (jobId, response, endPolling) => {
        const operationType = initialTest ? 'deployments.deploy' : 'environment.create';
        const deployedOrgCode = initialTest ? `${process.env.TEST_DEPLOY_ENV}.${initialTest.name}` : orgCode;
        const environmentJob = response.operations.find((operation) => operation.type === operationType);
        if (environmentJob && environmentJob.status === 'completed') {
            onSave(jobId, deployedOrgCode);
            invalidateEnvironments();
            endPolling();
            addNotification({
                title: translate('Test version is being deployed. Hang tight, this might take a bit! You can navigate away from this page'),
                type: 'info',
            });
        }
        if (response.status === 'failed') {
            onDeployError(ErrorUtils.getResultErrorMessage(response.result));
            endPolling();
            logEvent('study_deploy_failed', { study_id: studyId });
        }
    };
    const onDeployError = (message) => {
        const subtitle = message
            ? translate('The deployment of this version of your study failed because of "{message}"', { message })
            : translate('The deployment of this version of your study failed. Please try again.');
        addNotification({ title: translate('Error deploying study'), subtitle, type: 'error' });
        setIsLoading(false);
    };
    const savePrimaryButton = {
        label: initialTest ? translate('Update') : translate('Create'),
        onClick: saveTest,
        disabled: saveDisabled,
    };
    const cancelSecondaryButton = {
        label: translate('Cancel'),
        onClick: onClose,
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            saveTest();
        }
    };
    const handlePasswordChange = (event) => {
        const { value } = event.target;
        const result = zxcvbn(value);
        setIsPasswordStrong(result.score >= MINIMUM_SCORE);
        setStudyTestForm((oldForm) => ({ ...oldForm, password: value }));
        setIsPristine(false);
        setIsPasswordPristine(false);
    };
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setStudyTestForm((oldForm) => ({ ...oldForm, [id]: value }));
        setIsPristine(false);
    };
    const handleVersionChange = (newVersion) => {
        setStudyTestForm((oldForm) => ({ ...oldForm, version: newVersion }));
        setIsPristine(false);
    };
    const formSections = [
        {
            title: translate('Details'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6', children: [_jsx(Input, { id: 'name', label: translate('Sandbox name'), maxLength: 50, onChange: handleInputChange, required: true, value: studyTestForm.name, width: 'lg', autoComplete: 'off', disabled: !!initialTest }), _jsx(TextArea, { id: 'description', label: translate('Description'), onChange: handleInputChange, value: studyTestForm.description, width: 'lg' })] })),
        },
        {
            title: translate('Configuration'),
            content: (_jsxs("div", { className: 'flex flex-col gap-6 w-[27.5rem]', children: [_jsx(Select, { dataTestId: 'create-test-version', label: translate('Version'), description: translate('Select the version of the build you want to deploy to the sandbox'), onChange: handleVersionChange, options: versionOptions, required: true, value: studyTestForm.version, width: 'lg' }), _jsx(Input, { id: 'password', label: translate('Password'), description: translate('Used for direct access to sandbox. Make sure your password is at least 6 characters long and combines letters, numbers, and special characters'), errorMessage: translate('Please choose a stronger password'), hasError: !isPasswordStrong && !isPasswordPristine, onChange: handlePasswordChange, required: true, type: 'password', value: studyTestForm.password, width: 'lg', autoComplete: 'new-password', disabled: !!initialTest }), !initialTest && (_jsx(Input, { id: 'secondPassword', label: translate('Confirm password'), errorMessage: translate('Passwords do not match'), hasError: !isPasswordPristine &&
                            !!studyTestForm.secondPassword &&
                            studyTestForm.password !== studyTestForm.secondPassword, onChange: handleInputChange, required: true, type: 'password', value: studyTestForm.secondPassword, width: 'lg' }))] })),
        },
    ];
    return (_jsxs(ModalWindow, { title: initialTest ? translate('Update sandbox') : translate('Create sandbox'), isOpen: true, closeWindow: onClose, footerPrimaryActionButton: savePrimaryButton, footerSecondaryActionButtons: [cancelSecondaryButton], width: 'full', children: [isLoading && _jsx(Spinner, { wrapper: 'full' }), _jsx("form", { className: 'min-h-[30rem]', autoComplete: 'off', onKeyUp: handleEnterPress, children: _jsx(FormSections, { sections: formSections }) })] }));
};
