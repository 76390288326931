import { LocalizationStatus } from 'libs.localization.common';
import { assetConditions } from './asset_conditions';
import { mapStudyAssetToRole } from './data_utils';
// const studyAssetKeyMap: Record<
//   ResponderRole,
//   typeof participantTranslatableStudyAssets | typeof siteTranslatableStudyAssets
// > = {
//   Participant: participantTranslatableStudyAssets,
//   Site: siteTranslatableStudyAssets,
// } as const;
/**
 * Retrieves study asset data for a given study and view.
 * @returns An object of objects containing the status of each locale and path for each asset.
 */
export const getStudyData = (study, locales) => {
    const assetKeys = Object.keys(assetConditions);
    if (!assetKeys || !locales.length || !study || !Object.keys(study).length) {
        return {};
    }
    const data = {};
    assetKeys.forEach((assetKey) => {
        const { condition, displayName, type, getAsset = () => { } } = assetConditions[assetKey] || {};
        const localeData = {};
        if (condition && condition(study)) {
            for (const locale of locales) {
                localeData[locale] = locale === 'en-US' ? LocalizationStatus.READY : LocalizationStatus.NOT_STARTED;
            }
            const asset = getAsset(study);
            data[displayName] = {
                type,
                statuses: localeData,
                path: asset.id,
                assetType: assetKey,
                id: assetKey,
                asset,
                role: mapStudyAssetToRole(assetKey),
            };
        }
    });
    return data;
};
