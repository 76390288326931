import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useRef, useImperativeHandle } from 'react';
import { FormatText } from '../formatters';
import { useFormatBarVisibility } from '../hooks';
const FormatBarContainer = forwardRef(({ children, alwaysVisible }, parentRef) => {
    const ref = useRef(null);
    useImperativeHandle(parentRef, () => ref.current, []);
    const isFormatBarVisible = useFormatBarVisibility(ref, alwaysVisible);
    if (!isFormatBarVisible) {
        return null;
    }
    // Using absolute position for simplicity this might be changed to use a portal instead
    return (_jsx("div", { className: 'absolute right-0 mt-1 inline-flex items-center bg-white z-50 overflow-hidden rounded-md shadow-xl p-1 gap-1', ref: ref, children: children }));
});
FormatBarContainer.displayName = 'FormatBarContainer';
export const FormatBar = forwardRef(({ children, alwaysVisible = false }, ref) => (_jsxs(FormatBarContainer, { ref: ref, alwaysVisible: alwaysVisible, children: [_jsx(FormatText, {}), children] })));
FormatBar.displayName = 'FormatBar';
