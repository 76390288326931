import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { FileUploader } from 'libs.nucleus.file_uploader';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
export const ImportModal = ({ onModalClose, onHandleImport, width = 'full' }) => {
    const t = useLocalizeMessage();
    const [fileStates, setFileStates] = useState({});
    const [files, setFiles] = useState({});
    const onModalWindowClose = () => {
        onModalClose();
    };
    const onDoneClick = () => {
        onHandleImport({ files });
    };
    const onFileDelete = (_, deleteIdx, onSuccess) => {
        const newFileStates = { ...fileStates };
        delete newFileStates[deleteIdx];
        setFileStates(newFileStates);
        const newFiles = { ...files };
        delete newFiles[deleteIdx];
        setFiles(newFiles);
        onSuccess();
    };
    const handleFileUpload = (files) => {
        setFileStates(Object.keys(files).reduce((acc, key) => {
            acc[key] = { type: 'success' };
            return acc;
        }, {}));
        setFiles(files);
    };
    const doneButton = {
        label: t('Import'),
        onClick: onDoneClick,
        disabled: Object.keys(files).length === 0,
    };
    const closeButton = {
        label: t('Cancel'),
        onClick: onModalWindowClose,
    };
    return (_jsx(ModalWindow, { title: t('Import translations'), isOpen: true, closeWindow: onModalWindowClose, footerSecondaryActionButtons: [closeButton], footerPrimaryActionButton: doneButton, width: width, position: 'top', children: _jsx(FileUploader, { label: t('Select translation files'), accept: '.json,.zip', multiple: true, onFileChange: handleFileUpload, onFileDelete: onFileDelete, fileStates: fileStates, description: t('Upload individual or multiple files using .json or .zip formats'), buttonLabel: t('Upload') }) }));
};
