import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { Text } from 'libs.nucleus.typography';
import { useActivityUtils } from '../../../../hooks';
import { ActivityDependencies, EndingConditionType, StartingConditionType, useActivityDependencyUtils, } from '../activity_dependencies';
export const ActivityInstanceEditModal = ({ activityToEdit, visit, onCancel, onSave, }) => {
    const translate = useLocalizeMessage();
    const { getActivityIcon } = useActivityUtils();
    const activityDependencyUtils = useActivityDependencyUtils({
        currentActivity: activityToEdit,
        currentVisit: visit,
    });
    const [isPristine, setIsPristine] = useState(true);
    const { startingConditions, endingConditions } = activityDependencyUtils;
    const isSaveDisabled = useMemo(() => {
        if (isPristine) {
            return true;
        }
        return (startingConditions.some((condition) => (condition.type === StartingConditionType.ACTIVITY_RESPONSE &&
            Object.values(condition.activityResponse).some((v) => !v.value)) ||
            (condition.type === StartingConditionType.DATE_RESPONSE &&
                (condition.activityResponse.activity.value === '' || condition.activityResponse.screen.value === ''))) ||
            endingConditions.some((condition) => (condition.type === EndingConditionType.AFTER_ACTIVITY_COMPLETION && !condition.activityCompletion.value) ||
                (condition.type === EndingConditionType.AFTER_DURATION && !condition.duration.value) ||
                (condition.type === EndingConditionType.ACTIVITY_RESPONSE &&
                    Object.values(condition.activityResponse).some((v) => !v.value)) ||
                (condition.type === EndingConditionType.DATE_RESPONSE &&
                    (condition.activityResponse.activity.value === '' || condition.activityResponse.screen.value === ''))));
    }, [startingConditions, endingConditions, isPristine]);
    const handleSave = () => {
        if (!isSaveDisabled) {
            onSave({ endingConditions, startingConditions });
        }
    };
    const saveButton = {
        label: translate('Save'),
        onClick: handleSave,
        disabled: isSaveDisabled,
    };
    const cancelButton = {
        label: translate('Cancel'),
        onClick: onCancel,
    };
    const onSubmit = (event) => {
        event.preventDefault();
        handleSave();
    };
    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };
    const modalSubtitle = (_jsxs("div", { className: 'flex items-center gap-2', children: [getActivityIcon(activityToEdit.details.type), _jsx(Text, { size: 'sm', className: 'text-secondary', as: 'span', children: activityToEdit.name })] }));
    return (_jsx(ModalWindow, { title: translate('Edit details'), subtitle: modalSubtitle, isOpen: true, width: 'full', footerPrimaryActionButton: saveButton, footerSecondaryActionButtons: [cancelButton], closeWindow: onCancel, children: _jsx("form", { className: 'flex flex-col gap-4', onSubmit: onSubmit, autoComplete: 'off', onKeyUp: handleEnterPress, children: _jsx(ActivityDependencies, { onPristineChange: setIsPristine, activityDependencyUtils: activityDependencyUtils, activityToEdit: activityToEdit }) }) }));
};
