import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { RadioGroup, RadioGroupOption } from 'libs.nucleus.radio_group';
import { H6 } from 'libs.nucleus.typography';
import { ActivitySelectionCard } from './activity_selection_card.component';
export const SelectActivityTypeStep = ({ selectedActivityType, onSelectedActivityTypeChange, }) => {
    const translate = useLocalizeMessage();
    const onActivityTypeSelection = (type) => {
        onSelectedActivityTypeChange(type);
    };
    return (_jsxs("div", { className: 'flex flex-col items-center justify-center w-full h-full gap-8', children: [_jsx("div", { className: 'w-1/2', children: _jsx(H6, { className: 'text-text-primary font-semibold', children: translate('Select activity type') }) }), _jsx(ActivitySelectionCard, { selected: selectedActivityType === 'visit', onClick: () => onActivityTypeSelection('visit'), children: _jsx(RadioGroup, { onChange: onActivityTypeSelection, value: selectedActivityType, children: _jsx(RadioGroupOption, { option: {
                            label: translate('Visit activity'),
                            description: translate('Add an activity that happens in a visit with a clinician'),
                            value: 'visit',
                        } }) }) }), _jsx(ActivitySelectionCard, { selected: selectedActivityType === 'remote', onClick: () => onActivityTypeSelection('remote'), children: _jsx(RadioGroup, { onChange: onActivityTypeSelection, value: selectedActivityType, children: _jsx(RadioGroupOption, { option: {
                            label: translate('Remote activity'),
                            description: translate('Intended for participants to complete remotely in between visits'),
                            value: 'remote',
                        } }) }) })] }));
};
