import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { FlagContext } from 'libs.flags.react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { H1 } from 'libs.nucleus.typography';
import { PageWrapper } from 'libs.react.components';
import { Chat } from '..';
import { STUDY_MANAGEMENT_FLAGS } from '../../constants';
export const ChatContainer = ({ children, chatProps, title }) => {
    const translate = useLocalizeMessage();
    const flagManager = useContext(FlagContext);
    const chatEnabled = flagManager.getValue(STUDY_MANAGEMENT_FLAGS.chat);
    return (_jsxs("div", { className: 'flex h-full', children: [_jsxs(PageWrapper, { children: [title && (_jsx("div", { className: 'mb-6 border-b', children: _jsx(H1, { dataTestId: 'chat-page-header-title', children: translate(title) }) })), children] }), chatEnabled && _jsx(Chat, { conversationKey: chatProps.conversationKey, systemPrompt: chatProps.systemPrompt })] }));
};
