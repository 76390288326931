const getStatus = (environment) => {
    const workflow = Array.isArray(environment.workflow) ? environment.workflow[0] : environment.workflow;
    if (workflow) {
        return workflow.status || 'active';
    }
    return environment.metadata.packages.length > 0 ? 'completed' : 'failed';
};
const buildEnvironmentUrl = (studyTest) => {
    return `https://apps-dashboard-${studyTest.cluster}.medable.com/?orgCode=${studyTest.code}`;
};
export const DeploymentUtils = {
    buildEnvironmentUrl,
    getStatus,
};
