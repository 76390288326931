import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
const ActivitiesDashboard = lazy(() => import('./components/activities_dashboard'));
const ViewActivity = lazy(() => import('./components/view_activity'));
const ViewTranslation = lazy(() => import('./components/view_translation'));
export const ECOABuilderApp = () => {
    return (_jsxs(Routes, { children: [_jsx(Route, { path: '/:id', element: _jsx(ViewActivity, {}) }), _jsx(Route, { path: '/:id/translation/:locale?', element: _jsx(ViewTranslation, {}) }), _jsx(Route, { index: true, path: '/', element: _jsx(ActivitiesDashboard, {}) })] }));
};
export { ECOA_BUILDER_FLAGS } from './constants/developer_flags.constants';
