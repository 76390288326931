import { jsx as _jsx } from "react/jsx-runtime";
import { LocalizationStatus } from 'libs.localization.common';
import { Icon } from 'libs.nucleus.icon';
export const localeStatusMap = {
    [LocalizationStatus.READY]: {
        color: 'green',
        icon: 'success',
    },
    [LocalizationStatus.IN_PROGRESS]: {
        color: 'yellow',
        icon: 'navWrenchScrewdriver',
    },
    [LocalizationStatus.IN_REVIEW]: {
        color: 'yellow',
        icon: 'preview',
    },
    [LocalizationStatus.NOT_STARTED]: {
        color: 'red',
        icon: 'stopCircle',
    },
};
export const LocaleStatusIcon = ({ status, disabled = false }) => {
    return status === LocalizationStatus.NOT_AVAILABLE ? ('-') : (_jsx(Icon, { name: localeStatusMap[status].icon, color: disabled ? 'grayLight' : localeStatusMap[status].color, size: 'sm', "data-testid": `locale-status-icon-${status.replace(' ', '-').toLowerCase()}` }));
};
