import { LinkNode } from '@lexical/link';
import { isHTMLElement } from 'lexical';
import { linkClasses } from '../utils';
export class CustomLinkNode extends LinkNode {
    constructor(url, retainTarget) {
        super(url);
        this.retainTarget = retainTarget;
    }
    static getType() {
        return 'custom_link';
    }
    static clone(node) {
        if (node instanceof CustomLinkNode) {
            return new CustomLinkNode(node.__url, node.retainTarget);
        }
        return new CustomLinkNode(node.__url, false);
    }
    static importJSON(node) {
        return super.importJSON(node);
    }
    createDOM(config) {
        const dom = super.createDOM(config);
        dom.setAttribute('rel', 'noopener noreferrer');
        dom.setAttribute('target', '_blank');
        dom.setAttribute('class', linkClasses);
        return dom;
    }
    exportJSON() {
        return Object.assign(Object.assign({}, super.exportJSON()), { type: 'custom_link', version: 1 });
    }
    /**
     * Overrides the exportDOM method to remove additional classes, styles, and attributes from an a tag.
     * @override
     */
    exportDOM(editor) {
        const { element } = super.exportDOM(editor);
        if (element && isHTMLElement(element)) {
            element.removeAttribute('class');
            element.removeAttribute('rel');
            if (!this.retainTarget) {
                element.removeAttribute('target');
            }
        }
        return { element };
    }
}
