import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useContext, useMemo } from 'react';
import { LocalizationStatus } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { FlagContext } from 'libs.flags.react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Toggle } from 'libs.nucleus.toggle';
import { ListInformation } from 'libs.react.components';
import { StudyConfigurationContext } from '../../../contexts';
export const StudySettings = () => {
    const translate = useLocalizeMessage();
    const flagManager = useContext(FlagContext);
    const { assignStudy, currentStudy, studyId } = useContext(StudyConfigurationContext);
    const { logEvent } = useContext(FirebaseAppContext);
    const disableBuildConfig = currentStudy.localizationStatus === LocalizationStatus.READY;
    const studyMetadata = currentStudy.getStudyMetadata();
    const updateStudyMetadata = (partialMetadata) => {
        currentStudy.updateStudyMetadata(partialMetadata);
        assignStudy(currentStudy);
    };
    const onIsEConsentEnabledChange = useCallback((isEConsentEnabled) => {
        updateStudyMetadata({ isEConsentEnabled });
        logEvent('enable_econsent_toggled', { study_id: studyId, is_econsent_enabled: isEConsentEnabled });
    }, [currentStudy]);
    const content = useMemo(() => {
        return [
            {
                title: translate('Enable eConsent'),
                description: translate('If turned on, the consent manager tab will be displayed in Site App'),
                actionContent: (_jsx(Toggle, { checked: studyMetadata.isEConsentEnabled, dataTestId: 'enable-econsent-toggle', disabled: disableBuildConfig, onChange: onIsEConsentEnabledChange })),
            },
        ];
    }, [flagManager, onIsEConsentEnabledChange, studyMetadata.isEConsentEnabled]);
    return _jsx(ListInformation, { items: content, descriptionColor: 'secondary' });
};
