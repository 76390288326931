import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { v4 } from 'uuid';
import { DefaultActivityId } from '@axon/rosetta-sdk';
import { Button } from 'libs.nucleus.button';
import { Checkbox } from 'libs.nucleus.checkbox';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Icon } from 'libs.nucleus.icon';
import { Input } from 'libs.nucleus.input';
import { Select } from 'libs.nucleus.select';
import { TimePicker } from 'libs.nucleus.time_picker';
import { Text } from 'libs.nucleus.typography';
import { StartingConditionType, EndingConditionType, } from './use_activity_dependency_utils.hook';
import { ONLY_POSITIVE_INTEGERS_REGEX } from '../../../../constants';
import { getSelectOption } from '../../../../utils';
import { SelectActivityResponse, } from '../activity_instance_edit_modal/select_activity_response.component';
const MAX_REPETITION_EVERY = 500;
const filterArr = [
    EndingConditionType.ACTIVITY_RESPONSE,
    EndingConditionType.VISIT_CONFIRMATION,
    EndingConditionType.AFTER_ACTIVITY_COMPLETION,
    EndingConditionType.ENDLESS,
    EndingConditionType.NUM_OF_OCURRENCES,
];
const getReccurenceIdx = (endingConditions) => {
    return endingConditions.findIndex((condition) => filterArr.includes(condition.type));
};
export const ActivityDependencies = ({ onPristineChange, activityDependencyUtils, isRemoteActivity, activityToEdit, remoteType, showSpecificStartTime, onShowSpecificStartTimeChange, }) => {
    const translate = useLocalizeMessage();
    const { startingConditionOptions, endingConditionOptions, onboardingVisit, visitConfirmationOption, startingConditions, endingConditions, activityCompletionOptions, dayOptions, periodOptions, vrsResponseOptions, dateTimeResponseOptions, setStartingConditions, setEndingConditions, allVisitOptions, specificStartTime, setSpecificStartTime, repetition, setRepetition, recurrenceEndingOptions, } = activityDependencyUtils;
    const repetitionOptions = periodOptions.filter((option) => option.value !== 'hours');
    const [recurrenceEndingOption, setRecurrenceEndingOption] = useState(() => {
        if (remoteType !== 'recurring') {
            return recurrenceEndingOptions[0];
        }
        if (remoteType === 'recurring' && filterArr.includes(endingConditions[endingConditions.length - 1]?.type)) {
            return getSelectOption(recurrenceEndingOptions, endingConditions[endingConditions.length - 1].type);
        }
        if (repetition.count) {
            return recurrenceEndingOptions[0];
        }
        // endless is the default;
        return recurrenceEndingOptions[1];
    });
    const getDefaultActivityResponse = (options) => {
        return {
            activity: options.activities[0],
            screen: options.screenOptions[''].screens[0],
            response: options.screenOptions[''].responses[''][0],
        };
    };
    const handleStartingSelectChange = (conditionIndex, id, option) => {
        onPristineChange && onPristineChange(false);
        setStartingConditions((conditions) => {
            if (id === 'activityCompletion' &&
                typeof option === 'object' &&
                'value' in option &&
                (option.value ===
                    onboardingVisit.getActivitySequenceByActivityId(DefaultActivityId.AUTHENTICATION)?.instanceId ||
                    option.value ===
                        onboardingVisit.getActivitySequenceByActivityId(DefaultActivityId.ENTER_PARTICIPANT_NUMBER)?.instanceId)) {
                // @ts-expect-error - This is a valid assignment
                conditions[conditionIndex] = {
                    ...conditions[conditionIndex],
                    [id]: option,
                    offset: dayOptions[0],
                };
            }
            else {
                conditions[conditionIndex] = { ...conditions[conditionIndex], [id]: option };
            }
            return [...conditions];
        });
    };
    const handleStartingConditionChange = (conditionIndex, option) => {
        if (startingConditions[conditionIndex].option.value === option.value) {
            return;
        }
        handleStartingSelectChange(conditionIndex, 'option', option);
        handleStartingSelectChange(conditionIndex, 'type', option.value);
        if (option.value === StartingConditionType.VISIT_CONFIRMATION) {
            handleStartingSelectChange(conditionIndex, 'activityCompletion', visitConfirmationOption);
        }
        if (option.value === StartingConditionType.AFTER_ACTIVITY_COMPLETION) {
            handleStartingSelectChange(conditionIndex, 'activityCompletion', activityCompletionOptions[0]);
        }
        if (option.value === StartingConditionType.ACTIVITY_RESPONSE ||
            option.value === StartingConditionType.DATE_RESPONSE) {
            const options = option.value === StartingConditionType.ACTIVITY_RESPONSE ? vrsResponseOptions : dateTimeResponseOptions;
            handleStartingSelectChange(conditionIndex, 'activityResponse', getDefaultActivityResponse(options));
        }
        if (option.value === StartingConditionType.ACTIVITY_RESPONSE) {
            handleStartingSelectChange(conditionIndex, 'offset', dayOptions[0]);
        }
    };
    const handleEndingSelectChange = (conditionIndex, id, option) => {
        onPristineChange && onPristineChange(false);
        setEndingConditions((conditions) => {
            conditions[conditionIndex] = { ...conditions[conditionIndex], [id]: option };
            return [...conditions];
        });
    };
    const handleEndingConditionChange = (conditionIndex, option) => {
        if (endingConditions[conditionIndex].option.value === option.value) {
            return;
        }
        handleEndingSelectChange(conditionIndex, 'option', option);
        handleEndingSelectChange(conditionIndex, 'type', option.value);
        if (option.value === EndingConditionType.VISIT_CONFIRMATION) {
            handleEndingSelectChange(conditionIndex, 'activityCompletion', visitConfirmationOption);
        }
        if (option.value === EndingConditionType.AFTER_DURATION) {
            handleEndingSelectChange(conditionIndex, 'duration', dayOptions[0]);
            handleEndingSelectChange(conditionIndex, 'durationPeriod', periodOptions[1]);
        }
        if (option.value === EndingConditionType.AFTER_ACTIVITY_COMPLETION) {
            handleEndingSelectChange(conditionIndex, 'activityCompletion', activityCompletionOptions[0]);
        }
        if (option.value === EndingConditionType.ACTIVITY_RESPONSE || option.value === EndingConditionType.DATE_RESPONSE) {
            const options = option.value === EndingConditionType.ACTIVITY_RESPONSE ? vrsResponseOptions : dateTimeResponseOptions;
            handleEndingSelectChange(conditionIndex, 'activityResponse', getDefaultActivityResponse(options));
        }
        if (option.value === EndingConditionType.ACTIVITY_RESPONSE ||
            option.value === EndingConditionType.END_OF_DAY ||
            option.value === EndingConditionType.AFTER_DURATION ||
            option.value === EndingConditionType.UPON_COMPLETION) {
            handleEndingSelectChange(conditionIndex, 'offset', dayOptions[0]);
        }
    };
    const getValidStartingConditions = (condition) => {
        const disableMap = {
            [StartingConditionType.VISIT_CONFIRMATION]: {
                afterActivityCompletion: false,
                activityResponse: false,
                dateResponse: true,
                visitConfirmation: true,
                studyCreation: false,
            },
            [StartingConditionType.AFTER_ACTIVITY_COMPLETION]: {
                afterActivityCompletion: true,
                activityResponse: false,
                dateResponse: false,
                visitConfirmation: false,
                studyCreation: true,
            },
            [StartingConditionType.ACTIVITY_RESPONSE]: {
                afterActivityCompletion: false,
                activityResponse: true,
                dateResponse: false,
                visitConfirmation: false,
                studyCreation: false,
            },
            [StartingConditionType.DATE_RESPONSE]: {
                afterActivityCompletion: false,
                activityResponse: false,
                dateResponse: true,
                visitConfirmation: true,
                studyCreation: false,
            },
            [StartingConditionType.STUDY_CREATION]: {
                afterActivityCompletion: true,
                activityResponse: false,
                dateResponse: false,
                visitConfirmation: false,
                studyCreation: true,
            },
        };
        return [
            {
                ...startingConditionOptions.visitConfirmation,
                hidden: !isRemoteActivity && !visitConfirmationOption,
                isDisabled: startingConditions.length > 1 &&
                    disableMap[condition.option.value].visitConfirmation,
            },
            {
                ...startingConditionOptions.studyCreation,
                isDisabled: startingConditions.length > 1 && disableMap[condition.option.value].studyCreation,
            },
            {
                ...startingConditionOptions.afterActivityCompletion,
                isDisabled: startingConditions.length > 1 &&
                    disableMap[condition.option.value].afterActivityCompletion,
            },
            {
                ...startingConditionOptions.activityResponse,
                hidden: activityToEdit?.id === DefaultActivityId.AUTHENTICATION,
                isDisabled: startingConditions.length > 1 && disableMap[condition.option.value].activityResponse,
            },
            {
                ...startingConditionOptions.dateResponse,
                hidden: activityToEdit?.id === DefaultActivityId.AUTHENTICATION,
                isDisabled: startingConditions.length > 1 && disableMap[condition.option.value].dateResponse,
            },
        ];
    };
    const filteredEndingConditions = remoteType !== 'recurring' ? endingConditions : endingConditions.filter((c) => !filterArr.includes(c.type));
    const getValidEndingConditions = (condition) => {
        const disableMap = {
            [EndingConditionType.END_OF_DAY]: {
                endOfDay: true,
                afterActivityCompletion: false,
                activityResponse: false,
                dateResponse: true,
                uponCompletion: true,
                afterDuration: true,
                visitConfirmation: true,
                endless: true,
            },
            [EndingConditionType.UPON_COMPLETION]: {
                endOfDay: true,
                afterActivityCompletion: false,
                activityResponse: false,
                dateResponse: true,
                uponCompletion: true,
                afterDuration: true,
                visitConfirmation: true,
                endless: true,
            },
            [EndingConditionType.AFTER_ACTIVITY_COMPLETION]: {
                endOfDay: false,
                afterActivityCompletion: true,
                activityResponse: true,
                dateResponse: true,
                uponCompletion: false,
                afterDuration: false,
                visitConfirmation: false,
                endless: true,
            },
            [EndingConditionType.ACTIVITY_RESPONSE]: {
                endOfDay: false,
                afterActivityCompletion: true,
                activityResponse: true,
                dateResponse: false,
                uponCompletion: false,
                afterDuration: false,
                visitConfirmation: false,
                endless: true,
            },
            [EndingConditionType.DATE_RESPONSE]: {
                endOfDay: true,
                afterActivityCompletion: false,
                activityResponse: false,
                dateResponse: true,
                uponCompletion: true,
                afterDuration: true,
                visitConfirmation: true,
                endless: true,
            },
            [EndingConditionType.AFTER_DURATION]: {
                endOfDay: true,
                afterActivityCompletion: false,
                activityResponse: false,
                dateResponse: true,
                uponCompletion: true,
                afterDuration: true,
                visitConfirmation: true,
                endless: true,
            },
            [EndingConditionType.VISIT_CONFIRMATION]: {
                endOfDay: true,
                afterActivityCompletion: false,
                activityResponse: false,
                dateResponse: true,
                uponCompletion: true,
                afterDuration: true,
                visitConfirmation: true,
                endless: true,
            },
            [EndingConditionType.ENDLESS]: {
                endOfDay: true,
                afterActivityCompletion: true,
                activityResponse: true,
                dateResponse: true,
                uponCompletion: true,
                afterDuration: true,
                visitConfirmation: true,
                endless: true,
            },
            [EndingConditionType.NUM_OF_OCURRENCES]: {
                endOfDay: true,
                afterActivityCompletion: true,
                activityResponse: true,
                dateResponse: true,
                uponCompletion: true,
                afterDuration: true,
                visitConfirmation: true,
                endless: true,
            },
        };
        return [
            {
                ...endingConditionOptions.visitConfirmation,
                hidden: !isRemoteActivity || (isRemoteActivity && remoteType === 'recurring'),
                isDisabled: filteredEndingConditions.length > 1 &&
                    disableMap[condition.option.value].visitConfirmation,
            },
            {
                ...endingConditionOptions.endless,
                hidden: !isRemoteActivity || (isRemoteActivity && remoteType !== 'adhoc'),
                isDisabled: filteredEndingConditions.length > 1,
            },
            {
                ...endingConditionOptions.endOfDay,
                hidden: isRemoteActivity && remoteType === 'adhoc',
                isDisabled: filteredEndingConditions.length > 1 && disableMap[condition.option.value].endOfDay,
            },
            {
                ...endingConditionOptions.uponCompletion,
                hidden: isRemoteActivity && remoteType !== 'once',
                isDisabled: filteredEndingConditions.length > 1 &&
                    disableMap[condition.option.value].uponCompletion,
            },
            {
                ...endingConditionOptions.afterDuration,
                hidden: isRemoteActivity && remoteType === 'adhoc',
                isDisabled: filteredEndingConditions.length > 1 &&
                    disableMap[condition.option.value].afterDuration,
            },
            {
                ...endingConditionOptions.afterActivityCompletion,
                hidden: isRemoteActivity && remoteType === 'recurring',
                isDisabled: filteredEndingConditions.length > 1 &&
                    disableMap[condition.option.value].afterActivityCompletion,
            },
            {
                ...endingConditionOptions.activityResponse,
                hidden: isRemoteActivity && remoteType === 'recurring',
                isDisabled: filteredEndingConditions.length > 1 &&
                    disableMap[condition.option.value].activityResponse,
            },
            {
                ...endingConditionOptions.dateResponse,
                hidden: isRemoteActivity && remoteType === 'recurring',
                isDisabled: filteredEndingConditions.length > 1 && disableMap[condition.option.value].dateResponse,
            },
        ];
    };
    const addStartCondition = () => {
        onPristineChange && onPristineChange(false);
        const newOption = startingConditions[0].option.value === StartingConditionType.VISIT_CONFIRMATION ||
            (!visitConfirmationOption &&
                startingConditions[0].option.value !== StartingConditionType.AFTER_ACTIVITY_COMPLETION &&
                startingConditions[0].option.value !== StartingConditionType.STUDY_CREATION)
            ? {
                id: v4(),
                option: startingConditionOptions.afterActivityCompletion,
                activityCompletion: activityCompletionOptions[0],
                offset: dayOptions[0],
                type: StartingConditionType.AFTER_ACTIVITY_COMPLETION,
            }
            : visitConfirmationOption
                ? {
                    id: v4(),
                    option: startingConditionOptions.visitConfirmation,
                    activityCompletion: visitConfirmationOption,
                    offset: dayOptions[0],
                    type: StartingConditionType.VISIT_CONFIRMATION,
                }
                : startingConditions[0].option.value !== StartingConditionType.AFTER_ACTIVITY_COMPLETION &&
                    startingConditions[0].option.value !== StartingConditionType.STUDY_CREATION
                    ? {
                        id: v4(),
                        option: startingConditionOptions.afterActivityCompletion,
                        activityCompletion: activityCompletionOptions[0],
                        offset: dayOptions[0],
                        type: StartingConditionType.AFTER_ACTIVITY_COMPLETION,
                    }
                    : {
                        id: v4(),
                        option: startingConditionOptions.activityResponse,
                        activityResponse: getDefaultActivityResponse(vrsResponseOptions),
                        type: StartingConditionType.ACTIVITY_RESPONSE,
                    };
        setStartingConditions((conditions) => [...conditions, newOption]);
    };
    const addEndCondition = () => {
        onPristineChange && onPristineChange(false);
        let newOption;
        if (remoteType === 'recurring') {
            newOption =
                endingConditions[0].option.value === EndingConditionType.END_OF_DAY
                    ? {
                        id: v4(),
                        option: endingConditionOptions.afterDuration,
                        type: EndingConditionType.AFTER_DURATION,
                        duration: dayOptions[0],
                        durationPeriod: periodOptions[1],
                    }
                    : {
                        id: v4(),
                        option: endingConditionOptions.endOfDay,
                        type: EndingConditionType.END_OF_DAY,
                    };
        }
        else {
            newOption =
                endingConditions[0].option.value === EndingConditionType.UPON_COMPLETION ||
                    endingConditions[0].option.value === EndingConditionType.END_OF_DAY ||
                    endingConditions[0].option.value === EndingConditionType.AFTER_DURATION ||
                    endingConditions[0].option.value === EndingConditionType.DATE_RESPONSE ||
                    (isRemoteActivity && endingConditions[0].option.value === EndingConditionType.VISIT_CONFIRMATION)
                    ? {
                        id: v4(),
                        option: endingConditionOptions.afterActivityCompletion,
                        activityCompletion: activityCompletionOptions[0],
                        offset: dayOptions[0],
                        type: EndingConditionType.AFTER_ACTIVITY_COMPLETION,
                    }
                    : isRemoteActivity
                        ? {
                            id: v4(),
                            option: endingConditionOptions.visitConfirmation,
                            type: EndingConditionType.VISIT_CONFIRMATION,
                            activityCompletion: visitConfirmationOption,
                            offset: dayOptions[0],
                        }
                        : {
                            id: v4(),
                            option: endingConditionOptions.uponCompletion,
                            type: EndingConditionType.UPON_COMPLETION,
                        };
        }
        // The added end condition should always be the second value in the array,
        // this is because the last value is always the recurrence end condition
        const newConditions = [...endingConditions];
        newConditions.splice(1, 0, newOption);
        setEndingConditions(newConditions);
    };
    const deleteCondition = (index, type) => {
        onPristineChange && onPristineChange(false);
        if (type === 'start') {
            setStartingConditions((conditions) => conditions.filter((_, i) => i !== index));
            return;
        }
        setEndingConditions((conditions) => conditions.filter((_, i) => i !== index));
    };
    const handlePositiveNumberChange = (event) => {
        const { id, value } = event.target;
        if (!value ||
            (ONLY_POSITIVE_INTEGERS_REGEX.test(value) && Number(value) <= MAX_REPETITION_EVERY && Number(value) > 0)) {
            onPristineChange(false);
            setRepetition((oldRepetition) => ({ ...oldRepetition, [id]: value }));
        }
    };
    /**
     * Handles the blur event for numeric inputs
     * when input is empty, set the value to 1
     */
    const handleNumericInputBlur = (event) => {
        const { id, value } = event.target;
        if (!value) {
            setRepetition((oldRepetition) => ({ ...oldRepetition, [id]: '1' }));
        }
    };
    const handleRepetitionChange = (option) => {
        onPristineChange(false);
        setRepetition((oldForm) => ({ ...oldForm, repetitionEveryPeriod: option }));
    };
    const handleReccurenceEndingOptionChange = (option) => {
        if (recurrenceEndingOption.value === option.value) {
            return;
        }
        onPristineChange(false);
        setRecurrenceEndingOption(option);
        setRepetition((oldForm) => ({
            ...oldForm,
            count: option.value === EndingConditionType.NUM_OF_OCURRENCES ? '1' : undefined,
        }));
        const newConditions = [...endingConditions];
        const conditionIdx = getReccurenceIdx(newConditions);
        const newCondition = option.value === EndingConditionType.VISIT_CONFIRMATION
            ? {
                id: v4(),
                option: endingConditionOptions.visitConfirmation,
                activityCompletion: visitConfirmationOption,
                offset: newConditions[conditionIdx]?.offset || dayOptions[0],
                type: EndingConditionType.VISIT_CONFIRMATION,
            }
            : option.value === EndingConditionType.ACTIVITY_RESPONSE
                ? {
                    id: v4(),
                    option: endingConditionOptions.activityResponse,
                    activityResponse: getDefaultActivityResponse(vrsResponseOptions),
                    type: EndingConditionType.ACTIVITY_RESPONSE,
                }
                : option.value === EndingConditionType.AFTER_ACTIVITY_COMPLETION
                    ? {
                        id: v4(),
                        option: endingConditionOptions.afterActivityCompletion,
                        activityCompletion: activityCompletionOptions[0],
                        offset: newConditions[conditionIdx]?.offset || dayOptions[0],
                        type: EndingConditionType.AFTER_ACTIVITY_COMPLETION,
                    }
                    : {
                        id: v4(),
                        type: option.value,
                    };
        // we always want the recurrence end condition to be at the end of the array
        if (conditionIdx > -1) {
            newConditions.splice(conditionIdx);
        }
        newConditions.push(newCondition);
        setEndingConditions(newConditions);
    };
    const sections = [
        {
            title: translate('Availability starts'),
            description: translate('Choose when this activity will become available for the first time'),
            content: (_jsxs("section", { className: 'flex flex-col gap-6', "data-testid": 'activity-instance-start-availability', children: [startingConditions.map((condition, index) => {
                        const isStartOffsetDisabled = condition.option.value !== StartingConditionType.VISIT_CONFIRMATION &&
                            condition.option.value !== StartingConditionType.DATE_RESPONSE;
                        const isChooseActivity = condition.type === StartingConditionType.AFTER_ACTIVITY_COMPLETION;
                        return (_jsxs("section", { className: 'flex flex-col gap-4', children: [_jsxs("section", { className: 'flex gap-2 items-end', children: [_jsx(Select, { dataTestId: `activity-instance-start-activity-type-${index}`, onChange: (option) => handleStartingConditionChange(index, option), options: getValidStartingConditions(index === 0 ? startingConditions[1] : startingConditions[0]), value: condition.option, label: translate('Starts after'), width: 'base' }), _jsx(Select, { dataTestId: `activity-instance-start-offset-${index}`, onChange: (option) => handleStartingSelectChange(index, 'offset', option), label: translate('Offset (days)'), options: dayOptions, value: condition.offset || dayOptions[0], width: 'xs', disabled: isStartOffsetDisabled }), startingConditions.length > 1 && (_jsx("div", { children: _jsx(Button, { label: translate('Delete'), content: _jsx(Icon, { name: 'delete' }), type: 'button', variant: 'transparent', onClick: () => deleteCondition(index, 'start') }) }))] }), (isRemoteActivity ||
                                    (!isRemoteActivity &&
                                        condition.option.value !== StartingConditionType.VISIT_CONFIRMATION &&
                                        condition.option.value !== StartingConditionType.STUDY_CREATION)) && (_jsx("div", { className: 'w-[27.5rem] h-px bg-neutral-300' })), isRemoteActivity && condition.option.value === StartingConditionType.VISIT_CONFIRMATION && (_jsxs("section", { className: 'w-[27.5rem] flex items-center justify-between', children: [_jsx(Text, { size: 'sm', className: 'font-medium', children: translate('At visit') }), _jsx(Select, { dataTestId: `activity-instance-start-confirmation-visit-${index}`, onChange: (option) => handleStartingSelectChange(index, 'visitId', option), options: allVisitOptions, value: condition.visitId || allVisitOptions[0], width: 'base' })] })), isChooseActivity && (_jsxs("section", { className: 'w-[27.5rem] flex items-center justify-between', children: [_jsx(Text, { size: 'sm', className: 'font-medium', children: translate('Activity') }), _jsx(Select, { dataTestId: `activity-instance-start-activity-completion-${index}`, onChange: (option) => handleStartingSelectChange(index, 'activityCompletion', option), options: activityCompletionOptions, value: condition.activityCompletion || activityCompletionOptions[0], width: 'base' })] })), (condition.type === StartingConditionType.ACTIVITY_RESPONSE ||
                                    condition.type === StartingConditionType.DATE_RESPONSE) && (_jsx(SelectActivityResponse, { screenType: condition.type === StartingConditionType.DATE_RESPONSE ? 'date_time' : 'vrs', options: condition.type === StartingConditionType.DATE_RESPONSE
                                        ? dateTimeResponseOptions
                                        : vrsResponseOptions, onSelectedOptionChange: (option) => handleStartingSelectChange(index, 'activityResponse', option), selectedOption: condition.activityResponse })), startingConditions.length !== index + 1 && (_jsxs("div", { className: 'flex items-center w-[27.5rem]', children: [_jsx(Text, { size: 'sm', className: 'mx-4 text-secondary font-medium', children: translate('And') }), _jsx("div", { className: 'border-b w-full' })] }))] }, `${condition.option.value}-${index}`));
                    }), startingConditions.length === 1 && activityToEdit?.id !== DefaultActivityId.AUTHENTICATION && (_jsx("section", { children: _jsx(Button, { variant: 'transparent', label: translate('+ Add condition'), onClick: addStartCondition }) })), remoteType === 'recurring' && (_jsxs("section", { className: 'w-[27.5rem]', children: [_jsx(Checkbox, { label: translate('Start at specific time of day'), description: translate('Choose what time each instance after the first instance will start. If not selected, instances will start at midnight.'), checked: !!showSpecificStartTime, onChange: onShowSpecificStartTimeChange }), _jsx("div", { className: 'ml-8', children: _jsx(TimePicker, { value: specificStartTime, onChange: setSpecificStartTime, disabled: !showSpecificStartTime }) })] }))] })),
        },
        {
            title: translate('Availability ends'),
            description: isRemoteActivity
                ? translate("Choose when this activity's availability will end")
                : translate("Choose when this activity's availability will end. This activity will automatically end upon start of next visit or upon participant deactivation or early withdraw"),
            hidden: activityToEdit?.id === DefaultActivityId.AUTHENTICATION,
            content: (_jsxs("section", { className: 'flex flex-col gap-6', "data-testid": 'activity-instance-end-availability', children: [filteredEndingConditions.map((condition, index) => (_jsxs("section", { className: 'flex flex-col gap-4', children: [_jsxs("section", { className: 'flex gap-2', children: [_jsx(Select, { dataTestId: `activity-instance-end-trigger-${index}`, label: translate('Ends'), onChange: (option) => handleEndingConditionChange(index, option), options: getValidEndingConditions(index === 0 ? filteredEndingConditions[1] : filteredEndingConditions[0]), value: condition.option, width: 'base' }), _jsx(Select, { dataTestId: `activity-instance-offset-${index}`, onChange: (option) => handleEndingSelectChange(index, 'offset', option), label: translate('Offset (days)'), options: dayOptions, value: condition.offset || dayOptions[0], width: 'xs', disabled: condition.option.value !== EndingConditionType.DATE_RESPONSE &&
                                            condition.option.value !== EndingConditionType.VISIT_CONFIRMATION &&
                                            condition.option.value !== EndingConditionType.NUM_OF_OCURRENCES }), filteredEndingConditions.length > 1 && (_jsx("div", { children: _jsx(Button, { label: translate('Delete'), content: _jsx(Icon, { name: 'delete' }), type: 'button', variant: 'transparent', onClick: () => deleteCondition(index, 'end') }) }))] }), condition.option.value !== EndingConditionType.END_OF_DAY &&
                                condition.option.value !== EndingConditionType.UPON_COMPLETION &&
                                condition.option.value !== EndingConditionType.ENDLESS && (_jsx("div", { className: 'w-[27.5rem] h-px bg-neutral-300' })), isRemoteActivity && condition.option.value === EndingConditionType.VISIT_CONFIRMATION && (_jsxs("section", { className: 'w-[27.5rem] flex items-center justify-between', children: [_jsx(Text, { size: 'sm', className: 'font-medium', children: translate('At visit') }), _jsx(Select, { dataTestId: `activity-instance-end-confirmation-visit-${index}`, onChange: (option) => handleEndingSelectChange(index, 'visitId', option), options: allVisitOptions, value: condition.visitId || allVisitOptions[0], width: 'base' })] })), condition.option.value === EndingConditionType.AFTER_DURATION && (_jsxs("section", { className: 'flex items-end gap-2', children: [_jsx(Select, { dataTestId: `activity-instance-duration-${index}`, onChange: (option) => handleEndingSelectChange(index, 'duration', option), options: dayOptions, value: condition.duration || dayOptions[0], width: 'xs' }), _jsx(Select, { dataTestId: `activity-instance-duration-period-${index}`, onChange: (option) => handleEndingSelectChange(index, 'durationPeriod', option), options: periodOptions, value: condition.durationPeriod || periodOptions[1] })] })), condition.option.value === EndingConditionType.AFTER_ACTIVITY_COMPLETION && (_jsxs("section", { className: 'w-[27.5rem] flex items-center justify-between', children: [_jsx(Text, { size: 'sm', className: 'font-medium', children: translate('Activity') }), _jsx(Select, { dataTestId: `activity-instance-end-activity-completion-${index}`, onChange: (option) => handleEndingSelectChange(index, 'activityCompletion', option), options: activityCompletionOptions, value: condition.activityCompletion || activityCompletionOptions[1], width: 'base' })] })), (condition.option.value === EndingConditionType.ACTIVITY_RESPONSE ||
                                condition.option.value === EndingConditionType.DATE_RESPONSE) && (_jsx(SelectActivityResponse, { screenType: condition.type === EndingConditionType.DATE_RESPONSE ? 'date_time' : 'vrs', options: condition.type === EndingConditionType.DATE_RESPONSE ? dateTimeResponseOptions : vrsResponseOptions, onSelectedOptionChange: (option) => handleEndingSelectChange(index, 'activityResponse', option), selectedOption: condition.activityResponse })), filteredEndingConditions.length !== index + 1 && (_jsxs("div", { className: 'flex items-center w-[27.5rem]', children: [_jsx(Text, { size: 'sm', className: 'mx-4 text-secondary font-medium', children: translate('Or') }), _jsx("div", { className: 'border-b w-full' })] }))] }, condition.option.value))), filteredEndingConditions.length === 1 &&
                        filteredEndingConditions[0].type !== EndingConditionType.ENDLESS && (_jsx("section", { children: _jsx(Button, { variant: 'transparent', label: translate('+ Add condition'), onClick: () => addEndCondition() }) }))] })),
        },
        {
            title: translate('Repetition'),
            hidden: !isRemoteActivity || remoteType !== 'recurring',
            description: translate('Configure repetition settings. Days before offset will not apply for the first instance of the activity.'),
            content: (_jsxs("section", { className: 'flex flex-col gap-4', "data-testid": 'activity-instance-repetition', children: [_jsxs("section", { className: 'w-[27.5rem] flex items-end justify-between', children: [_jsx(Input, { dataTestId: 'activity-instance-repetition-every-input', label: translate('Repeats every'), id: 'repetitionEvery', max: MAX_REPETITION_EVERY, min: '1', onChange: handlePositiveNumberChange, onBlur: handleNumericInputBlur, type: 'number', value: repetition.repetitionEvery || '', width: 'xs' }), _jsx(Select, { dataTestId: 'activity-instance-repetition-every-period', onChange: handleRepetitionChange, options: repetitionOptions, value: repetition.repetitionEveryPeriod, width: 'base' })] }), _jsxs("section", { className: 'w-[27.5rem] flex items-end gap-2 justify-between', children: [_jsx(Select, { label: translate('Until'), dataTestId: 'activity-instance-repetition-until-condition', onChange: handleReccurenceEndingOptionChange, options: recurrenceEndingOptions, value: recurrenceEndingOption, width: 'full' }), _jsx(Select, { dataTestId: `activity-instance-offset-0`, onChange: (option) => handleEndingSelectChange(getReccurenceIdx(endingConditions), 'offset', option), label: translate('Offset (days)'), options: dayOptions, value: endingConditions.find((c) => c.type === recurrenceEndingOption.value)?.offset || dayOptions[0], width: 'xs', disabled: recurrenceEndingOption.value === EndingConditionType.AFTER_ACTIVITY_COMPLETION ||
                                    recurrenceEndingOption.value === EndingConditionType.NUM_OF_OCURRENCES ||
                                    recurrenceEndingOption.value === EndingConditionType.ACTIVITY_RESPONSE ||
                                    recurrenceEndingOption.value === EndingConditionType.ENDLESS })] }), recurrenceEndingOption.value === EndingConditionType.NUM_OF_OCURRENCES && (_jsxs("section", { className: 'w-[27.5rem] flex items-center justify-between', children: [_jsx(Text, { size: 'sm', className: 'font-medium', children: translate('Ocurrences') }), _jsx(Input, { dataTestId: 'activity-instance-repetition-count-input', id: 'count', min: '1', onChange: handlePositiveNumberChange, onBlur: handleNumericInputBlur, type: 'number', value: repetition.count || '1', width: 'base' })] })), recurrenceEndingOption.value === EndingConditionType.VISIT_CONFIRMATION && (_jsxs("section", { className: 'w-[27.5rem] flex items-center justify-between', children: [_jsx(Text, { size: 'sm', className: 'font-medium', children: translate('At visit') }), _jsx(Select, { dataTestId: `activity-instance-end-confirmation-visit-1`, onChange: (option) => handleEndingSelectChange(getReccurenceIdx(endingConditions), 'visitId', option), options: allVisitOptions, value: endingConditions.find((c) => c.type === EndingConditionType.VISIT_CONFIRMATION)?.visitId ||
                                    allVisitOptions[0], width: 'base' })] })), recurrenceEndingOption.value === EndingConditionType.ACTIVITY_RESPONSE && (_jsx(SelectActivityResponse, { screenType: 'vrs', options: vrsResponseOptions, onSelectedOptionChange: (option) => handleEndingSelectChange(getReccurenceIdx(endingConditions), 'activityResponse', option), selectedOption: endingConditions.find((c) => c.type === EndingConditionType.ACTIVITY_RESPONSE)?.activityResponse ||
                            getDefaultActivityResponse(vrsResponseOptions) })), recurrenceEndingOption.value === EndingConditionType.AFTER_ACTIVITY_COMPLETION && (_jsxs("section", { className: 'w-[27.5rem] flex items-center justify-between', children: [_jsx(Text, { size: 'sm', className: 'font-medium', children: translate('Activity') }), _jsx(Select, { dataTestId: `activity-instance-end-activity-completion-1`, onChange: (option) => handleEndingSelectChange(getReccurenceIdx(endingConditions), 'activityCompletion', option), options: activityCompletionOptions, value: endingConditions.find((c) => c.type === EndingConditionType.AFTER_ACTIVITY_COMPLETION)
                                    ?.activityCompletion || activityCompletionOptions[1], width: 'base' })] }))] })),
        },
    ];
    return _jsx(FormSections, { sections: sections });
};
