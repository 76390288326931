import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SDKStudy } from '@axon/rosetta-sdk';
import { Error as SDKError } from '@axon/rosetta-sdk/src/types/error';
import { FirebaseAppContext } from 'libs.firebase_react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { Table } from 'libs.nucleus.table';
import { AuthContext, useScopeValidator } from 'libs.react.auth';
import { PageHeader } from 'libs.react.components';
import { useToastNotification, useBooleanState } from 'libs.react.hooks';
import { AclScope } from 'libs.react.types';
import { useStudiesDashboardColumns } from './studies_dashboard_columns.hook';
import { StudiesContext, StudyConfigurationContext } from '../../contexts';
import { sanitizeStudy } from '../../utils/study';
import { ShareStudyModal } from '../share_study_modal';
import { StudyDetailsEdit } from '../study_build';
export const StudiesDashboard = () => {
    const translate = useLocalizeMessage();
    const { addNotification } = useToastNotification();
    const { isLoading: isLoadingStudies, studies } = useContext(StudiesContext);
    const { refreshIdentity } = useContext(AuthContext);
    const { cloneStudy, createNewStudy, getStudyConfiguration } = useContext(StudyConfigurationContext);
    const [studyIdToShare, setStudyIdToShare] = useState('');
    const [isShareModalOpen, openShareModal, closeShareModal] = useBooleanState();
    const { logEvent } = useContext(FirebaseAppContext);
    const navigate = useNavigate();
    const [studyToClone, setStudyToClone] = useState();
    const [isCreateModalOpen, openCreateModal, closeCreateModal] = useBooleanState(false);
    const canCreateStudies = useScopeValidator(AclScope.MOM_STUDY_CREATE);
    const onCloneStudy = async (id) => {
        const studyConfiguration = await getStudyConfiguration(id);
        if (studyConfiguration) {
            try {
                setStudyToClone(sanitizeStudy(studyConfiguration.data));
                openCreateModal();
            }
            catch (error) {
                addNotification({
                    title: translate('Error parsing the study'),
                    subtitle: error instanceof SDKError && error.message
                        ? error.message
                        : translate('Something went wrong when parsing the study.'),
                    type: 'error',
                });
            }
        }
    };
    const onOpenShareModal = (studyId) => {
        setStudyIdToShare(studyId);
        openShareModal();
    };
    const onCloseShareModal = () => {
        setStudyIdToShare('');
        closeShareModal();
    };
    const studyColumns = useStudiesDashboardColumns({ onCloneStudy, onOpenShareModal });
    const onSaveCreate = async (studyDetails) => {
        const study = studyToClone ? cloneStudy(studyToClone, studyDetails) : new SDKStudy(studyDetails);
        study.updateStudyMetadata({
            ...studyDetails.metadata,
            subjectIdFormat: studyDetails.metadata.subjectIdFormat || 'P#####',
        });
        try {
            const id = await createNewStudy(study);
            refreshIdentity();
            closeCreateModal();
            if (studyToClone) {
                logEvent('study_cloned', { study_id: id, cloned_from: studyToClone.id });
            }
            else {
                logEvent('study_created', { study_id: id });
            }
            setStudyToClone(undefined);
            navigate(`/studies/${id}/build`);
        }
        catch (error) { }
    };
    const onCloseCreateModal = () => {
        setStudyToClone(undefined);
        closeCreateModal();
    };
    const headerOptions = [
        { name: translate('Create study'), onClick: openCreateModal, variant: 'primary' },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(PageHeader, { title: translate('Studies'), showOptions: canCreateStudies, options: headerOptions }), _jsx(Table, { columns: studyColumns, data: studies, hasPagination: true, initialPageSize: 10, isLoading: isLoadingStudies, labelNoResults: translate('No studies'), paginationNavigationScreenReaderLabel: translate('Studies table navigation'), useInternalSearch: true, labelSearchField: translate('Search studies') }), isCreateModalOpen && (_jsx(StudyDetailsEdit, { onCancel: onCloseCreateModal, onSave: onSaveCreate, initialStudyDetails: studyToClone, isCloning: !!studyToClone })), isShareModalOpen && _jsx(ShareStudyModal, { onClose: onCloseShareModal, studyId: studyIdToShare })] }));
};
