import { useMutation } from '@tanstack/react-query';
import { useApiClient } from 'libs.react.contexts';
export const useLocalizedBundle = () => {
    const { post } = useApiClient("web" /* ApiClientService.WEB */);
    return useMutation({
        mutationKey: ['instrument_bundler', 'localized'],
        mutationFn: ({ resourceId, locales }) => {
            return post('/api/instrument_bundler/localized', { resourceId, locales });
        },
        retry: false,
    });
};
