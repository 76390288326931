import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useReducer } from 'react';
import { FormSections } from 'libs.nucleus.form_sections';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ModalWindow } from 'libs.nucleus.modal_window';
import { RadioGroup, RadioGroupOption } from 'libs.nucleus.radio_group';
import SectionAssets from './section_asset.component';
import { SectionContent } from './section_content.component';
import { SectionLocale } from './section_locale.component';
export var EXPORT_SCOPE;
(function (EXPORT_SCOPE) {
    EXPORT_SCOPE["ALL"] = "all";
    EXPORT_SCOPE["CUSTOM"] = "custom";
})(EXPORT_SCOPE || (EXPORT_SCOPE = {}));
export function reducer(state, action) {
    switch (action.type) {
        case 'SET_SCOPE':
            return {
                ...state,
                scope: action.payload,
            };
        case 'SET_CONTENT':
            return {
                ...state,
                content: action.payload,
            };
        case 'SET_LOCALE':
            return {
                ...state,
                locale: action.payload,
            };
        case 'SET_ASSETS':
            return {
                ...state,
                assets: action.payload,
            };
        case 'RESET':
            return {
                ...action.payload,
            };
        default:
            return state;
    }
}
export const ExportModal = ({ onModalClose, onHandleExport, assets, locales }) => {
    const t = useLocalizeMessage();
    const initState = {
        scope: EXPORT_SCOPE.ALL,
        content: { resources: ['strings', 'screens'] },
        locale: { selectedLocales: locales || [] },
        assets: { selectedAssets: assets ? Object.keys(assets).map((key) => assets[key].asset) : [] },
    };
    const [state, dispatch] = useReducer(reducer, initState);
    const onModalWindowClose = () => {
        onModalClose();
    };
    const closeButton = {
        label: t('Cancel'),
        onClick: onModalWindowClose,
    };
    const doneButton = {
        label: t('Export'),
        onClick: () => {
            onHandleExport(state);
        },
        disabled: state.scope === EXPORT_SCOPE.CUSTOM &&
            (!state.content?.resources?.length ||
                !state.locale?.selectedLocales?.length ||
                !state.assets?.selectedAssets?.length),
    };
    const handleContentSectionChange = useCallback((value) => {
        dispatch({ type: 'SET_CONTENT', payload: value });
    }, []);
    const handleLocaleSectionChange = useCallback((value) => {
        dispatch({ type: 'SET_LOCALE', payload: value });
    }, []);
    const handleAssetsSectionChange = useCallback((value) => {
        dispatch({ type: 'SET_ASSETS', payload: value });
    }, []);
    const handleScope = (value) => {
        if (value === EXPORT_SCOPE.ALL) {
            dispatch({ type: 'RESET', payload: initState });
        }
        else {
            dispatch({ type: 'SET_SCOPE', payload: value });
        }
    };
    const formSections = [
        {
            title: t('Scope'),
            content: (_jsx("div", { className: 'flex flex-col gap-5', children: _jsxs(RadioGroup, { label: t('Scope of export'), value: state.scope, onChange: handleScope, required: true, children: [_jsx(RadioGroupOption, { option: {
                                value: EXPORT_SCOPE.ALL,
                                label: t('Export all'),
                                description: t('Exporting strings and screens, all assets, only base locale, one json per locale for each asset, all in one .zip file'),
                            } }), _jsx(RadioGroupOption, { option: {
                                value: EXPORT_SCOPE.CUSTOM,
                                label: t('Customize exports'),
                                description: t('Choose content, file format, locales and assets to export'),
                            } })] }) })),
        },
    ];
    if (state.scope === EXPORT_SCOPE.CUSTOM) {
        formSections.push({
            title: t('Content'),
            description: t('At least one option needs to be selected'),
            content: _jsx(SectionContent, { onChange: handleContentSectionChange }),
        });
        if (locales) {
            formSections.push({
                title: t('Locales'),
                content: _jsx(SectionLocale, { onChange: handleLocaleSectionChange, locales: locales }),
            });
        }
        if (assets) {
            formSections.push({
                title: t('Assets'),
                content: _jsx(SectionAssets, { onChange: handleAssetsSectionChange, assets: assets }),
            });
        }
    }
    return (_jsx(ModalWindow, { title: t('Export strings/screens'), isOpen: true, closeWindow: onModalWindowClose, footerPrimaryActionButton: doneButton, footerSecondaryActionButtons: [closeButton], position: 'top', width: 'lg', children: _jsx("section", { className: 'px-8 py-2', children: _jsx(FormSections, { sections: formSections }) }) }));
};
