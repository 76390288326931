import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useCallback } from 'react';
import { LocalizationStatus } from '@axon/rosetta-sdk';
import { FirebaseAppContext } from 'libs.firebase_react';
import { Button } from 'libs.nucleus.button';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
import { ListInformation } from 'libs.react.components';
import { useBooleanState } from 'libs.react.hooks';
import { TranslationStartedNotification } from 'libs.translation_workbench';
import { StudyDetailsEdit } from './study_details_edit.component';
import { StudiesContext, StudyConfigurationContext } from '../../../contexts';
export const StudyDetails = () => {
    const translate = useLocalizeMessage();
    const { assignStudy, currentStudy, studyId } = useContext(StudyConfigurationContext);
    const { updateStudyMetadata } = useContext(StudiesContext);
    const [isEditModalOpen, openEditModal, closeEditModal] = useBooleanState(false);
    const disableBuildConfig = currentStudy.localizationStatus === LocalizationStatus.READY;
    const { logEvent } = useContext(FirebaseAppContext);
    const handleSaveEdit = useCallback(({ name, description = '', displayName = '', protocolNumber = '', metadata }) => {
        currentStudy.name = name;
        currentStudy.displayName = displayName;
        currentStudy.description = description;
        currentStudy.protocolNumber = protocolNumber;
        currentStudy.updateStudyMetadata(metadata);
        assignStudy(currentStudy);
        updateStudyMetadata(studyId, { name, protocolNumber, description });
        logEvent('study_details_updated', { study_id: studyId });
        closeEditModal();
    }, [currentStudy]);
    const detailItems = [
        { title: translate('Study name'), description: currentStudy.name || '' },
        {
            title: translate('Sponsor name'),
            description: currentStudy.configuration.studyMetadata.sponsor || '',
        },
        { title: translate('Display name'), description: currentStudy.displayName || '' },
        { title: translate('Protocol number'), description: currentStudy.protocolNumber || '' },
        { title: translate('Description'), description: currentStudy.description || '' },
    ];
    return (_jsxs("div", { className: 'flex flex-col gap-8', children: [disableBuildConfig && _jsx(TranslationStartedNotification, {}), _jsx("div", { className: 'ml-auto', children: _jsx(Button, { label: translate('Edit details'), onClick: openEditModal, disabled: disableBuildConfig }) }), _jsx(ListInformation, { dataTestId: 'study-details-list-information', items: detailItems, useDefaultEmptyDescription: true }), isEditModalOpen && (_jsx(StudyDetailsEdit, { onCancel: closeEditModal, onSave: handleSaveEdit, initialStudyDetails: currentStudy }))] }));
};
