import { set as _set, get as _get } from 'lodash';
import { useMemo, useState } from 'react';
import { useLocalizeMessage } from 'libs.nucleus.i18n';
export const useStudyNotificationsHandler = () => {
    const translate = useLocalizeMessage();
    const [isFieldPristine, setIsFieldPristine] = useState({});
    const defaultSelectOption = useMemo(() => ({ label: translate('-select-'), value: '' }), []);
    const requiredFields = useMemo(() => ({
        notifications: [
            'label',
            'name',
            'template',
            'recipientType',
            'triggerType',
            'activityId',
            'activityScheduleType',
            'delayType',
        ],
        templates: ['name', 'type', 'data.plain'],
    }), []);
    const recipientTypes = useMemo(() => [
        defaultSelectOption,
        { label: translate('Participant'), value: 'participant' },
        { label: translate('Site User'), value: 'site_user' },
        { label: translate('Site Investigator'), value: 'site_investigator' },
        { label: translate('Site Monitor'), value: 'site_monitor' },
    ], []);
    const triggerTypes = useMemo(() => [
        defaultSelectOption,
        { label: translate('Activity reminder'), value: 'activity_reminder' },
        { label: translate('Activity completion'), value: 'activity_completion' },
        { label: translate('Activity missed'), value: 'activity_missed' },
        { label: translate('Activity unavailable'), value: 'activity_unavailable' },
    ], []);
    const activityScheduleTypes = useMemo(() => [
        defaultSelectOption,
        { label: translate('Once'), value: 'once' },
        { label: translate('Recurring'), value: 'recurring' },
        { label: translate('Adhoc'), value: 'adhoc' },
    ], []);
    const stepResponsesAggregations = useMemo(() => [defaultSelectOption, { label: translate('Sum'), value: 'sum' }], []);
    const comparisonOperators = useMemo(() => [
        defaultSelectOption,
        { label: translate('=='), value: '==' },
        { label: translate('!='), value: '!=' },
        { label: translate('<'), value: '<' },
        { label: translate('>'), value: '>' },
        { label: translate('<='), value: '<=' },
        { label: translate('>='), value: '>=' },
    ], []);
    const delayTypes = useMemo(() => [
        defaultSelectOption,
        { label: translate('Absolute'), value: 'absolute' },
        { label: translate('Relative'), value: 'relative' },
    ], []);
    const templateTypes = useMemo(() => [
        defaultSelectOption,
        { label: translate('Email'), value: 'email' },
        { label: translate('Push'), value: 'push' },
        { label: translate('SMS'), value: 'sms' },
    ], []);
    const isFormValid = (formFields, payloadObj) => {
        const isFormValid = formFields.every((field) => Boolean(_get(payloadObj, field)));
        return isFormValid;
    };
    const isFieldValid = (payloadObj, field) => {
        return field in isFieldPristine && !isFieldPristine[field] && !payloadObj?.[field];
    };
    const handleInputChange = (setPayload, event) => {
        const { id, value } = event.target;
        setPayload((prev) => _set({ ...prev }, id, value));
        setIsFieldPristine((prev) => _set({ ...prev }, id, false));
    };
    const handleSelectChange = (setPayload, fieldName, selectedOption) => {
        const { value } = selectedOption;
        setPayload((prev) => _set({ ...prev }, fieldName, value));
        setIsFieldPristine((prev) => _set({ ...prev }, fieldName, false));
    };
    return {
        requiredFields,
        recipientTypes,
        triggerTypes,
        activityScheduleTypes,
        stepResponsesAggregations,
        comparisonOperators,
        delayTypes,
        templateTypes,
        defaultSelectOption,
        isFormValid,
        isFieldValid,
        handleInputChange,
        handleSelectChange,
    };
};
