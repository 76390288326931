import { AssetTypes } from 'libs.localization.common';
export const getResourceTitlesAsset = (study) => ({
    type: AssetTypes.RESOURCE_TITLES,
    id: study.id,
    version: '1',
    messages: Object.values(study.getStudyMetadata().studyResources).map((resource) => ({
        namespace: resource.id,
        messages: { value: resource.title },
    })),
    preview: {
        engine: 'react',
        id: AssetTypes.RESOURCE_TITLES,
        default_viewport: 'iosSmall',
        info: {
            display_name: 'Resource titles',
            list: [],
        },
    },
});
